<div class="document-section">
    <div class="common-tab-with-buttons">
        <div *ngIf="isDisplayButtons">
            <div class="inner-action-section inner-full-action">
                <div class="row align-items-center justify-content-end">
                    <div class="col-12 col-md-12 col-xxl-12">
                        <div class="top-action-btns">
                            <ul>
                                <li *ngIf="this.showFileSwitch">
                                    <div class="switch-inner-label">
                                        <div class="button-switch">
                                            <input type="checkbox" id="activeInactive1" class="switch"
                                                [(ngModel)]="archivedData" (ngModelChange)="getArchivedData()">
                                            <label for="activeInactive1" class="lbl-off">Current files</label>
                                            <label for="activeInactive1" class="lbl-on">Archived files</label>
                                        </div>
                                    </div>
                                </li>
                                <li *ngIf="this.showUploadButton">
                                    <a href="javascript:;" class="btn btn-primary btn-sm"
                                        (click)="addDocumentOpen(0,false)"><span
                                            class="custom-icons icon-upload m-r"></span>
                                        Upload</a>
                                </li>
                                <li>
                                    <a href="javascript:;" class="btn btn-primary btn-sm"
                                        (click)="clearFilterDocuments()">
                                        <span class="custom-icons icon-rotate m-r"></span>Clear Filter</a>
                                </li>
                                <li>
                                    <div class="dropdown">
                                        <a href="javascript:;" class="btn btn-primary btn-sm dropdown-toggle"
                                            id="dropdownExport" data-bs-toggle="dropdown"
                                            aria-expanded="false">Export</a>
                                        <ul class="dropdown-menu dropdown-top-position"
                                            aria-labelledby="dropdownExport">
                                            <li><a (click)="exportToExcel()" class="dropdown-item"
                                                    href="javascript:;"><span
                                                        class="custom-icons icon-excel m-r"></span>Excel</a></li>
                                            <li><a (click)="exportToPDF()" class="dropdown-item"
                                                    href="javascript:;"><span
                                                        class="custom-icons icon-pdf m-r"></span>PDF</a></li>
                                        </ul>
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="common-border table-responsive only-pager-grid">
            <kendo-grid (cellClick)="onCellClick($event)" (dblclick)="addDocumentOpen(null, false)"
                [data]="_commonPageService.groupPaginationListDataCommon" #kendoGridForDocument="kendoGrid"
                [navigable]="true" filterable="menu" [sortable]="true" [sort]="_commonPageService.commonState.sort"
                (sortChange)="sortChangeDocuments($event)" [pageable]="true"
                [pageSize]="_commonPageService.commonState.take" [skip]="_commonPageService.commonState.skip"
                (pageChange)="onPageChangeDocuments($event)" [filter]="_commonPageService.commonState.filter"
                (filterChange)="filterChangeDocuments($event)">
                <kendo-grid-column title="Action" [filterable]="false" [sortable]="false" [width]="100"
                    [headerClass]="{'text-center': true}">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <div class="dropdown table-dropdown text-center">
                            <a href="javascript:;" class="dropdown-toggle filter-icon dropdown-actions-fixed"
                                role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                <i class="custom-icons icon-list-menu"></i>
                            </a>
                            <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <li>
                                    <a class="dropdown-item" href="javascript:;"
                                        (click)="addDocumentOpen(dataItem.fileId, false)"><span
                                            class="custom-icons icon-pencil"></span>Edit</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:;"
                                        (click)="deleteDocument(dataItem.fileId,1)"><span
                                            class="custom-icons icon-trash"></span>Delete</a>
                                </li>
                                <li *ngIf="dataItem.isArchive == 0">
                                    <a class="dropdown-item" href="javascript:;"
                                        (click)="deleteDocument(dataItem.fileId,2)"><span
                                            class="custom-icons icon-refresh"></span>Arcihve</a>
                                </li>
                                <li>
                                    <a class="dropdown-item" href="javascript:;"
                                        (click)="addDocumentOpen(dataItem.fileId, true)"><span
                                            class="custom-icons icon-eye"></span>View</a>
                                </li>
                            </ul>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column *ngIf="isPatientCaseView" field="documentType" title="Document Type" [filterable]="true" [width]="120">
                </kendo-grid-column>
                <kendo-grid-column *ngIf="!isPatientCaseView" field="fileId" title="File ID" [filterable]="true" [width]="120">
                </kendo-grid-column>
                <kendo-grid-column *ngIf="isPatientCaseView" field="facilityName" title="Facility" [filterable]="true" [width]="180">
                </kendo-grid-column>
                <kendo-grid-column *ngIf="isPatientCaseView" field="patientName" title="Patient Name" [filterable]="true" [width]="150">
                </kendo-grid-column>
                <kendo-grid-column *ngIf="!isPatientCaseView" field="teamName" title="Team" [filterable]="true" [width]="150">
                </kendo-grid-column>
                <kendo-grid-column *ngIf="!isPatientCaseView" field="teamMemberName" title="Team Member" [filterable]="true" [width]="180">
                </kendo-grid-column>
                <kendo-grid-column *ngIf="!isPatientCaseView" field="status" title="Status" [filterable]="true" [width]="150">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <div class="label-with-tooltip">
                            <div class="grid-label-btn">
                                <span class='status-label-btn'
                                    [ngClass]="dataItem.statusColorCode">{{dataItem.status}}</span>
                            </div>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column *ngIf="!isPatientCaseView" field="createdDate" title="Created Date" [filterable]="true" [width]="180"
                    filter="date">
                    <ng-template kendoGridCellTemplate let-dataItem>
                        <span>{{dataItem.createdDate | localDateTime}}</span>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column *ngIf="!isPatientCaseView" field="createdByName" title="Created By" [filterable]="true" [width]="150">
                </kendo-grid-column>
                <kendo-grid-column field="fileName" title="File Name" [filterable]="true" [width]="180">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <div class="files-links-box">
                            <a [routerLink]="" (click)="downloadDocument(dataItem.fileId, dataItem.statusId)"
                                style="cursor: pointer;" [download]="dataItem.fileName" target="_blank"
                                class="file-link">{{dataItem.fileName}}</a>
                        </div>
                    </ng-template>
                </kendo-grid-column>
                <kendo-grid-column field="categoryName" title="Category" [filterable]="true" [width]="180">
                </kendo-grid-column>
                <kendo-grid-column *ngIf="isPatientCaseView" field="subCategoryName" title="Sub Category" [filterable]="true" [width]="180">
                </kendo-grid-column>
                <kendo-grid-pdf fileName="Document List.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
                    [landscape]="true" [scale]="0.5">
                    <!----custom div-->
                    <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
                    </kendo-grid-pdf-margin>
                    <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
                        <div class="page-template">
                            <div class="header" style="border-bottom: none;"
                                [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">
                                <div class="address">
                                    <div class="for" style="text-align: center; margin-top: 2%;">
                                        <h3>Documents List Details </h3>
                                    </div>
                                </div>
                            </div>
                            <div class="footer">
                                <div style="float: right">
                                    Page {{ pageNum }} of {{ totalPages }}
                                </div>
                            </div>
                        </div>
                    </ng-template>
                    <!----custom div-->
                </kendo-grid-pdf>
                <kendo-grid-excel fileName="Document List.xlsx">
                </kendo-grid-excel>
            </kendo-grid>
            <kendo-contextmenu #gridmenu [items]="contextMenuList" (select)="onContextMenuSelected($event)">
                <ng-template kendoMenuItemTemplate let-item="item">
                    <span [ngClass]="item.class"></span>{{item.text}}
                </ng-template>
            </kendo-contextmenu>
        </div>
    </div>
</div>

<!-- Add Document Popup Start -->
<kendo-dialog title="{{DocumentTitle}}" title="Add Document" *ngIf="addDocument" (close)="addDocumentClose()"
    class="kendo-modal modal-790">
    <div class="common-popup-space">
        <!-- <pre>{{selectFacility | json}}</pre> -->
        <form [formGroup]="addDocumentForm">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label class="form-label">Created Date</label>
                        <kendo-datetimepicker [disabled]="true" formControlName="documentDate">
                        </kendo-datetimepicker>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label class="form-label">Created by</label>
                        <input type="text" class="form-control" placeholder="Created by" [value]="createdByName"
                            disabled>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <div class="form-group">
                        <label class="form-label">Expiry Date</label>
                        <kendo-datepicker #datePicker (click)="changeFocusToMonth(0)" calendarType="classic"
                            formControlName="expiryDate" [disabled]="isViewMode"
                            [ngClass]="{ 'is-invalid':  addDocumentForm.get('expiryDate').errors && submitted}">
                        </kendo-datepicker>
                        <div *ngIf=" addDocumentForm.get('expiryDate').errors" class="invalid-feedback">
                            {{ displayMessage['expiryDate'] }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <div class="form-group">
                        <label class="form-label">Document Type <span class="field-required">*</span></label>
                        <kendo-dropdownlist [data]="documentTypeList" [textField]="'text'" [valueField]="'value'"
                            [valuePrimitive]="true" formControlName="documentTypeId" [disabled]="isViewMode"
                            [defaultItem]="{ text: 'Select Document Type', value: null }"
                            [ngClass]="{ 'is-invalid':   displayMessage['documentTypeId']}"
                            (valueChange)=" getCategoryListByDocumentTypeId($event);this.addDocumentForm.controls.patientId.value = null">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['documentTypeId']" class="invalid-feedback">
                            {{ displayMessage['documentTypeId'] }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <div class="form-group">
                        <label class="form-label">Facility <span class="field-required">*</span></label>
                        <kendo-dropdownlist [data]="originalFacilityList" formControlName="facilityId"
                            [textField]="'facilityName'" [valueField]="'facilityId'" [valuePrimitive]="true"
                            [filterable]="true" [kendoDropDownFilter]="filterSettings" [disabled]="isViewMode"
                            [ngClass]="{ 'is-invalid':  ( isSubmit && (addDocumentForm.value.facilityId == null || addDocumentForm.value.facilityId ==0))}"
                            (valueChange)="docFacilityMultiselectChange($event)">
                        </kendo-dropdownlist>
                        <div *ngIf="isSubmit && (addDocumentForm.value.facilityId == null || addDocumentForm.value.facilityId ==0)"
                            class="invalid-feedback">
                            please select facility
                        </div>
                    </div>
                </div>
                <div *ngIf="addDocumentForm.value.documentTypeId ===2" class="col-12 col-md-12">
                    <div class="form-group">
                        <label class="form-label">Patient <span class="field-required">*</span></label>
                        <kendo-multiselect [data]="patientList" formControlName="patientId" [textField]="'patientName'"
                            [valueField]="'patientMasterId'" [valuePrimitive]="true" [filterable]="true"
                            [kendoDropDownFilter]="filterSettings"
                            [disabled]="addDocumentForm.value.facilityId == 0 || isViewMode"
                            [ngClass]="{ 'is-invalid': (isSubmit && (addDocumentForm.value.patientId== null || addDocumentForm.value.patientId ==0))}">>
                        </kendo-multiselect>
                        <div *ngIf="isSubmit && (addDocumentForm.value.patientId == null|| addDocumentForm.value.patientId ==0)"
                            class="invalid-feedback">
                            please select patient
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <div class="label-with-icon">
                            <label class="form-label">Category (Department) <span
                                    class="field-required">*</span></label>
                        </div>
                        <kendo-dropdownlist [data]="categoryMasterData" [textField]="'categoryName'"
                            [valueField]="'categoryId'" [valuePrimitive]="true" formControlName="categoryId"
                            [disabled]="isViewMode"
                            (valueChange)="getCategoryListByDocumentTypeId(addDocumentForm.value.documentTypeId,addDocumentForm.value.categoryId)"
                            [defaultItem]="{ categoryName: 'Select Category', categoryId: null }"
                            [ngClass]="{ 'is-invalid':   displayMessage['categoryId']}">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['categoryId']" class="invalid-feedback">
                            {{ displayMessage['categoryId'] }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <div class="label-with-icon">
                            <label class="form-label">Sub Category <span class="field-required">*</span></label>
                        </div>
                        <kendo-dropdownlist [data]="subCategoryMasterData" [textField]="'subCategoryName'"
                            [valueField]="'documentTypeMasterId'" [valuePrimitive]="true"
                            formControlName="subCategoryId"
                            [disabled]="addDocumentForm.value.categoryId == null || isViewMode"
                            [defaultItem]="{ subCategoryName: 'Select Sub Category', subCategoryId: null }"
                            [ngClass]="{ 'is-invalid':   displayMessage['subCategoryId']}">
                        </kendo-dropdownlist>
                        <div *ngIf="displayMessage['subCategoryId']" class="invalid-feedback">
                            {{ displayMessage['subCategoryId'] }}
                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <div class="form-group">
                        <label class="form-label">File <span class="field-required">*</span></label><br />
                        <label *ngIf="this.isViewMode">{{fileName}}</label>
                        <a (click)="downloadDocument(addDocumentForm.value.documentId, 1)" style="cursor: pointer;"
                            [download]="fileName" target="_blank"
                            *ngIf="(isDocumentEdit && filePath !=null && fileName != null)">
                            {{fileName}}</a>

                        <kendo-fileselect (select)="uploadFileSelect($event)" (remove)="uploadFileRemove()"
                            formControlName="documentUpload" [disabled]="isViewMode"
                            [ngClass]="{ 'is-invalid':   displayMessage['documentUpload'] || !isValidExtension}">
                            <kendo-fileselect-messages dropFilesHere="Drag & Drop File" select="Browse">
                            </kendo-fileselect-messages>
                        </kendo-fileselect>
                        <div *ngIf="displayMessage['documentUpload']" class="invalid-feedback">
                            {{ displayMessage['documentUpload'] }}
                        </div>
                        <div *ngIf="!isValidExtension" class="invalid-feedback">
                            Please upload file as per selected file Type
                        </div>
                        <div *ngIf="isValidSize" class="invalid-feedback">
                            File size exceeds the allowed file size is 5MB
                        </div>
                    </div>
                </div>
                <div class="col-lg-12 col-md-12">
                    <div class="form-group">
                        <label for="taskType" class="form-label">External Note</label>
                        <textarea class="form-control" formControlName="externalNotes" placeholder="Note"
                            [disabled]="isViewMode" maxlength="450"></textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <kendo-dialog-actions class="d-block text-end" *ngIf="this.showUploadButton">
        <button kendoButton *ngIf="!isViewMode" (click)="saveDocument()" class="btn btn-primary btn-md">Save</button>
        <button kendoButton (click)="addDocumentClose()" class="btn btn-cancel btn-md">Cancel</button>
    </kendo-dialog-actions>
</kendo-dialog>
<!-- Add Document Popup End -->

<!-- History Popup Start -->
<kendo-dialog title="(File Id #{{ histroyModelDocumentId }}) History" *ngIf="history" (close)="historyClose()"
    class="kendo-modal modal-720 modal-remove-button">
    <div class="common-popup-info">
        <div class="common-border table-responsive only-pager-grid">
            <kendo-grid [kendoGridBinding]="documentHistory" [pageSize]="_commonPageService.commonState.take"
                [skip]="_commonPageService.commonState.skip!" [sort]="_commonPageService.commonState.sort!"
                [sortable]="true" [pageable]="false" filterable="menu">
                <kendo-grid-column field="action" title="Actions" [width]="160" [filterable]="true">
                </kendo-grid-column>
                <kendo-grid-column field="byUser" title="By User" [width]="160" [filterable]="true"></kendo-grid-column>
                <kendo-grid-column field="byDate" title="By Date" [width]="160" [filterable]="true">
                    <ng-template kendoGridCellTemplate let-dataItem="dataItem">
                        <span>{{dataItem.byDate | localDateTime}}</span>
                    </ng-template>
                </kendo-grid-column>
            </kendo-grid>
        </div>
    </div>
</kendo-dialog>
<!-- History Popup End -->

<!-- delete pop up -->
<kendo-dialog *ngIf="isDelete" title="Please Confirm" (close)="isDelete = false;"
    class="kendo-modal modal-sm text-center">
    <div class="common-popup-space">
        <p>{{deletePopupMessage}}</p>
    </div>
    <kendo-dialog-actions class="d-block text-center">
        <button kendoButton (click)="delete()" class="btn btn-primary btn-sm">Yes</button>
        <button kendoButton (click)="isDelete = false;" class="btn btn-cancel btn-sm">No</button>
    </kendo-dialog-actions>
</kendo-dialog>