import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class CallLogService extends CoreHttpService {
  private callLogUrl: string = `${environment.apiUrl}/CallLog`;
  constructor(
    private _http: HttpClient,
    router: Router,
    spinner: NgxSpinnerService
  ) {
    super(_http, router, spinner);
  }

  getCallLogByRefferenceId(params: any): Observable<any> {
    return this.post(`${this.callLogUrl}/GetCallLogByRefferenceId`, params);
  }

  addUpdateCallLog(model: any) {
    return this.post(`${this.callLogUrl}/AddUpdateCallLog`, model);
  }
  
  saveManualCallLog(model: any) {
    return this.post(`${this.callLogUrl}/SaveManualCallLog`, model);
  }
}
