import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class PatientsService extends CoreHttpService {

  private patientsUrl: string = `${environment.apiUrl}/PatientMaster`;
  private VOBUrl: string = `${environment.apiUrl}/VOBService`;
  private authorisationUrl: string = `${environment.apiUrl}/Authorization`;
  private billingUrl: string = `${environment.apiUrl}/BillingMaster`;
  private documentUrl: string = `${environment.apiUrl}/DocumentMaster`;


  constructor(
    private _http: HttpClient,
    router: Router,
    spinner: NgxSpinnerService
  ) {
    super(_http, router, spinner);
  }

  getPatientMasterByPatientId(params: any): Observable<any> {
    return this.postParams(`${this.patientsUrl}/GetPatientMasterByPatientId`, "", params);
  }

  AddUpdatePatientMaster(model: FormData) {
    return this.postFormData(`${this.patientsUrl}/AddUpdatePatientMaster`, model);
  }

  getPatientDocumentByPatientId(param: any): Observable<any> {
    return this.postParams(`${this.patientsUrl}/GetPatientDocumentByPatientId`, "", param);
  }

  UpdatePatientDetailsFromCensus(model) {
    return this.postFormData(`${this.patientsUrl}/UpdatePatientDetailsFromCensus`, model);
  }
  AddPatientDetailsFromCensus(model: any) {
    return this.postFormData(`${this.patientsUrl}/AddPatientDetailsFromCensus`, model);
  }

  UploadPatientInsuranceDocuments(model: FormData): Observable<any> {
    return this.postFormData(`${this.patientsUrl}/UploadPatientInsuranceDocuments`, model);
  }

  CheckIfPatientExists(facilityId: number, firstName: string, lastName: string, dateOfBirth: string) {
    return this.get(`${this.patientsUrl}/CheckIfPatientExists?facilityId=${facilityId}&firstName=${firstName}&lastName=${lastName}&dateOfBirth=${dateOfBirth}`);
  }

  collabMdFindPatient(model) {
    return this.post(`${this.patientsUrl}/CollabMdFindPatient`, model);
  }

  //#region Patient Case View Services

  //VOB
  caseViewVobDetail(patientId: number) {
    return this.get(`${this.VOBUrl}/SearchPatientForVOB?patientId=${patientId}&vobId=0`);
  }

  getPatientDemoGraphicByVobId(patientId: number, vobId: number): Observable<any> {
    return this.get(`${this.VOBUrl}/SearchPatientForVOB?patientId=${patientId}&vobId=${vobId}`);
  }

  getVobExportByVobId(vobId: number): Observable<any> {
    return this.get(`${this.VOBUrl}/GetVobExportByVobId?vobId=${vobId}`);
  }

  //AUTH
  caseViewAuthDetail(patientId: number) {
    return this.get(`${this.authorisationUrl}/SearchPatientForAuth?patientId=${patientId}&authId=0`);
  }

  getPatientCallInfoByAuthId(patientId: number, authId: number): Observable<any> {
    return this.get(`${this.authorisationUrl}/SearchPatientForAuth?patientId=${patientId}&authId=${authId}`);
  }

  caseViewBillingDetail(patientId: number) {
    return this.get(`${this.billingUrl}/SearchPatientForBilling?patientId=${patientId}`);
  }

  //#endregion

  transferPatientByCaseId(caseId: number, facilityId: number, transferDate: string) {
    return this.post(`${this.patientsUrl}/TransferPatientByCaseId?caseId=${caseId}&facilityId=${facilityId}&transferDate=${transferDate}`, "");
  }

  setPatientMasterPriority(patientMasterId: number, isPriority: number): Observable<any> {
    const params = {
      patientMasterId: patientMasterId,
      isPriority: isPriority
    }

    return this.postParams(`${this.patientsUrl}/SetPatientPriority`, "", params);
  }

  GetPatientMasterForGrid(model: any): Observable<any> {
    return this.post(`${this.patientsUrl}/GetPatientMasterForGrid`, model);
  }

  GetPatientDocumentGroupMasterForGrid(model: any): Observable<any> {
    return this.post(`${this.patientsUrl}/GetPatientDocumentGroupMasterForGrid`, model);
  }
}




