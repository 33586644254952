export enum CereusNoteTypeId {
    VOB	= 1,
    UR	= 2,
    Billing	= 3,
    RCM	= 4,
    MedicalRecords = 5,
    Payments = 6,
    ThirdPartyNegotiation = 7,
    ServiceNote = 8
}


export enum CereusNotesMode {
    ExternalInternal = 0,
    DomainOnly = 1
}

export enum CeruesNotesExternalAddMode {
    Text = 0,
    Selection = 1
}

export enum NotesTabIds {
    InternalTab = 0,
    ExternalTab = 1,
    HistoryTab = 2,
    CommunicationTab = 3,
}