import { Component, EventEmitter, Input, OnInit, Output, QueryList, ViewChildren } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import { CallLogService } from './call-log.services';
import { lastValueFrom } from 'rxjs';
import { CallAction, CallType } from '../../enum/call-log';
import { GroupDescriptor } from '@progress/kendo-data-query';
import { UserManagementService } from 'src/app/modules/auth/administration/user-management/user-management.service';
import { CoreHelperService } from 'src/app/providers/core-helper/core-helper.service';
import { UserStatus } from '../../enum/user-domain';

@Component({
  selector: 'app-call-log-dialog',
  templateUrl: './call-log-dialog.component.html',
  styleUrls: ['./call-log-dialog.component.scss']
})

export class CallLogDialogComponent implements OnInit {
  @Input() isCallLogOpen: boolean = false;
  @Input() patientName: string;
  @Input() refferenceId: number;
  @Input() refferenceTypeId: number;
  callLogId: number;
  isCallHold: boolean = false;
  isCallStart: boolean = false;
  notes: string;
  holdResumeCallText: string = "Hold";
  startEndCallText: string = "Start Call";
  addManualCallLog: boolean = false;
  holdResumeCallDisable: boolean = true;
  discussionStartDisable: boolean = true;
  discussionEndDisable: boolean = true;
  public callLogDetailsData: [] = [];
  @ViewChildren('datePicker') datePickers: QueryList<DatePickerComponent>;
  callLogTitle = 'Call Log For';
  callLogForm: FormGroup | any;
  @Output() callLogCancelEvent: EventEmitter<any> = new EventEmitter<any>();
  public group: GroupDescriptor[] = [{ field: "callDate", dir: 'desc' }];
  manualcallLogForm: FormGroup | any;
  isSubmittedCallLog: boolean = false;
  constructor(private _callLogService: CallLogService,
    public _userService: UserManagementService,
    private fb: FormBuilder,
    public _coreHelper: CoreHelperService) {
  }

  ngOnInit(): void {
    this.getCallLogByRefferenceId(Number(this.refferenceId), this.refferenceTypeId);
  }

  callLogClose(): void {
    this.callLogCancelEvent.emit();
    this.isCallLogOpen = false;
  }

  openManualCallLog(): void {
    this.addManualCallLog = true;
    this.manualcallLogForm = this.fb.group({
      startDatetime: [new Date(), [Validators.required]],
      endDatetime: [new Date()],
      holdDatetime: [new Date()],
      resumeDatetime: [new Date()],
      discussionStartDatetime: [new Date()],
      discussionEndDatetime: [new Date()],
      callNote: [null],
    });
  }

  async saveManualCallLog() {
    if (this.manualcallLogForm.invalid) {
      this.isSubmittedCallLog = true;
      return;
    }
    let saveManualCallLogModel = {
      startDatetime: this.manualcallLogForm.value.startDatetime,
      endDatetime: this.manualcallLogForm.value.endDatetime,
      holdDatetime: this.manualcallLogForm.value.holdDatetime,
      resumeDatetime: this.manualcallLogForm.value.resumeDatetime,
      discussionStartDatetime: this.manualcallLogForm.value.discussionStartDatetime,
      discussionEndDatetime: this.manualcallLogForm.value.discussionEndDatetime,
      callNote: this.manualcallLogForm.value.callNote,
      callType: CallType.Manual,
      refferenceId: Number(this.refferenceId),
      refferenceTypeId: Number(this.refferenceTypeId),
      callLogId: this.callLogId
    }
    await lastValueFrom(this._callLogService.saveManualCallLog(saveManualCallLogModel)).then((response) => {
      if (response.apiStatus) {
        this.cancelManualCallLog();
        this.getCallLogByRefferenceId(Number(this.refferenceId), this.refferenceTypeId);
      }
    });
  }

  cancelManualCallLog(): void {
    this.addManualCallLog = false;
  }

  async getCallLogByRefferenceId(refferenceId: number, refferenceTypeId: number) {
    this.callLogDetailsData = [];
    let callLogRefferenceRequest = {
      refferenceId: refferenceId,
      refferenceTypeId: refferenceTypeId
    };
    await lastValueFrom(this._callLogService.getCallLogByRefferenceId(callLogRefferenceRequest)).then((response) => {
      if (response.apiStatus) {
        this.callLogDetailsData = response.apiData;
      }
    });
  }

  async onDiscussionStartClick() {
    this.holdResumeCallDisable = true;
    this.discussionStartDisable = true;
    this.discussionEndDisable = false;

    let callLogRequest: any = {
      callLogId: this.callLogId,
      callType: CallType.Automatic,
      callAction: CallAction.DiscussionStart,
      notes: this.notes,
    }
    this.addUpdateCallLog(callLogRequest);
  }

  async onDiscussionEndClick() {
    this.holdResumeCallDisable = true;
    this.discussionStartDisable = false;
    this.discussionEndDisable = true;

    let callLogRequest: any = {
      callLogId: this.callLogId,
      callType: CallType.Automatic,
      callAction: CallAction.DiscussionEnd,
      notes: this.notes,
    }
    this.addUpdateCallLog(callLogRequest, UserStatus.Available);
  }

  async onHoldResumeCallClick(isCallHold: boolean) {
    this.isCallHold = isCallHold;
    this.holdResumeCallText = isCallHold ? "Resume" : "Hold";
    this.discussionStartDisable = isCallHold;
    this.discussionEndDisable = true;

    let callLogRequest: any = {
      callLogId: this.callLogId,
      callType: CallType.Automatic,
      callAction: isCallHold ? CallAction.Hold : CallAction.Resume,
      notes: this.notes,
    }
    this.addUpdateCallLog(callLogRequest);
  }

  async onStartEndCallClick(isCallStart: boolean) {
    this.isCallStart = isCallStart;
    this.startEndCallText = isCallStart ? "End Call" : "Start Call";

    if (!isCallStart) {
      this.isCallHold = false;
      this.holdResumeCallText = "Hold";
    }
    this.holdResumeCallDisable = !isCallStart;
    this.discussionStartDisable = !isCallStart;
    this.discussionEndDisable = true;
    let callLogRequest: any = {
      refferenceId: Number(this.refferenceId),
      refferenceTypeId: this.refferenceTypeId,
      callLogId: this.callLogId,
      callType: CallType.Automatic,
      callAction: isCallStart ? CallAction.Start : CallAction.End,
      notes: this.notes,
    }
    this.addUpdateCallLog(callLogRequest, isCallStart ? UserStatus.Busy : UserStatus.Available);
  }

  async addUpdateCallLog(callLogRequest: any, userStatus: number = 0) {
    await lastValueFrom(this._callLogService.addUpdateCallLog(callLogRequest)).then((response) => {
      if (response.apiStatus) {
        this.getCallLogByRefferenceId(Number(this.refferenceId), this.refferenceTypeId);
        if (userStatus === UserStatus.Busy || userStatus === UserStatus.Available)
          this.setUserStatus(userStatus);
        this.callLogId = response.apiData;
      }
    });
  }

  async setUserStatus(userStatus: UserStatus) {
    let requestModel = {
      userId: this._coreHelper.getUserId(),
      isLogin: 1,
      spMode: 1,
      userStatusId: userStatus
    }
    await lastValueFrom(this._userService.addUpdateSignedUser(requestModel)).then((response) => {
      this._coreHelper.setUserStatusId(response.apiData);
    });
  }
}
