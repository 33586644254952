<div class="vob-information">
    <div class="vob-info-actions">
        <div class="info-title">
            <h6>Notes</h6>
        </div>
        <div ngClass="info-action" [ngClass]="(showAddButton && currentTab <= 1 && 
                        (this.currentTab === notesTabIds.InternalTab && currentUserDomain == userDomainType.Internal) ||
                        (this.currentTab == notesTabIds.ExternalTab && (currentUserDomain == userDomainType.Internal || 
                        this.currentUserDomain == userDomainType.External))) ? 'selected' : 'notSelected'">
            <div class="action-box">
                <button type="button" *ngIf="currentTab != notesTabIds.CommunicationTab" class="btn btn-primary"
                    (click)="openDialog(0, '');"><span class="custom-icons icon-plus me-1"></span> Add</button>
            </div>
        </div>
        <button type="button" *ngIf="currentTab === notesTabIds.CommunicationTab && showReplyButton"
            class="btn btn-primary" (click)="openSendEmail();"><img class="email-white-icon" height="19" width="19" />&nbsp;&nbsp;Reply</button>
    </div>
    <div class="common-white-box vob-note-height-btn-title">
        <ul class="nav nav-tabs common-nav-tab note-mr-btm inner-square-nav-tav" id="NoteTab" role="tablist">
            <li class="nav-item" *ngIf="currentUserDomain == userDomainType.Internal" role="presentation"
                [ngStyle]="{'display': mode === 1 && currentUserDomain !== userDomainType.Internal ? 'none' : 'inline-block'}">
                <button class="nav-link w-100" [ngClass]="{'active': currentTab === notesTabIds.InternalTab}"
                    id="Internal-tab" data-bs-toggle="tab" (click)="changeTab(notesTabIds.InternalTab)" type="button"
                    role="tab" aria-controls="Internal" aria-selected="true">
                    Internal
                </button>
            </li>
            <li class="nav-item" role="presentation"
                [ngStyle]="{'display': mode === 1 && currentUserDomain !== userDomainType.External ? 'none' : 'inline-block'}">
                <button class="nav-link w-100" [ngClass]="{'active': currentTab === notesTabIds.ExternalTab}"
                    id="External-tab" data-bs-toggle="tab" (click)="changeTab(notesTabIds.ExternalTab)" type="button"
                    role="tab" aria-controls="External" aria-selected="false">
                    External
                </button>
            </li>
            <li class="nav-item" role="presentation" *ngIf="currentUserDomain == userDomainType.Internal">
                <button class="nav-link w-100" id="History-tab" data-bs-toggle="tab"
                    [ngClass]="{'active': currentTab === notesTabIds.HistoryTab}"
                    (click)="changeTab(notesTabIds.HistoryTab)" type="button" role="tab" aria-controls="History"
                    aria-selected="false">
                    History
                </button>
            </li>
            <li class="nav-item" role="presentation" *ngIf="currentUserDomain == userDomainType.Internal && noteTypeId != 2">
                <button class="nav-link w-100" id="Communication-tab" data-bs-toggle="tab"
                    [ngClass]="{'active': currentTab === notesTabIds.CommunicationTab}"
                    (click)="changeTab(notesTabIds.CommunicationTab)" type="button" role="tab"
                    aria-controls="Communication" aria-selected="false">
                    Communication
                </button>
            </li>
        </ul>
        <div class="tab-content notes-height">
            <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="Internal-tab"
                *ngIf="this.currentTab <= 1">
                <!-- Internal Notes -->
                <div
                    *ngIf="this.currentTab == notesTabIds.InternalTab && this.currentUserDomain == userDomainType.Internal">
                    <div *ngFor="let item of myInternalNotes">
                        <div class="vob-note-box" *ngIf="item.userNotes !=''"
                            style="border-bottom: 1px solid #E5EDEF; margin-bottom: 11px; padding-bottom: 14px;">
                            <p>{{item.userNotes}}</p>
                            <span class="date-time-text">
                                {{item.updatedBy}} {{ item.updatedDate | localDateTime}}
                            </span>
                            <div class="dropdown table-dropdown note-action-dropdown" *ngIf="item.canModify">
                                <a href="javascript:;" class="dropdown-toggle filter-icon" role="button"
                                    id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="custom-icons icon-list-menu"></i>
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <a class="dropdown-item" href="javascript:;"
                                            (click)="openDialog(item.notesId, item.userNotes)"><span
                                                class="custom-icons icon-pencil"></span>Edit</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="javascript:;" (click)="deleteNote(item.notesId)">
                                            <span class="custom-icons icon-trash"></span>Delete</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="myInternalNotes?.length <= 0" class="chart-not-found-msg" style="margin-top: 30%;">
                        <h5>No records available.</h5>
                    </div>
                </div>
                <!-- External Notes -->
                <div
                    *ngIf="this.currentTab == notesTabIds.ExternalTab && (this.currentUserDomain == userDomainType.Internal || this.currentUserDomain == userDomainType.External) ">
                    <div *ngFor="let item of myExternalNotes">
                        <div class="vob-note-box" *ngIf="item.userNotes !=''"
                            style="border-bottom: 1px solid #E5EDEF; margin-bottom: 11px; padding-bottom: 14px;">
                            <p>{{item.userNotes}}</p>
                            <span class="date-time-text">
                                {{item.updatedBy}} {{ item.updatedDate | localDateTime}}
                            </span>
                            <div class="dropdown table-dropdown note-action-dropdown" *ngIf="item.canModify">
                                <a href="javascript:;" class="dropdown-toggle filter-icon" role="button"
                                    id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                                    <i class="custom-icons icon-list-menu"></i>
                                </a>
                                <ul class="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                    <li>
                                        <a class="dropdown-item" href="javascript:;"
                                            (click)="openDialog(item.notesId, item.userNotes)"><span
                                                class="custom-icons icon-pencil"></span>Edit</a>
                                    </li>
                                    <li>
                                        <a class="dropdown-item" href="javascript:;" (click)="deleteNote(item.notesId)">
                                            <span class="custom-icons icon-trash"></span>Delete</a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div *ngIf="myExternalNotes?.length <= 0" class="chart-not-found-msg" style="margin-top: 30%;">
                        <h5>No records available.</h5>
                    </div>
                </div>
            </div>

            <!-- History Tab -->
            <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="History-tab"
                *ngIf="this.currentTab === notesTabIds.HistoryTab">
                <div class="common-border table-responsive">
                    <kendo-grid [data]="historyList" [pageSize]="state.take!" [skip]="state.skip!" [sort]="state.sort!"
                        [sortable]="false" [pageable]="false" filterable="menu"
                        (dataStateChange)="dataStateChange($event)">
                        <kendo-grid-column title="View" [filterable]="false" [sortable]="false" [width]="70"
                            [headerClass]="{'text-center': true}">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <ul class="grid-action-icon justify-content-end" *ngIf="dataItem.showDetail === 1">
                                    <li>
                                        <a href="javascript:;"
                                            (click)="showVobDetailedHistoryByVobId(dataItem.historyId);">
                                            <div kendoTooltip class="grid-tooltip">
                                                <span class="custom-icons icon-eye" [title]="'Detailed Info'"></span>
                                            </div>
                                        </a>
                                    </li>
                                </ul>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="historyId" title="HistoryId" [filterable]="false" [hidden]="true">
                        </kendo-grid-column>
                        <kendo-grid-column field="historyDetails" title="Details" [filterable]="false" [width]="250">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div kendoTooltip class="grid-tooltip">
                                    <span title="{{dataItem.historyDetails}}">{{dataItem.historyDetails | elipsisText:50
                                        }}</span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column title="By User" [filterable]="false" [width]="180">
                            <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                                <div kendoTooltip class="grid-tooltip">
                                    <span
                                        title="{{'Department: ' + dataItem.departmentName}}">{{dataItem.byUserName}}</span>
                                </div>
                            </ng-template>
                        </kendo-grid-column>
                        <kendo-grid-column field="createdDate" title="Date" [filterable]="false" [width]="180"
                            filter="date" format="{0:dd/MM/yyyy}">
                            <ng-template kendoGridCellTemplate let-dataItem>
                                <span>{{dataItem.createdDate | localDateTime}}</span>
                            </ng-template>
                        </kendo-grid-column>
                    </kendo-grid>
                </div>
            </div>

            <!-- Communication Tab -->
            <div class="tab-pane fade show active" role="tabpanel" aria-labelledby="Communication-tab"
                *ngIf="this.currentTab === notesTabIds.CommunicationTab && noteTypeId != 2">
                <div class="common-border table-responsive" *ngIf="communicationList?.length > 0">
                    <div *ngFor="let email of communicationList">
                        <div class="common-white-box box-vob-demographic-grid-height"
                            style="border-top-color: #4297BF;">
                            <div class="row">
                                <div class="row">
                                    <div class="form-group">
                                        <label class="form-label" style="font-weight: 600;color:#05B5E4;">Received
                                            Date</label><br>
                                        <label class="form-label">{{email.receivedDateTime | localDateTime}}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group">
                                        <label class="form-label"
                                            style="font-weight: 600;color:#05B5E4;">Sender</label><br>
                                        <label class="form-label">{{email.senderEmailAddress}}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group">
                                        <label class="form-label"
                                            style="font-weight: 600;color:#05B5E4;">Receipient</label><br>
                                        <label class="form-label">{{email.toEmailAddress}}</label>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="form-group">
                                        <label class="form-label"
                                            style="font-weight: 600;color:#05B5E4;">Subject</label><br>
                                        <label class="form-label">{{email.subject}}</label>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="form-group">
                                    <label class="form-label" style="font-weight: 600;color:#05B5E4;">Email
                                        Content</label><br>
                                </div>
                                <div class="accordian-content-text"
                                    [innerHTML]="this.sanitizer.bypassSecurityTrustHtml(email.bodyContent)">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div *ngIf="communicationList?.length <= 0" class="chart-not-found-msg" style="margin-top: 30%;">
                    <h5>No communication thread available.</h5>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Add Notes Start -->
<kendo-dialog title="{{editDialog.title}}" *ngIf="editDialog.isOpen" (close)="editDialog.isOpen = false;"
    class="kendo-modal modal-md">
    <div class="common-popup-space">
        <form [formGroup]="editDialog.formGroup" (ngSubmit)="submitNotes()" autocomplete="off">
            <div class="row">
                <div class="col-12 col-md-12">
                    <div class="form-group">
                        <label class="form-label">Note <span class="field-required">*</span></label>
                        <div *ngIf="externalAddMode === 1 && currentTab === 1">
                            <kendo-dropdownlist [data]="externalAddSelectOptions" maxlength="450"
                                formControlName="noteExternalSelect"
                                [defaultItem]="{  text: 'Select note', value: null }" [valuePrimitive]="true"
                                [kendoDropDownFilter]="externalSelectFilterSettings"
                                [ngClass]="{ 'is-invalid': editDialog.formGroup.get('noteExternalSelect').errors && editDialog.isSubmitted}"
                                textField="text" valueField="value"
                                (selectionChange)="onExternalSelectIndexChanged($event)">
                            </kendo-dropdownlist>
                            <div *ngIf="editDialog.formGroup.get('noteExternalSelect').errors && editDialog.isSubmitted"
                                class="invalid-feedback">
                                Please select note
                            </div>
                        </div>
                        <div *ngIf="externalAddMode === 0 || currentTab !== 1">
                            <textarea class="form-control" formControlName="noteText" placeholder="Note"
                                maxlength="4000"
                                [ngClass]="{ 'is-invalid': editDialog.formGroup.get('noteText').errors && editDialog.isSubmitted}"></textarea>
                            <div *ngIf="editDialog.formGroup.get('noteText').errors && editDialog.isSubmitted"
                                class="invalid-feedback">Please enter a note</div>
                        </div>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <kendo-dialog-actions class="d-block text-end">
        <button kendoButton (click)="submitNotes()" class="btn btn-primary btn-md">Save</button>
        <button kendoButton (click)="editDialog.isOpen = false;" class="btn btn-cancel btn-md">Cancel</button>
    </kendo-dialog-actions>
</kendo-dialog>
<!-- Add Notes Popup End -->

<app-cereus-dialog [showConfirmDialog]="isDeleteMode" [dialogBodyMessage]="'Are you sure you want to delete?'"
    (yesButtonClickEvent)="doDeleteSelectedNote()" (noButtonClickEvent)="doDeleteCancelNote()">
</app-cereus-dialog>

<!-- History Detailed Information START-->
<kendo-dialog title="VOB Update History" *ngIf="showVobDetailHistory" (close)="showVobDetailHistory=false"
    class="kendo-modal modal-1180">
    <div class="common-border table-responsive">
        <kendo-grid [data]="detailedHistoryList" [pageSize]="state.take!" [skip]="state.skip!" [sort]="state.sort!"
            [sortable]="false" [pageable]="false" filterable="menu">
            <kendo-grid-column field="historyId" title="Id" [filterable]="false" [width]="50">
            </kendo-grid-column>
            <kendo-grid-column field="fieldId" title="Field Changed" [filterable]="false" [width]="210">
                <ng-template kendoGridCellTemplate let-dataItem let-rowIndex="rowIndex">
                    <div>
                        <span>{{dataItem.fieldId | camelCaseToSpaces}}</span>
                    </div>
                </ng-template>
            </kendo-grid-column>
            <kendo-grid-column field="oldValue" title="Old Change" [filterable]="false" [width]="180">
            </kendo-grid-column>
            <kendo-grid-column field="newValue" title="New Change" [filterable]="false" [width]="180">
            </kendo-grid-column>
            <kendo-grid-column field="byUserName" title="By User" [filterable]="false" [width]="180">
            </kendo-grid-column>
            <kendo-grid-column field="createdDate" title="Date" [filterable]="false" [width]="180" filter="date"
                format="{0:dd/MM/yyyy}">
                <ng-template kendoGridCellTemplate let-dataItem>
                    <span>{{dataItem.createdDate | localDateTime}}</span>
                </ng-template>
            </kendo-grid-column>
        </kendo-grid>
    </div>
    <kendo-dialog-actions class="d-block text-end">
        <button kendoButton (click)="showVobDetailHistory=false" class="btn btn-cancel btn-md">Close</button>
    </kendo-dialog-actions>
</kendo-dialog>
<!-- History Detailed Information END-->

<!-- VOB Send Email START -->
<kendo-dialog title="Reply to Email" *ngIf="showSendEmail" (close)="showSendEmail=false" class="kendo-modal modal-md">
    <div class="table-responsive">
        <form [formGroup]="sendEmailForm">
            <div class="form-group ">
                <label class="form-label">Receipient Email <span
                        class="field-required">*</span></label>
                <input type="text" class="form-control" placeholder="Please enter valid email" formControlName="toEmailId" id="toEmailId"
                    [ngClass]="{ 'is-invalid': sendEmailFormControls.toEmailId.errors }">
                <div *ngIf="sendEmailFormControls.toEmailId?.errors"
                    class="invalid-feedback">
                    <div *ngIf=" sendEmailFormControls.toEmailId.errors?.required">Receipient email required.</div>
                </div>
            </div>
            <div class="form-group ">
                <label class="form-label">Subject <span
                        class="field-required">*</span></label>
                <input type="text" class="form-control" placeholder="Please type subject line" formControlName="emailSubject" id="emailSubject"
                    [ngClass]="{ 'is-invalid': sendEmailFormControls.emailSubject.errors }">
                <div *ngIf="sendEmailFormControls.emailSubject?.errors"
                    class="invalid-feedback">
                    <div *ngIf=" sendEmailFormControls.emailSubject.errors?.required">Email subject is required.</div>
                </div>
            </div>
            <div class="form-group">
                <label class="form-label">Email Details <span
                    class="field-required">*</span></label>                
                <textarea class="form-control" formControlName="sendEmailContent" style="height:400px;" placeholder="Email Body" maxlength="4000" 
                    [ngClass]="{ 'is-invalid': sendEmailFormControls.sendEmailContent.errors}"></textarea>
                <div *ngIf="sendEmailFormControls.sendEmailContent?.errors" class="invalid-feedback">
                    <div *ngIf=" sendEmailFormControls.sendEmailContent.errors?.required">Email details is required.</div>
                </div>
            </div>
        </form>
    </div>
    <kendo-dialog-actions class="d-block text-end">
        <button kendoButton (click)="sendVobEmail()" class="btn btn-primary btn-md">
            <img class="email-white-icon" height="19" width="19" />&nbsp;&nbsp;Send</button>
        <button kendoButton (click)="showSendEmail=false" class="btn btn-cancel btn-md">Close</button>
    </kendo-dialog-actions>
</kendo-dialog>
<!-- VOB Send Email End -->