import { Pipe, PipeTransform } from "@angular/core";

@Pipe({ name: 'camelCaseToSpaces' })

export class CamelCaseToSpacesPipe implements PipeTransform {
  transform(camelCaseText: string): string {
    if (!camelCaseText) return '';
    return camelCaseText.replace(/([a-z])([A-Z])/g, '$1 $2');
  }
}


@Pipe({ name: 'elipsisText' })

export class ElipsisTextPipe implements PipeTransform {

  transform(value: string, limit: number): string {
      return value.length > limit ? value.substring(0, limit) + '...' : value;
  }
}