import { ChangeDetectorRef, Component, ElementRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { FormBuilder, FormControlName, FormGroup, Validators } from '@angular/forms';
import { GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { CompositeFilterDescriptor, SortDescriptor } from '@progress/kendo-data-query';
import { debounceTime, fromEvent, lastValueFrom, merge, Observable } from 'rxjs';
import { DocumentTypeMasterService } from 'src/app/modules/auth/administration/document-workflow/document-workflow.service';
import { documentsService } from 'src/app/modules/auth/services/document/document.services';
import { CoreHelperService } from 'src/app/providers/core-helper/core-helper.service';
import { MenuItems, Modules } from 'src/app/providers/core-role-access-service';
import { documentMasterMessages } from 'src/app/shared/CommonValidation/form-validators-messages';
import { GenericValidator } from 'src/app/shared/CommonValidation/generic-validator';
import { TopActivityService } from '../../service/topactivity.service';
import { CensusService } from 'src/app/modules/auth/services/census/census.service';
import { ReportService } from 'src/app/modules/auth/report/report.service';
import { ActivatedRoute } from '@angular/router';
import { messagesService } from 'src/app/modules/auth/messages/messages.services';
import { CommonPageService } from 'src/app/shared/utility/common-page.service';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { DynamicListData } from '../../DynamicListData';
import { DatePickerComponent } from '@progress/kendo-angular-dateinputs';
import { IdNameModel } from '../../service/topativity.classes';
import { FileRestrictions, SelectEvent } from '@progress/kendo-angular-upload';
import { ContextMenuComponent, ContextMenuSelectEvent } from '@progress/kendo-angular-menu';
import { menuItems } from '../../service/menu-items';
import { ModuleFeatureIds } from '../../enum/module-feature';
import { CommonNotificationService } from '../../service/common-notification.service';

@Component({
  selector: 'app-document-tab',
  templateUrl: './document-tab.component.html',
  styleUrls: ['./document-tab.component.scss']
})

export class DocumentTabComponent implements OnInit {
  @ViewChild("gridmenu") public gridContextMenu: ContextMenuComponent;
  @ViewChild("kendoGridForDocument") public grid: GridComponent;
  contextMenuList: any = menuItems;

  @Input() isPatientCaseView: boolean = false;
  @Input() departmentId: number = 0;
  @Input() documentTypeId: number = 0;
  @Input() facilityId: number = 0;
  @Input() patientId: number = 0;
  @Input() isDisplayButtons: boolean = false;
  documentTypeList = DynamicListData.DocumentTypeList;
  @ViewChildren('datePicker') datePickers: QueryList<DatePickerComponent>;
  userDetail;
  isFromNotification = false;
  DocumentTitle = 'Add Document';

  documentTypes: Array<any> = [];
  documentsTags: Array<any> = [];
  originalDocumentsTags: Array<any> = [];
  patientList: Array<any> = [];
  facilityList: Array<any> = [];
  originalFacilityList: Array<any> = [];
  fileTypes: Array<any> = ['png', 'jpg', 'pdf', 'doc', 'docx', 'xlsx', 'txt'];
  documentStatus: Array<any> = [];
  documentList: any[] = [];
  originalDocumentList: any[] = [];
  documentHistory: any[] = [];
  assignUserList: any[] = [];
  specialistUserList: any[] = [];
  addDocumentForm: FormGroup | any;
  responsePageDocument: any = 3;
  submitted: boolean = false;
  submittedCategory: boolean = false;
  submittedSubCategory: boolean = false;
  disabled: boolean = true;
  isDocumentEdit: boolean = false;
  isValidExtension: boolean = true;
  isValidSize: boolean = false;
  filePath: string = '';
  fileName: string = '';
  categoryId: number = 0;
  paramObj: any = {};
  fileSize: any = 6000000;
  assignUserId: number = 0;
  specialistUserId: number = 0;
  documentId: number = 0;
  archivedData: boolean = false;

  private genericValidator: GenericValidator;
  displayMessage: { [key: string]: string } = {};
  displayMessageCategory: { [key: string]: string } = {};
  displayMessageSubCategory: { [key: string]: string } = {};
  documentDropdownStatus = new Array<IdNameModel>();

  public myRestrictions: FileRestrictions = {
    allowedExtensions: ['.png', '.jpg', '.pdf', '.doc', '.docx', '.xlsx', '.txt',],
    maxFileSize: this.fileSize,
  };

  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements!: ElementRef[];
  public documentListData: any;
  public formData: FormData = new FormData();
  documentTypeMasterData: any[] = [];
  histroyModelDocumentId = 0;
  public isDelete: boolean = false;
  public deletePopupMessage: string;
  fileId: number = 0;
  modeType: number = 0;
  statusId: number = 1;

  constructor(
    private _documentService: documentsService,
    private _censusService: CensusService,
    public _documentTypeMasterService: DocumentTypeMasterService,
    public reportServices: ReportService,
    private fb: FormBuilder,
    public _topActivity: TopActivityService,
    public _coreHelper: CoreHelperService,
    private _activeRoute: ActivatedRoute,
    private _commonNotificationService: CommonNotificationService,
    private _messagesService: messagesService,
    public _commonPageService: CommonPageService,
    private readonly changeDetectorRef: ChangeDetectorRef
  ) {
      this.permissionList = this._coreHelper.geDocumentVaultModulePermission();
      this.featurePermissionList = this._coreHelper.getDocumentVaultModuleFeaturePermission();
      this.genericValidator = new GenericValidator(documentMasterMessages);
      this.addDocumentForm = this.fb.group({
        documentDate: [new Date()],
        expiryDate: [''],
        documentId: [''],
        documentTypeId: [2, Validators.required],
        categoryId: ['', Validators.required],
        subCategoryId: ['', Validators.required],
        patientId: [0],
        facilityId: [0],
        tags: [''],
        statusId: [1, Validators.required],
        fileTypeId: [''],
        documentUpload: ['', Validators.required],
        internalNotes: [
          null,
          [Validators.maxLength(450)],
        ],
        externalNotes: [
          null,
          [Validators.maxLength(450)],
        ],
      });
      this._activeRoute.queryParams.subscribe((params) => {
        if (params && params['tab'] && params['tab'] == 'taskmanagement') {
          this.isFromNotification = true;
        }
      });
  }

  ngOnInit(): void {
    this.clearFilterDocuments();
    this.getFacilityList();
    this.userDetail = this._coreHelper.getLoggedInUserDetail();
  }

  public reLoadDocumentGrid(){
    this.clearFilterDocuments();
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };
  //#region  validation method

  ngAfterViewInit(): void {
    // Watch for the blur event from any input element on the form.
    const controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur')
    );
    // Merge the blur event observable with the valueChanges observable
    merge(this.addDocumentForm.valueChanges, ...controlBlurs)
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.displayMessage = this.genericValidator.processMessages(
          this.addDocumentForm,
          this.submitted
        );
      });

    this.loadPermission();
    this.changeDetectorRef.detectChanges();
  }

  //#endregion

  // Page Title
  setTitle(title: string) {
    localStorage.setItem('title', title);
  }
  changeFocusToMonth(index: number) {
    const datePicker = this.datePickers.toArray()[index];
    if (datePicker) {
      datePicker.focus();
    }
  }
  //#region Model Open Close methods


  // Modal Start
  public addDocument = false;
  public history = false;
  public clickedRowItem;
  isViewMode: boolean = false;
  createdByName = "";

  public disabledDates = (date: Date): boolean => {
    let today = new Date();
    date.setDate(date.getDate() + 1);
    return date < today;
  }

  public addDocumentClose() {
    this.addDocument = false;
    this.isSubmit = false;
    this.isViewMode = false;
    this.isValidExtension = true;
  }

  public addDocumentOpen(documentId: number, viewMode: boolean) {
    this.isViewMode = viewMode;
    if (this._coreHelper.isNullOrUndefined(documentId)) {
      documentId = this.clickedRowItem.fileId;
    }
    this.addDocumentForm.reset();

    this.submitted = false;
    this.addDocumentForm.controls.documentTypeId.setValue(2);
    documentId == 0 ? this.getCategoryListByDocumentTypeId(2) : '';
    this.addDocument = true;
    if (documentId > 0) {
      this.DocumentTitle = !this.isViewMode ? 'Edit Document' : 'View Document';
      let param = { documentId: documentId };
      this.addDocumentForm.controls.statusId.setValue(2);
      this._documentService.getDocumentList(param).subscribe(
        (Response: any) => {
          let data = Response.apiData[0];
          if (data != null && Response.apiStatus) {
            this.documentsTags = this.originalDocumentsTags;
            this.isValidSize = false;
            this.disabled = false;
            this.isDocumentEdit = true;
            this.filePath = data.filePath == '' ? null : data.filePath;
            this.fileName = data.fileName == '' ? null : data.fileName;
            this.createdByName = data.updatedByName;
            this.getCategoryListByDocumentTypeId(data.documentTypeId, data.categoryId);
            this.addDocumentForm.controls.documentId.setValue(data.fileId);
            this.addDocumentForm.controls.documentTypeId.setValue(data.documentTypeId);
            if (!data.createdDate.toLocaleLowerCase().endsWith('z')) {
              data.createdDate += 'Z';
            }

            this.addDocumentForm.controls.documentDate.setValue(
              new Date(data.createdDate)
            );

            if (!this._coreHelper.isNullOrUndefined(data.expiryDate)) {
              if (!data.expiryDate.toLocaleLowerCase().endsWith('z')) {
                data.expiryDate += 'Z';
              }

              this.addDocumentForm.controls.expiryDate.setValue(
                new Date(data.expiryDate)
              );
            }

            this.addDocumentForm.controls.categoryId.setValue(data.categoryId);
            this.addDocumentForm.controls.subCategoryId.setValue(
              data.subCategoryId
            );
            this.addDocumentForm.controls.documentUpload.setValue('');
            this.getPatientList(data.facilityId);
            this.addDocumentForm.controls.patientId.setValue(data.patientOrFacilityId?.indexOf(',') > -1 ? data.patientOrFacilityId.split(',').map(Number) : data.patientOrFacilityId?.split().map(Number));
            this.addDocumentForm.controls.facilityId.setValue(data.facilityId);
            this.addDocumentForm.controls.tags.setValue(data.tagName?.split(','));

            this.addDocumentForm.controls.internalNotes.setValue(
              !this._coreHelper.isStringNullOrUndefined(data.internalNotes) ? data.internalNotes : ''
            );
            this.addDocumentForm.controls.externalNotes.setValue(
              !this._coreHelper.isStringNullOrUndefined(data.externalNotes) ? data.externalNotes : ''
            );
          }
        },
        (error) => { }
      );
    } else {
      this.DocumentTitle = 'Add Document';
      this.createdByName = this.userDetail.firstName + ' ' + this.userDetail.lastName;
      this.addDocumentForm.controls.documentDate.setValue(
        new Date()
      );
      this.addDocumentForm.controls.patientId.setValue(0);

      if (this.originalFacilityList.length == 1) {
        this.addDocumentForm.controls.facilityId.setValue(this.originalFacilityList[0].facilityId);
      }
      else {
        this.addDocumentForm.controls.facilityId.setValue(0);
      }
      this.addDocumentForm.controls.statusId.setValue(1);
      this.addDocumentForm.controls.documentId.setValue(0);
      this.isDocumentEdit = false;
      this.fileName = null;
      this.filePath = null;
    }
  }

  public historyClose() {
    this.history = false;
    this.histroyModelDocumentId = 0;
  }
  public historyOpen(documentId: number) {
    this.getDocumentHistory(documentId);
    this.histroyModelDocumentId = documentId;
    this.history = true;
  }

  //#endregion

  public get module() {
    return Modules;
  }

  public get menuItems() {
    return MenuItems;
  }


  onCellClick(e: any) {
    this.clickedRowItem = e.dataItem;
    if (e.type === "contextmenu") {
      const originalEvent = e.originalEvent;
      originalEvent.preventDefault();
      this.grid.closeRow(e.rowIndex);
      this.gridContextMenu.show({
        left: originalEvent.pageX,
        top: originalEvent.pageY,
      });
    }
  }

  onContextMenuSelected(event: ContextMenuSelectEvent): void {
    let menuOptions = event.item.text;
    if (menuOptions == "Edit") {
      this.addDocumentOpen(this.clickedRowItem.fileId, false)
    }
    else if (menuOptions == "Delete") {
      this.deleteDocument(this.clickedRowItem.fileId, 1)

    } else if (menuOptions == "View") {
      this.addDocumentOpen(this.clickedRowItem.fileId, true)
    }
  }

  categoryMasterData: any[] = [];
  subCategoryMasterData: any[] = [];
  getCategoryListByDocumentTypeId(typeId: number, categoryId: number = 0) {
    this.categoryMasterData = []
    this.subCategoryMasterData = []
    let data = this.addDocumentForm.value;
    if (data?.facilityId != null) {
      this.patientList = this.patientList.filter(
        (s) => s.facilityId == data.facilityId
      );
    }


    this.addDocumentForm.controls.subCategoryId.setValue(null);
    this.addDocumentForm.controls.categoryId.setValue(null);
    this._documentService.getDocumentcategoryList(typeId, categoryId).subscribe(res => {
      if (res.apiStatus) {
        let val = categoryId == 0 ? null : categoryId
        this.addDocumentForm.controls.categoryId.setValue(val);
        this.categoryMasterData = res.apiData.documentCategoryList;
        this.subCategoryMasterData = res.apiData.documentSubCategoryList;
      }
    })
  }

  async getPatientList(facilityId: number) {
    let FacilityId = { FacilityId: facilityId };
    await lastValueFrom(this._censusService.getPatientByFacilityId(FacilityId)).then((response) => {
      if (response.apiStatus === true) {
        this.patientList = response.apiData;
      }
    });
  }

  getFacilityList() {
    let facility: any = { 'facilityId': this._coreHelper.getFacilityId() }
    this.reportServices.getFacilityForFilter(facility).subscribe(response => {
      if (response.apiStatus) {
        this.originalFacilityList = response.apiData;
        this.facilityList = response.apiData;
      }
    });
  }

  downloadMyFile(url: string, name: string) { }

  //#endregion

  //#region Add update document
  public uploadFileSelect(file: SelectEvent) {
    this.isValidExtension = (this.fileTypes.includes(file.files[0].name.split('.').pop().toLowerCase()));
  }

  public uploadFileRemove() {
    this.isValidExtension = true;
  }

  addTag($event) {
    if (!!$event.target.value) {
      this.documentsTags.push({ 'tagName': $event.target.value, 'tagValue': $event.target.value });
    }
  }

  isSubmit: boolean = false;
  saveDocument(): void {
    this.isSubmit = true;
    let data = this.addDocumentForm.value;

    if (
      (data.documentUpload == null ||
        data.documentUpload == '' ||
        data.documentUpload == undefined) &&
      this.isDocumentEdit
    ) {
      this.addDocumentForm.get('documentUpload')?.clearValidators();
      this.addDocumentForm.get('documentUpload').updateValueAndValidity();
      this.formData.set('DocumentUpload', '');
    } else if (data.documentUpload != null) {
      this.formData.set('DocumentUpload', data.documentUpload[0]);
    }

    if (this.addDocumentForm.invalid || ((data?.patientId == null || data.patientId == 0) && data.documentTypeId == 2)) {
      this.submitted = true;
      this.displayMessage = this.genericValidator.processMessages(
        this.addDocumentForm,
        this.submitted
      );
      return;
    }
    if (!this.isValidExtension) {
      return;
    }

    if (this.isDocumentEdit) {
      if (
        !(
          data.documentUpload == null ||
          data.documentUpload == '' ||
          data.documentUpload == undefined
        )
      ) {
        if (data.documentUpload[0].size > this.fileSize) {
          this.isValidSize = true;
          return;
        }
      }
      this.formData.set('DocumentId', data.documentId);
      this.formData.set('SpMode', '1'); // 1 means record update
    } else {
      this.formData.set('DocumentUpload', data.documentUpload[0]);
      this.formData.set('SpMode', '0'); // 0 means record insert
    }
    this.formData.set('DocumentTypeId', data.documentTypeId);
    this.formData.set('DocumentDate', this._coreHelper.getUtcDate(data.documentDate).toJSON());
    this.formData.set('CategoryId', data.categoryId);
    this.formData.set('SubCategoryId', data.subCategoryId);
    this.formData.set('PatientOrFacilityIds', data.documentTypeId == 1 ? data.facilityId : data.patientId);
    this.formData.set('FacilityId', data.facilityId);
    this.formData.set('TagName', data.tags ?? '');
    this.formData.set('StatusId', data.statusId);
    this.formData.set('ExpiryDate', !this._coreHelper.isNullOrUndefined(data.expiryDate) ? this._coreHelper.getUtcDate(data.expiryDate).toJSON() : '');
    this.formData.set('AssignToLead', this.assignUserId.toString());
    this.formData.set('AssignToSpecialist', this.specialistUserId.toString());
    this.formData.set('InternalNotes', data.internalNotes ?? '');
    this.formData.set('ExternalNotes', data.externalNotes ?? '');
    this.formData.set('CreatedBy', this._coreHelper.getUserId().toString()); //static for now it should dyanmic after OKta integration
    this.formData.set('IsArchive', '0');

    this._documentService.addUpdateDocument(this.formData).subscribe((res) => {

      if (res.apiStatus == true) {
        this.addDocumentClose();
        this.getDocumentMasterForGrid();
        this.addDocumentForm.reset();
        this.fileName = '';
        this.filePath = '';
        this.isValidSize = false;
      }
    });
  }
  //#endregion

  //#region Get All Document

  getArchivedData() {
    this._commonPageService.clearCommonState();
    this.getDocumentMasterForGrid(this.archivedData);
  }

  getDocumentHistory(documentId: number) {
    let param = { DocumentId: documentId };
    this.documentHistory = [];
    this._documentService.documentHistory(param).subscribe((res) => {
      if (res.apiStatus) {
        this.documentHistory = res.apiData;
      }
    });
  }

  //#endregion

  public downloadDocument(fileId: number, statusId: number) {
    this.fileId = fileId;
    this.statusId = statusId;
    this.isDelete = false;
    this.download();
  }
  // modeType 
  // 1 - Delete Mode
  // 2 - Archive Mode
  public deleteDocument(fileId: number, modeType: number) {
    this.fileId = fileId;
    this.modeType = modeType;
    this.deletePopupMessage = modeType === 1 ? 'Are you sure you want to delete ?' : 'Are you sure you want to archive ?';
    this.isDelete = true;
  }

  download() {
    if (this.statusId > 0) {
      this._documentService.downloadDocumentById(this.fileId).subscribe((Response: any) => {
        if (Response.apiStatus) {
          this.streamDownloadFile(Response.apiData);
        }
      });
    } else {
      //Messaging document
      this._messagesService.downloadDocumentByMessageDocumentId(this.fileId).subscribe((Response: any) => {
        if (Response.apiStatus) {
          this.streamDownloadFile(Response.apiData);
        }

      });
    }
  }

  streamDownloadFile(fileInfo: any) {
    let byteCharacters = atob(fileInfo.fileContents);
    let byteNumbers = new Array(byteCharacters.length);

    for (var i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    var byteArray = new Uint8Array(byteNumbers);
    let binaryData = [];
    binaryData.push(byteArray);

    let downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, { type: 'blob' }));
    downloadLink.setAttribute('download', fileInfo?.fileDownloadName);
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  }

  delete() {
    if (this.modeType === 1) {
      this._documentService.deleteDocumentById(this.fileId).subscribe(res => {
        if (res.apiStatus) {
          this._commonNotificationService.displaySuccessMessage(res.apiMessage);
          this.getDocumentMasterForGrid();
        }
        else {
          this._commonNotificationService.displayError(res.apiMessage);
        }
        this.isDelete = false;
      });
    }
    else {
      this._documentService.archiveDocumentById(this.fileId, 1).subscribe(res => {
        if (res.apiStatus) {
          this.isDelete = false;
          this.getDocumentMasterForGrid();
        }
      });
    }

  }
  docFacilityMultiselectChange(event: any) {
    if (this.addDocumentForm.value.documentTypeId == 2)
      this.getPatientList(event);

    let dataVal = event;
    let updatedVal = [];
    if (dataVal.length > 0) {
      updatedVal.push(dataVal[dataVal.length - 1]);
      this.addDocumentForm.controls.patientId.setValue(updatedVal);
    } else {
      this.addDocumentForm.controls.patientId.setValue(0);
    }
  }

  //#region Permission
  permissionList: any = [];
  featurePermissionList: any = [];

  showUploadButton: boolean = false;
  showFileSwitch: boolean = false;
  loadPermission() {
    if (this.featurePermissionList.length > 0) {
      let filteredList = this.featurePermissionList.filter(r => r.moduleFeatureMasterId === ModuleFeatureIds.DocumentVault_FileSwitch && r.isEdit > 0);
      this.showFileSwitch = (filteredList != undefined && filteredList != null && filteredList.length > 0);

      filteredList = this.featurePermissionList.filter(r => r.moduleFeatureMasterId === ModuleFeatureIds.DocumentVault_Upload && r.isEdit > 0);
      this.showUploadButton = (filteredList != undefined && filteredList != null && filteredList.length > 0);
    }
  }
  //#endregion
  //#region Sorting Grid Data
  public sortChangeDocuments(sort: SortDescriptor[]): void {
    this._commonPageService.clearCommonState();
    this._commonPageService.commonState.sort = sort;
    this.getDocumentMasterForGrid();
  }
  //#endregion

  //#region Paging Grid Data
  public onPageChangeDocuments(event: PageChangeEvent): void {
    this._commonPageService.clearCommonState();
    this._commonPageService.commonState.take = event.take;
    this._commonPageService.commonState.skip = event.skip;
    this.getDocumentMasterForGrid();
  }
  //#endregion
  //#region Filter Grid Data
  public filterChangeDocuments(filter: CompositeFilterDescriptor): void {
    this._commonPageService.clearCommonState();
    this._commonPageService.commonState.filter = filter;
    this.getDocumentMasterForGrid();
  }
  //#endregion

  //#region Clear Grid Data
  public clearFilterDocuments() {
    this._commonPageService.clearCommonState();
    this.getDocumentMasterForGrid();
  }
  //#endregion

  //#region Get Grid Data
  async getDocumentMasterForGrid(isArchived: boolean = false) {
    await lastValueFrom(this._documentService.GetDocumentMasterForGrid(this._commonPageService.getCustomPageModel(0, this.facilityId, 0, 0, 0, this.departmentId, this.documentTypeId, this.patientId))).then((response) => {
      if (response.apiStatus) {
        this._commonPageService.clearGroupPaginationListDataCommon();
        this._commonPageService.setGroupPaginationListDataCommon(
          isArchived ?
            response.apiData.data.filter((f: { isArchive: number; }) => f.isArchive == 1) :
            response.apiData.data,
          isArchived ?
            response.apiData.data.filter((f: { isArchive: number; }) => f.isArchive == 1).length :
            response.apiData.total);
      } else {
        this._commonPageService.clearGroupPaginationListDataCommon();
      };
    });
  }
  //#endregion

  //#region Export PDF/EXCEL
  public exportToPDF(): void {
    //gridComponent.saveAsPDF();
  }

  public exportToExcel(): void {
    // gridComponent.saveAsExcel();
  }
  //#endregion
}
