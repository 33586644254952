import { NgModule } from "@angular/core";
import { LocalDatePipe, LocalDateTimePipe, LocalDateTimeSecondPipe, LocalTimeSecondPipe } from "./local-date-time-pipe";
import { CamelCaseToSpacesPipe, ElipsisTextPipe } from "./camelCaseToSpaces.pipe";

@NgModule({
  declarations: [LocalDatePipe, LocalDateTimePipe, CamelCaseToSpacesPipe, ElipsisTextPipe, LocalDateTimeSecondPipe, LocalTimeSecondPipe],
  imports: [],
  exports: [LocalDatePipe, LocalDateTimePipe, CamelCaseToSpacesPipe, ElipsisTextPipe, LocalDateTimeSecondPipe, LocalTimeSecondPipe],
  providers: [LocalDatePipe, LocalDateTimePipe, CamelCaseToSpacesPipe, ElipsisTextPipe, LocalDateTimeSecondPipe, LocalTimeSecondPipe]
})

export class CustomDatePipeModule { }
