export enum DropdownType {
  Role = 1,
  Department = 2,
  Team = 3,
  Facility = 4,
  Carrier = 5,
  Fund = 6,
  Accreditation = 7,
  NotificationMessage = 8,
}
export class DropdownListRequestModel {
  id?: number = 0;
  type?: number = 0;
  domain?: number = 0;
}
export class DropdownListResponseModel {
  value: number;
  stringValue: string;
  text: string;
}
