import { Component, OnInit, Input, Output, SimpleChanges, EventEmitter } from '@angular/core';
import { DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { CoreHelperService } from '../../../providers/core-helper/core-helper.service';
import { FormBuilder, FormGroup, Validators, } from '@angular/forms';
import * as moment from 'moment';
import { SortDescriptor, State } from "@progress/kendo-data-query";
import { DataStateChangeEvent } from "@progress/kendo-angular-grid";
import { UsernameValidator } from 'src/app/shared/CommonValidation/username.validator';
import { UserDomainType } from 'src/app/common/enum/user-domain';
import { CereusNotesMode, CereusNoteTypeId, CeruesNotesExternalAddMode, NotesTabIds } from 'src/app/common/enum/notes-type';
import { lastValueFrom } from 'rxjs';
import { VOBService } from 'src/app/modules/auth/services/vob.service';
import { DomSanitizer } from '@angular/platform-browser';
import { CommonNotificationService } from 'src/app/common/service/common-notification.service';

export class CereusNotesAddUpdateEvent {
    notesId: number;
    noteTypeId: number;
    referenceId: number;
    subReferenceId: number;
    userNotes: string;
    isExternal: number;
    isArchive: number;
    spMode: number;
}

@Component({
    selector: 'app-cereus-notes',
    templateUrl: './cereus-notes.component.html',
    styleUrls: ['./cereus-notes.component.scss'],
})

export class CereusNotesComponent implements OnInit {
    @Input() internalNotes: any[] = [];
    @Input() externalNotes: any[] = [];
    @Input() historyList: any[] = [];
    @Input() mode: CereusNotesMode = CereusNotesMode.ExternalInternal;
    @Input() externalAddMode: CeruesNotesExternalAddMode = CeruesNotesExternalAddMode.Text;
    @Input() externalAddSelectOptions: any[] = [];
    @Input() showAddButton: boolean = false;
    @Output() addUpdateNote = new EventEmitter<CereusNotesAddUpdateEvent>();
    @Input() noteTypeId: CereusNoteTypeId;
    @Input() noteReferenceId: number = 0;
    @Input() noteSubReferenceId: number = 0;
    @Input() patientId: number = 0;

    detailedHistoryList: any[] = [];
    communicationList: any[] = [];
    transactionMode: number = 0;
    editDialog = {
        title: 'Notes',
        isOpen: false,
        isSubmitted: false,
        formGroup: this.fb.group({
            noteId: [],
            noteText: ['', [Validators.required, Validators.maxLength(1000), UsernameValidator.cannotContainSpace]],
            noteExternalSelect: ['', [Validators.required, Validators.maxLength(1000), UsernameValidator.cannotContainSpace]],
        })
    }

    currentUserDomain: number;
    currentUserId: number;
    currentTab: NotesTabIds;
    noteList: any[] = [];
    myInternalNotes: any[] = [];
    myExternalNotes: any[] = [];
    sort: SortDescriptor[] = [];
    state: State = {
        skip: 0,
        take: 10,
    };
    externalSelectFilterSettings: DropDownFilterSettings = {
        caseSensitive: false,
        operator: 'contains',
    };
    isDeleteMode: boolean = false;
    selectedNoteId: number = 0;

    userDomainType = UserDomainType;
    notesTabIds = NotesTabIds;

    showReplyButton: boolean = false;
    showSendEmail: boolean = false;
    sendEmailForm: FormGroup | any;
    emailReceipients: string = "";
    dumpId: number = 0;
    conversationId: string = "";
    emailSubject: string = "";
    
    constructor(
        public _coreHelper: CoreHelperService,
        private fb: FormBuilder,
        public _vobService: VOBService,
        public sanitizer: DomSanitizer,
        public _messageService: CommonNotificationService,
    ) {
    }

    async ngOnInit() {
        this.myInternalNotes = this.internalNotes;
        this.myExternalNotes = this.externalNotes;
        this.currentUserDomain = this._coreHelper.getLoggedInUserDomain();
        this.currentUserId = this._coreHelper.getUserId();
        this.changeTab(NotesTabIds.InternalTab);

        if (this.currentUserDomain == UserDomainType.External) {
            this.changeTab(NotesTabIds.ExternalTab);
        }

        this.sendEmailForm = this.fb.group({
            toEmailId: ['', [Validators.required, Validators.pattern(/^([\w+-.%]+@[\w-.]+\.[A-Za-z]{2,4},?)+$/)]],
            emailSubject: ['', Validators.required],
            sendEmailContent: ['', Validators.required],
        });
    }

    ngOnChanges(changes: SimpleChanges) {
        this.myInternalNotes = this.internalNotes;
        this.myExternalNotes = this.externalNotes;
        this.changeTab(this.currentTab);
    }

    //Notes Tab changes
    changeTab(tabIndex: NotesTabIds) {
        if (tabIndex === NotesTabIds.InternalTab) {
            this.myInternalNotes = [];
            this.myInternalNotes = this.addPropertiesToNotes(this.internalNotes);
        } else if (tabIndex === NotesTabIds.ExternalTab) {
            this.myExternalNotes = [];
            this.myExternalNotes = this.addPropertiesToNotes(this.externalNotes);
        } else if (tabIndex === NotesTabIds.HistoryTab) {
            this.getVobUpdateHistory(0);
        } else if (tabIndex === NotesTabIds.CommunicationTab) {
            this.getVobCommunicationHistory();
        }
        this.currentTab = tabIndex;
    }

    //#region VOB Update History
    async getVobUpdateHistory(paramHistoryId: number = 0) {
        let requestModel: any = {
            vobId: this.noteSubReferenceId,
            requestId: this.noteReferenceId,
            historyId: paramHistoryId,
        }
        await lastValueFrom(this._vobService.getVobUpdateHistoryById(requestModel)).then((response) => {
            if (response.apiStatus) {
                if (paramHistoryId == 0) {
                    this.historyList = [];
                    this.historyList = response.apiData;
                } else {
                    this.showVobDetailHistory = true;
                    this.detailedHistoryList = response.apiData;
                }
            }
        });
    }

    showVobDetailHistory: boolean = false;
    showVobDetailedHistoryByVobId(historyId: number = 0) {
        this.getVobUpdateHistory(historyId);
    }
    //#endregion

    public dataStateChange(state: DataStateChangeEvent): void {
        this.state = state;
    }

    //#region -- SHOW ADD NOTE POPUP
    openDialog(noteId: number, notesContent: string) {
        this.transactionMode = (noteId > 0) ? 1 : 0;
        this.editDialog.formGroup.value.noteText = notesContent;
        const noteType = this.currentTab === NotesTabIds.InternalTab ? 'Internal' : 'External';
        this.editDialog.title = noteId > 0 ? `Edit ${noteType} Note` : `Add ${noteType} Note`;
        this.editDialog.isSubmitted = false;
        this.editDialog.formGroup.patchValue({
            noteId: noteId,
            noteText: notesContent,
            noteExternalSelect: notesContent
        });
        this.editDialog.isOpen = true;
    }

    async submitNotes() {
        this.editDialog.isSubmitted = true;
        let noteTxt = '';
        if (this.externalAddMode === 0 || this.currentTab !== 1) {
            if (this.editDialog.formGroup.get('noteText').errors) {
                return;
            }
            noteTxt = this.editDialog.formGroup.value.noteText;
        } else if (this.externalAddMode === 1 && this.currentTab === 1) {
            if (this.editDialog.formGroup.get('noteExternalSelect').errors) {
                return;
            }
            noteTxt = this.editDialog.formGroup.value.noteExternalSelect;
        }
        let noteModel =
        {
            createdBy: this._coreHelper.getUserId(),
            isArchive: 0,
            notesId: (this.transactionMode == 1) ? this.editDialog.formGroup.value.noteId : 0,
            noteTypeId: this.noteTypeId,
            isExternal: (this.currentTab === NotesTabIds.ExternalTab) ? NotesTabIds.ExternalTab : NotesTabIds.InternalTab,
            referenceId: this.noteReferenceId,
            subReferenceId: this.noteSubReferenceId,
            userNotes: noteTxt,
            spMode: this.transactionMode,
        }
        this.addUpdateNote.emit(
            {
                notesId: noteModel.notesId,
                noteTypeId: noteModel.noteTypeId,
                referenceId: noteModel.referenceId,
                subReferenceId: noteModel.subReferenceId,
                userNotes: noteModel.userNotes,
                isExternal: noteModel.isExternal,
                isArchive: noteModel.isArchive,
                spMode: noteModel.spMode,
            });
        this.editDialog.isOpen = false;
    }
    //#endregion


    //#region -- DELETE SELECTED NOTE
    deleteNote(noteId: number) {
        if (noteId <= 0) {
            return;
        }
        else {
            this.selectedNoteId = noteId;
            this.isDeleteMode = true;
        }
    }

    async doDeleteCancelNote() {
        this.isDeleteMode = false;
    }

    async doDeleteSelectedNote() {
        let noteModel = {
            createdBy: this._coreHelper.getUserId(),
            isArchive: 0,
            notesId: this.selectedNoteId,
            noteTypeId: this.noteTypeId,
            isExternal: (this.currentTab == NotesTabIds.ExternalTab) ? 1 : 0,
            referenceId: this.noteReferenceId,
            subReferenceId: this.noteSubReferenceId,
            userNotes: this.editDialog.formGroup.value.noteText,
            spMode: 2,
        }
        this.addUpdateNote.emit({
            notesId: noteModel.notesId,
            noteTypeId: noteModel.noteTypeId,
            referenceId: noteModel.referenceId,
            subReferenceId: noteModel.subReferenceId,
            userNotes: noteModel.userNotes,
            isExternal: noteModel.isExternal,
            isArchive: noteModel.isArchive,
            spMode: noteModel.spMode,
        });
        this.isDeleteMode = false;
    }
    //#endregion


    // use properties instead of methods; methods are repeatedly called angular; properties aren't
    addPropertiesToNotes(list: any[]) {
        for (let i = 0; i < list.length; i++) {
            list[i]['canModify'] = this.canModifyNote(list[i]);
        }
        return list;
    }

    canModifyNote(item: any): boolean {
        if (this.currentUserId !== item.updatedById
            || this._coreHelper.isNullOrUndefined(item.updatedDate)) {
            return false;
        }
        const nowLocal = new Date();
        // updated within last 5 minutes
        // api dates are UTC; Date class using local time; add offset (- 5 minutes) 
        let dateToCompare = moment(nowLocal).add(nowLocal.getTimezoneOffset() - 25, 'minutes').format('YYYY-MM-DD  HH:mm:ss');
        let recDate = moment(item.updatedDate).format('YYYY-MM-DD  HH:mm:ss');;
        return moment(recDate).isAfter(moment(dateToCompare))
    }

    onExternalSelectIndexChanged(event) {
        this.editDialog.formGroup.value.noteExternalSelect = event.value;
    }

    //#region Communication Section (VOB)
    async getVobCommunicationHistory() {
        let requestModel: any = {
            patientId: this.patientId,
            requestId: this.noteReferenceId,
        }
        await lastValueFrom(this._vobService.getVobEmailHistoryById(requestModel)).then((response) => {
            if (response.apiStatus) {
                let mailList;
                let varDumpID;
                let varConversationId;
                let varSubject;
                this.communicationList = response.apiData;
                this.showReplyButton = (this.communicationList.length>0);
                this.communicationList.forEach(function (mail) {
                    mailList = (mailList == undefined)? mail.toEmailAddress : (mailList + ", " + mail.toEmailAddress.replace(",", ", "));
                    varDumpID = mail.dumpId;
                    varConversationId = mail.conversationId;
                    varSubject = mail.subject;
                });
                this.emailReceipients = mailList;
                this.dumpId = varDumpID;
                this.emailSubject = varSubject;
                this.conversationId = varConversationId;
                this.sendEmailForm.get("toEmailId").setValue(this.emailReceipients);
                this.sendEmailForm.get("emailSubject").setValue(this.emailSubject);
            }
        });
    }

    get sendEmailFormControls() {
        return this.sendEmailForm.controls;
    }

    openSendEmail(){
        this.showSendEmail = true;
    }

    async sendVobEmail() {
        if (this.sendEmailForm.invalid) {
            return;
        };

        let requestModel = {
            dumpId: this.dumpId,
            conversationId: this.conversationId,
            emailSentTo: this.sendEmailFormControls.toEmailId.value,
            emailSubject: this.sendEmailFormControls.emailSubject.value,
            emailBody: this.sendEmailFormControls.sendEmailContent.value,
        }
        await lastValueFrom(this._vobService.addVobMailSentData(requestModel)).then((response) => {            
            if (response.apiStatus) {
                this.sendEmailForm.get("toEmailId").setValue(this.emailReceipients);
                this.sendEmailForm.get("emailSubject").setValue('');
                this.sendEmailForm.get("sendEmailContent").setValue('');
                this._messageService.displaySuccessMessage("Email successfully sent.");
                this.showSendEmail = false;
            } else {
                this.sendEmailForm.get("toEmailId").setValue(this.emailReceipients);
                this.sendEmailForm.get("emailSubject").setValue('');
                this.sendEmailForm.get("sendEmailContent").setValue('');
            }
        });
    }
    //#endregion
}