import { Component, ElementRef, Inject, OnInit, ViewChild, ViewChildren } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControlName } from '@angular/forms';
import { CoreHelperService } from 'src/app/providers/core-helper/core-helper.service';
import { GenericValidator } from 'src/app/shared/CommonValidation/generic-validator';
import { taskManagementMessages } from 'src/app/shared/CommonValidation/form-validators-messages';
import { HeaderService } from './header.service';
import { documentsService } from 'src/app/modules/auth/services/document/document.services';
import { messagesService } from 'src/app/modules/auth/messages/messages.services';
import { debounceTime, fromEvent, lastValueFrom, merge, Observable } from 'rxjs';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';
import { StorageService } from '../../storage.service';
declare var $: any;
import * as moment from 'moment';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import * as _ from 'lodash';
import { Modules } from 'src/app/providers/core-role-access-service';
import { NavigationEnd, Router } from '@angular/router';
import { AutoCompleteComponent, DropDownFilterSettings } from '@progress/kendo-angular-dropdowns';
import { GridComponent } from '@progress/kendo-angular-grid';
import { DropdownListRequestModel, DropdownType } from 'src/app/modules/auth/administration/administration.classes ';
import { AdministrationService } from 'src/app/modules/auth/administration/administration.service';
import { UserManagementService } from 'src/app/modules/auth/administration/user-management/user-management.service';
import { UserStatus } from 'src/app/common/enum/user-domain';
import { CommonNotificationService } from 'src/app/common/service/common-notification.service';
import { MartkUserNotificationMsg } from 'src/app/common/enum/notification-options.enum';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})

export class HeaderComponent implements OnInit {
  @ViewChild('autocomplete') autocomplete: AutoCompleteComponent;
  @ViewChild('autocomplete1') autocomplete1: AutoCompleteComponent;
  @ViewChild('taskListData') taskDataList!: ElementRef;
  @ViewChild(GridComponent) taskGridList: GridComponent;
  @ViewChildren(FormControlName, { read: ElementRef })

  public markNotification = MartkUserNotificationMsg;
  userStatus = UserStatus;
  isTask: boolean = true;
  claims!: { name: string; value: unknown }[];
  userName: string = "";
  facilityName: string = "";
  isSearchOpen = false;
  isPatientSearchOpen = false;
  @ViewChild('myInput')
  inputEl!: ElementRef;
  location: Location;
  headerPageTitle: string | null = "";
  profileImageUrl: any;
  profileName: any;

  // Modal Start
  disabled: boolean = false;
  public currentDate = new Date();
  selectTaskType: any[] = [];
  selectFacility: any[] = [];
  selectCaseId: any[] = [];
  selectUser: any[] = [];
  selectStatus: any[] = [];
  formInputElements!: ElementRef[];
  public taskManagementForm: FormGroup;
  taskListData: any[] = [];
  taskViewList: any[] = [];
  datasaved = false;
  tillNow: string = "";
  public todate = new Date();
  public searchName: any[] = [];
  public patientSearchName: any[] = [];
  pipe = new DatePipe('en-US');
  todayWithPipe = null;
  //this.todayWithPipe = this.pipe.transform(Date.now(), 'dd/MM/yyyy');
  displayMessage: { [key: string]: string } = {};
  taskManagementTitle = 'Add Task';
  public submitted = false;
  private genericValidator: GenericValidator;
  fileupload!: File;
  fileFormData: FormData = new FormData();
  public taskId: number = 0;
  public RequiredFile: boolean = false;
  public addTask: boolean = false;
  public info: any;
  notifications: any[] = [];
  notificationUnreadCount: number = 0;
  notificationCount: number = 0;
  showUnreadMessagesPopup: boolean = false;
  showLogoutConfirmationPopup: boolean = false;
  public events: string[] = [];


  showSaveConfirmation:boolean = false;

  constructor(
      public _coreHelper: CoreHelperService,
      private router: Router,
      location: Location,
      public storageService: StorageService,
      public _headerService: HeaderService,
      private fb: FormBuilder,
      public _serviceDocument: documentsService,
      public _messageService: messagesService,
      private _administrationService: AdministrationService,
      public _userService: UserManagementService,
      @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
      private _commonNotificationService: CommonNotificationService,
  ) {
    this.location = location;
    this.profileImageUrl = _coreHelper.getLoggedInUserImagePath()
    this.genericValidator = new GenericValidator(taskManagementMessages);
    this.taskManagementForm = this.fb.group({
      TaskId: [0],
      taskDate: [new Date()],
      taskTypeId: ['', [Validators.required]],
      caseId: ['', [Validators.required]],
      facilityId: ['', [Validators.required]],
      assignedTo: ['', [Validators.required]],
      roleId: [0],
      dueDate: ['', [Validators.required]],
      taskSubject: ['', [Validators.required, Validators.maxLength(50)]],
      details: ['', [Validators.required, Validators.maxLength(100)]],
      statusId: ['', [Validators.required]],
      notifyMe: [0],
      taskTimeLine: [''],
      filePath: [0],
      isArchive: [0],
      CreatedBy: [this._coreHelper.getUserId()],
      SpMode: [0, [Validators.required]],
    })
    this.searchName = this.searchName.slice();
    this.searchName = Object.values(Modules);
    this._coreHelper.selectedUserStatus = this._coreHelper.getUserStatusId();
    router.events.subscribe((val) => {
      if (val instanceof NavigationEnd) {
        //this.getFacilityMessageCount();
        this.getNotificationCount();
      }
    });
  }

  ngOnInit() {
    this.userName = this._coreHelper.getProfileName();
    this.facilityName = this._coreHelper.getFacilityName();
    if (this.userName != undefined && this.userName != null) {
      this._coreHelper.callHeader.subscribe(
        data => {
          //this.getFacilityMessageCount();
          this.getNotificationCount();
        }
      );
    };
    // Below is alternative of Push Notification.
    // setInterval(() => this.getNotificationCount(), 1000 * 60);
  }

  ngAfterViewInit() {
    this._coreHelper.headerTitle.subscribe((data) => {
      this.headerPageTitle = data;
    })
    const controlBlurs: Observable<any>[] = this.formInputElements.map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur')
    );

    // Merge the blur event observable with the valueChanges observable
    merge(this.taskManagementForm.valueChanges, ...controlBlurs)
      .pipe(debounceTime(500))
      .subscribe(() => {
        this.displayMessage = this.genericValidator.processMessages(
          this.taskManagementForm,
          this.submitted
        );
      });
  }

  isFacilityUser() {
    let userDomain = this._coreHelper.getUserDomain();
    return (userDomain === 1);
  }

  isVOBDepartment() {
    return (this._coreHelper.getOpsDepartmentId() === 5);
  }

  public callCleartasklist() {
    let sender = this.taskGridList;
    sender.filter.filters = [];
    this.getOwnTaskManagementList();
  }

  public focus(): void {
    $("#autocomplete").kendoAutoComplete();
    var autocomplete = $("#autocomplete").data("kendoAutoComplete");
    autocomplete.focus();
  }

  private log(event: string, arg?: any): void {
    this.events.unshift(`${event} ${arg || ""}`);
  }

  async getPatientSearch() {
    this.patientSearchName = [];
    await lastValueFrom(this._headerService.getPatientList()).then((response) => {
      if (response.apiStatus) {
        this.patientSearchName = response.apiData;
      }
    });
  }

  checkIsLinkExists(value: any) {
    this.autocomplete.reset();
    this.toggleSearch();
    switch (value) {
      case Modules.FacilityMaster:
        this.router.navigate(['/auth/resource/facility/facility-master']);
        break;

      case Modules.CarrierMaster:
        this.router.navigate(['/auth/resource/facility/carriers-master']);
        break;

      case Modules.PatientMaster:
        this.router.navigate(['/auth/resource/facility/patients']);
        break;

      case Modules.DocumentVault:
        this.router.navigate(['/auth/services/document']);
        break;

      case Modules.Census:
        this.router.navigate(['/auth/services/census']);
        break;

      case Modules.VOB:
        this.router.navigate(['/auth/services/vob']);
        break;

      case Modules.Authorisation:
        this.router.navigate(['/auth/services/authorization']);
        break;

      case Modules.Billing:
        this.router.navigate(['/auth/services/billing']);
        break;

      case Modules.RCM:
        this.router.navigate(['/auth/services/collection']);
        break;

      case Modules.Payments:
        this.router.navigate(['/auth/services/payment']);
        break;

      case Modules.TPN:
        this.router.navigate(['/auth/services/tpn']);
        break;

      case Modules.PaymentSummary:
        this.router.navigate(['/auth/report/payment-summary']);
        break;
      case Modules.DailyProductionReport:
        this.router.navigate(['/auth/report/daily-production-reporty']);
        break;
      case Modules.QualityReport:
        this.router.navigate(['/auth/report/quality-report']);
        break;
      case Modules.TATReport:
        this.router.navigate(['/auth/report/tat-report']);
        break;
      case Modules.CallTimeReport:
        this.router.navigate(['/auth/report/call-time-report']);
        break;
      case Modules.SpecialistBreakReport:
        this.router.navigate(['/auth/report/specialist-break-report']);
        break;

      case Modules.CallRecordAnalytics:
        this.router.navigate(['/auth/report/call-record-analytics']);
        break;
      case Modules.UserManagement:
        this.router.navigate(['/auth/administration/user-management']);
        break;

      case Modules.Announcement:
        this.router.navigate(['/auth/administration/announcement']);
        break;

      case Modules.DiagnosticCode:
        this.router.navigate(['/auth/administration/diagnostic']);
        break;

      case Modules.DocumentWorkflow: //doubt
        this.router.navigate(['/auth/administration/document-workflow']);
        break;

      case Modules.StatusFlowMaster:
        this.router.navigate(['/auth/administration/status-workflow']);
        break;

      case Modules.Modulemaster:
        this.router.navigate(['/auth/administration/module']);
        break;

      case Modules.FacilityDocument:
        this.router.navigate(['/auth/administration/facility-documents']);
        break;

      case Modules.ServiceMaster:
        this.router.navigate(['/auth/administration/service']);
        break;

      case Modules.ServiceTypeMaster:
        this.router.navigate(['/auth/administration/service-type']);
        break;

      case Modules.FundMaster:
        this.router.navigate(['/auth/administration/fund-master']);
        break;

      case Modules.AccreditationMaster:
        this.router.navigate(['/auth/administration/accreditation-master']);
        break;

      case Modules.FacilityIdentifier:
        this.router.navigate(['/auth/administration/taxonomy']);
        break;

      case Modules.MessageConfiguration:
        this.router.navigate(['/auth/administration/message-configuration']);
        break;

      case Modules.TPNMaster:
        this.router.navigate(['/auth/administration/tpn-master']);
        break;

      case Modules.PaymentTask:
        this.router.navigate(['/auth/administration/payment-task']);
        break;

      case Modules.UploadTypeMaster:
        this.router.navigate(['/auth/administration/upload-type-master']);
        break;

      case Modules.CollectionGoal:
        this.router.navigate(['/auth/administration/collection-goal']);
        break;
    }

  }

  checkIsPatientExists(value: any) {
    this.autocomplete1.reset();
    let patientId = this.patientSearchName.find(x => x.fullName.toLowerCase() == value.toLowerCase())?.patientMasterId;
    if (!this._coreHelper.isNullOrUndefined(patientId)) {
      this.router.navigate(['/auth/patient-case-view'], { queryParams: { patientId: patientId } })
    }
    this.isPatientSearchOpen = false;
    this.isSearchOpen = false;
  }

  public filterSettings: DropDownFilterSettings = {
    caseSensitive: false,
    operator: 'contains',
  };


  setTitle(title: string) {
    localStorage.setItem('title', title)
  }

  getTitle() {
    var titlee = this.location.prepareExternalUrl(this.location.path());
    if (titlee.charAt(0) === '#') {
      titlee = titlee.slice(1);
    }
    if (titlee.charAt(0) === '?') {
      titlee = titlee.slice(1);
    }
    for (var item = 0; item < this.listTitles.length; item++) {
      if (this.listTitles[item].path === titlee) {
        return this.listTitles[item].title;
      }
    }
    if (titlee.includes('view-facility')) {
      return 'Facility Info';
    } else if (titlee.includes('facility-info')) {
      return 'Facility Info';
    } else if (titlee.includes('add-facility')) {
      return 'Add Facility';
    } else if (titlee.includes('edit-facility')) {
      return 'Edit Facility';
    } else if (titlee.includes('view-facility')) {
      return 'View Facility';
    } else if (titlee.includes('add-carriers')) {
      return 'Add Carrier';
    } else if (titlee.includes('edit-carriers')) {
      return 'Edit Carrier';
    } else if (titlee.includes('view-carriers')) {
      return 'View Carrier';
    } else if (titlee.includes('add-patients')) {
      return 'Add Patient';
    } else if (titlee.includes('edit-patients')) {
      return 'Edit Patient';
    } else if (titlee.includes('view-patients')) {
      return 'View Patient';
    } else if (titlee.includes('view-vob') || titlee.includes('add-vob') || titlee.includes('edit-vob')) {
      return 'VOB';
    } else if (titlee.includes('view-authorization') || titlee.includes('add-authorization') || titlee.includes('edit-authorization')) {
      return 'Authorization';
    } else if (titlee.includes('view-tpn') || titlee.includes('add-tpn') || titlee.includes('edit-tpn')) {
      return 'TPN';
    } else if (titlee.includes('view-single-case-agreements')) {
      return 'View Single Case';
    } else if (titlee.includes('view-billing')) {
      return 'Billing';
    } else if (titlee.includes('view-collection')) {
      return 'RCM'
    }
    else if (titlee.includes('view-medical-record')) {
      return 'Medical Record'
    }
    else if (titlee.includes('tab=taskmanagement')) {
      return 'Task Management'
    }
    else if (titlee.includes('patient-case-view')) {
      return 'Patient Case View'
    }
    else if (titlee.includes('invoicing')) {
      return 'Invoicing'
    }
    else if (titlee.includes('Permission')) {
      return 'Permission'
    }
    else if (titlee.includes('daily-production-report')) {
      return 'Daily Production Report'
    }
    else if (titlee.includes('quality-report')) {
      return 'Quality Report'
    }
    else if (titlee.includes('tat-report')) {
      return 'TAT Report'
    }
    else if (titlee.includes('call-time-report')) {
      return 'Call Time Report'
    }
    else if (titlee.includes('specialist-break-report')) {
      return 'Specialist Break Report'
    }
    else
      return 'Dashboard';
  }

  listTitles = [
    // Dashboard
    { path: '/auth/dashboard', title: 'Dashboard' },
    { path: '/auth/executive-dashboard', title: 'Executive Dashboard' },
    { path: '/auth/facility-dashboard', title: 'Dashboard' },
    // Resource
    { path: '/auth/resource/facility/facility-grouping', title: 'Facility Group' },
    { path: '/auth/resource/facility/facility-master', title: 'Facility Master' },
    { path: '/auth/resource/facility/carriers-master', title: 'Carrier Master' },
    { path: '/auth/resource/facility/patients', title: 'Patient Master' },
    // Services
    { path: '/auth/services/credentialing', title: 'Credentialing' },
    { path: '/auth/services/census', title: 'Census' },
    { path: '/auth/services/document', title: 'Documents' },
    { path: '/auth/services/vob', title: 'VOB' },
    { path: '/auth/services/authorization', title: 'Authorization' },
    { path: '/auth/services/billing', title: 'Billing' },
    { path: '/auth/services/collection', title: 'RCM' },
    { path: '/auth/services/medical-record', title: 'Medical Records' },
    { path: '/auth/services/payment', title: 'Payments' },
    { path: '/auth/services/tpn', title: 'TPN' },
    { path: '/auth/services/refund', title: 'Refund Request' },
    // Reports
    { path: '/auth/report/claims-billed', title: 'Claims Billed' },
    { path: '/auth/report/payment-summary', title: 'Payment Summary' },
    { path: '/auth/report/patient-statement', title: 'Patient Statement' },
    { path: '/auth/report/call-record-analytics', title: 'Call Record Analytics' },
    { path: '/auth/report/payment-detail', title: 'Payment Detail' },
    { path: '/auth/report/aging', title: 'Current Payer Breakdown' },
    { path: '/auth/report/aging-summary', title: 'AR Aging Summary' },
    { path: '/auth/report/ur-daily', title: 'UR Daily Authorizations' },
    { path: '/auth/report/ur-review', title: 'UR Upcoming Review' },
    { path: '/auth/report/activity-summary', title: 'Activity Summary By Month' },
    { path: '/auth/report/payer-priority', title: 'Total Balance by Payer Priority' },
    { path: '/auth/report/patient-balance', title: 'Patient Balance Report' },
    { path: '/auth/report/payer-type', title: 'Payer Type Breakdown' },
    // Administration
    { path: '/auth/administration/collection-admin', title: 'RCM For Admin' },
    { path: '/auth/administration/collection-goal', title: 'Collection Goal' },
    { path: '/auth/administration/permission', title: 'Permission' },
    { path: '/auth/administration/user-management', title: 'User Management' },
    { path: '/auth/administration/announcement', title: 'Announcement' },
    { path: '/auth/administration/diagnostic', title: 'Diagnostic Code' },
    { path: '/auth/administration/document-workflow', title: 'Document Type' },
    { path: '/auth/administration/status-workflow', title: 'Status Workflow' },
    { path: '/auth/administration/module', title: 'Module Master' },
    { path: '/auth/administration/facility-documents', title: 'Facility Documents' },
    { path: '/auth/administration/service', title: 'Service Master' },
    { path: '/auth/administration/service-type', title: 'Service Type Master' },
    { path: '/auth/administration/taxonomy', title: 'Facility Identifier' },
    { path: '/auth/administration/message-configuration', title: 'Message Configuration' },
    { path: '/auth/administration/tpn-master', title: 'Third Party Master' },
    { path: '/auth/administration/tenure-goals', title: 'Tenure Goals' },
    { path: '/auth/administration/statistics', title: 'Statistics' },
    { path: '/auth/administration/payment-task', title: 'Payment Task' },
    { path: '/auth/administration/upload-type-master', title: 'Upload File' },
    // Profile
    { path: '/auth/profile', title: 'Profile' },
    { path: '/auth/profile/change-password', title: 'Change Password' },
    { path: '/auth/setting', title: 'Notification Settings' },
    { path: '/auth/patient-case-view', title: 'Patient Case View' },
    { path: '/auth/messages', title: 'Messages' }
  ]

  public taskDetails = false;

  public taskDetailsClose() {
    this.taskDetails = false;
  }
  public taskDetailsOpen(taskId: number) {
    this.taskDetails = true;
    this.taskId = taskId;
    this.info = this.taskViewList.find((t: { taskId: any; }) => t.taskId == taskId)
    this.info.taskSubject;
    this.info.details;
  }

  //start add task related changes merge
  reset() {
    this.taskManagementForm.controls['taskTypeId'].reset('');
    this.taskManagementForm.controls['caseId'].reset('');
    this.taskManagementForm.controls['facilityId'].reset('');
    this.taskManagementForm.controls['assignedTo'].reset('');
    this.taskManagementForm.controls['roleId'].reset('');
    this.taskManagementForm.controls['dueDate'].reset('');
    this.taskManagementForm.controls['taskSubject'].reset('');
    this.taskManagementForm.controls['details'].reset('');
    this.taskManagementForm.controls['statusId'].reset('');
    this.taskManagementForm.controls['notifyMe'].reset('');
    this.taskManagementForm.controls['taskTimeLine'].reset('');
    this.taskManagementForm.controls['filePath'].reset('');
  }

  public taskModelOpen() {
    this.addTask = true;
    this.getAssignUser();
    this.getTaskType();
    this.getFacility();
    this.getStatusName();
    this.taskManagementForm.controls['taskDate'].patchValue(new Date());
    this.reset();
  }

  public taskModelClose() {
    this.addTask = false;
    this.submitted = false;
    this.reset();
  }

  async getTaskType() {
    this.selectTaskType = [];
    let taskType: any = { 'taskTypeId': 0 };
    await lastValueFrom(this._headerService.gettaskTypeList(taskType)).then((response) => {
      if (response.apiStatus) {
        this.selectTaskType = response.apiData.taskTypeList;
      }
    });
  }

  async getFacility() {
    this.selectFacility = [];
    let dropdownListRequestModel: DropdownListRequestModel = {
      type: DropdownType.Facility,
      domain: 0 // Domain Base Data
    };
    await lastValueFrom(this._administrationService.getDataForDropdownList(dropdownListRequestModel)).then((response) => {
      if (response.apiStatus) {
        this.selectFacility = response.apiData;
      }
    });
  }

  async getAssignUser() {
    await lastValueFrom(this._headerService.getassignUserList(0)).then((response) => {
      if (response.apiStatus) {
        this.selectUser = response.apiData;
        this.selectUser.forEach(element => {
          element.fullName = element.firstName + " " + element.lastName;
        });
      }
    });
  }

  public disabledDates = (date: Date): boolean => {
    date.setDate(date.getDate() + 1);
    return date < this.todate;
  }

  async getOwnTaskManagementList() {
    let param = { userId: this._coreHelper.getUserId() };
    await lastValueFrom(this._serviceDocument.gettaskManagementList(param)).then((response) => {
      if (response.apiStatus) {
        this.taskListData = response.apiData;
        this.taskViewList = this.taskListData;
        this.taskViewList.forEach(res => {
          res.tillNow = moment(res.createdDate).fromNow();
        })
      }
    });
  }

  async addupdateTaskInfo() {
    if (this.taskManagementForm.invalid) {
      this.submitted = true;
      this.displayMessage = this.genericValidator.processMessages(this.taskManagementForm, this.submitted);
      return;
    }
    let data = this.taskManagementForm.value;
    this.fileFormData.set("taskId", data.taskId);
    this.fileFormData.set("taskDate", data.taskDate.toLocaleString());
    this.fileFormData.set("taskTypeId", data.taskTypeId);
    this.fileFormData.set("caseId", data.caseId);
    this.fileFormData.set("facilityId", data.facilityId);
    this.fileFormData.set("assignedTo", data.assignedTo);
    this.fileFormData.set("dueDate", data.dueDate.toLocaleString());
    this.fileFormData.set("taskSubject", data.taskSubject);
    this.fileFormData.set("details", data.details);
    this.fileFormData.set("statusId", data.statusId);
    this.fileFormData.set("notifyMe", "0");
    this.fileFormData.set("filePath", data.filePath[0]);
    this.fileFormData.set("taskTimeLine", data.taskTimeLine);
    this.fileFormData.set("roleId", data.roleId);
    this.fileFormData.set("createdBy", this._coreHelper.getUserId().toString(),); //its 1 is static. But lator on it should be dynamic
    this.fileFormData.set("isArchive", data.isArchive);
    this.fileFormData.set("spMode", data.spMode);

    if (this.taskId > 0) {
      this.fileFormData.set('taskId', this.taskId.toString());
      this.fileFormData.set('spMode', "1");
    }
    else {
      this.fileFormData.set('taskId', "0");
      this.fileFormData.set('spMode', "0");
    }
    await lastValueFrom(this._headerService.AddUpdateTaskMaster(this.fileFormData)).then((response) => {
      if (response.apiStatus) {
        this.addTask = false;
        this.submitted = false;
      }
    });
  }

  /* Okta Integration */
  async logout() {
    this.showLogoutConfirmationPopup = true;
  }

  async doLogout(userStatus: number) {
    let requestModel = {
      userId: this._coreHelper.getUserId(),
      isLogin: userStatus === UserStatus.Offline ? 0 : 1,
      spMode: 1,
      userStatusId: userStatus
    }
    if (requestModel.userId != undefined || requestModel.userId != null) {
      await lastValueFrom(this._userService.addUpdateSignedUser(requestModel)).then((response) => {
        this._coreHelper.setUserStatusId(response.apiData);
        if (userStatus === UserStatus.Offline)
          localStorage.clear();
      });
      if (userStatus === UserStatus.Offline)
        await this.oktaAuth.signOut();
    };
  }

  onLogoutClose() {
    this.showLogoutConfirmationPopup = false;
  }

  async getStatusName() {
    this.selectStatus = [];
    let statusId: any = { 'statusId': 0 }
    await lastValueFrom(this._headerService.getstatusList(statusId)).then((response) => {
      if (response.apiStatus) {
        this.selectStatus = response.apiData.taskStatusList;
      }
    });
  }

  getdefultimage() {
    this.profileImageUrl = "assets/img/user.png";
  }

  public gridData: any[] = [
    {
      SrID: 1,
      Comments: "Request Pass on for review",
      updatedby: "John Melon",
      Updatedon: "14/04/2022",
    },
    {
      SrID: 2,
      Comments: "Request Pass on for review",
      updatedby: "John Melon",
      Updatedon: "14/04/2022",
    },
    {
      SrID: 3,
      Comments: "Request Pass on for review",
      updatedby: "John Melon",
      Updatedon: "14/04/2022",
    }
  ];
  //end add task related changes merge

  //#region  Notification
  async getNotificationByUser() {
    await lastValueFrom(this._headerService.getNotificationByUserId()).then((response) => {
      if (response.apiStatus) {
        this.notifications = response.apiData;
        this.notifications.forEach(res => {
          res.tillNow = moment(res.notifyDate).fromNow();
        })
      }
    });
  }

  notificationURL: string = "";

  async markNotificationAsReaded(item: any, markAll: boolean = false) {
    if (item != null && item.isReaded == 1) {
      this.closeNotificationSlider();
      return;
    }
    let requestModel = {
      notificationId: (item == null) ? 0 : item.notificationId,
      doDelete: MartkUserNotificationMsg.MarkDeleteNone,
      doReaded: (markAll) ? MartkUserNotificationMsg.MarkAllReaded : MartkUserNotificationMsg.MarkReadedById, 
      byUserId: 0,
    }
    await lastValueFrom(this._headerService.markNotificationAsReaded(requestModel)).then((response) => {
      if (response.apiStatus) {
        this.notificationURL = item.navigationUrl;
        if(markAll){
          this._commonNotificationService.displaySuccessMessage('ALL Messages are marked as readed.');
        }
        this.closeNotificationSlider();
      }else {
        this.notificationURL = "";
      }
    });
  }

  async deleteNotificationMessage(item: any, deleteAll: boolean = false) {
    let requestModel = {
      notificationId: (item == null) ? 0 : item.notificationId,
      doDelete: (deleteAll) ? MartkUserNotificationMsg.MarkAllDeleted : MartkUserNotificationMsg.MarkDeleteById,
      doReaded: MartkUserNotificationMsg.MarkReadedNone,
      byUserId: 0,
    }
    await lastValueFrom(this._headerService.markNotificationAsReaded(requestModel)).then((response) => {
      if (response.apiStatus) {
        if(deleteAll){
          this._commonNotificationService.displaySuccessMessage('ALL Messages are deleted.');
        }else{
          this._commonNotificationService.displaySuccessMessage('Message deleted.');
        }        
        $('#Notifications-modal').modal('hide');
        this.getNotificationCount();
      }
    });
  }

  closeNotificationSlider() {
    this.getNotificationCount();
    this.showSaveConfirmation = true;    
  }

  async getNotificationCount() {
    await lastValueFrom(this._headerService.getNotificationCountByUserId()).then((response) => {
      if (response.apiStatus) {
        let responseData = response.apiData[0];
        this.notificationUnreadCount = responseData.unreadCount;
        this.notificationCount = responseData.totalCount;
      }
    });
  }

  //Facility Messages BELOW Not the User Notification
  async getFacilityMessageCount() {
    await lastValueFrom(this._messageService.getMessageCount()).then((response) => {
      if (response.apiStatus) {
        this.notificationUnreadCount = 0;
        this.notificationCount = 0;
        response.apiData.forEach((x: { unread: number; total: number }) => { this.notificationUnreadCount += x.unread; this.notificationCount += x.total });
        if (localStorage.getItem('UnreadMessagePrompt') === null && this.notificationUnreadCount > 0 && this._coreHelper.getLoggedInUserDomain() !== 0) {
          localStorage.setItem('UnreadMessagePrompt', this._coreHelper.getUtcDateOnly(new Date));
          this.showUnreadMessagesPopup = true;
        }
      }
    });
  }

  unreadMessagesPopupClose() {
    this.showUnreadMessagesPopup = false;
  }

  unreadMessagesPopupClickLink() {
    this.unreadMessagesPopupClose();
    this.router.navigate(['/auth/messages']);
  }
  //#endregion


  // Header Search Box
  toggleSearch() {
    this.isSearchOpen = !this.isSearchOpen;
    this.isPatientSearchOpen = false;
  }

  patientSearch() {
    this.isPatientSearchOpen = !this.isPatientSearchOpen;
    this.isSearchOpen = false;
    if (this.isPatientSearchOpen)
      this.getPatientSearch();
  }

  onNoOptionSelected() {
    this.showSaveConfirmation = false;
    $('#Notifications-modal').modal('hide');
  }

  onYesOptionsSelected() {
    this.showSaveConfirmation = false;
    $('#Notifications-modal').modal('hide');
    if (this.isFacilityUser()) {
      this.router.navigate(['/auth/facility-dashboard']);
    } else {
      this.router.navigate(['/auth/services/vob']);
    }
  }
}
