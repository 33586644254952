
import { Component, OnInit, ViewChild } from '@angular/core';
import { LegendLabelsContentArgs } from "@progress/kendo-angular-charts";
import { State, AggregateDescriptor, SortDescriptor, CompositeFilterDescriptor, orderBy, filterBy } from "@progress/kendo-data-query";
import { CoreHelperService } from 'src/app/providers/core-helper/core-helper.service';
import { formatNumber } from '@progress/kendo-angular-intl';
import { lastValueFrom } from 'rxjs';
import { ReportService } from 'src/app/modules/auth/report/report.service';
import { Router } from '@angular/router';
import { CommonVobReportService } from './common-vob-report.service';
import { CommonPageService } from 'src/app/shared/utility/common-page.service';
import { GridComponent, PageChangeEvent } from '@progress/kendo-angular-grid';
import { UserManagementService } from 'src/app/modules/auth/administration/user-management/user-management.service';
import { ModuleTabIds } from '../../enum/module-tab-master';

@Component({
  selector: 'app-vob-report',
  templateUrl: './common-vob-report.component.html',
})
export class CommonVobReportComponent implements OnInit {
  range = { start: null, end: null };
  dateValue: Date = new Date();
  reportName: string;
  dailyProductionReportGridData: any[] = [];
  qualityReportGridData: any[] = [];
  callTimeReportGridData: any[] = [];
  tatReportGridData: any[] = [];
  specialistBreakReportGridData: any[] = [];
  showCalender = false;
  selectFacilityList: any[] = [];
  vobSpecialistList: any[] = [];
  selectedFacility: any[] = [];
  selectedVobSpecialist: any[] = [];
  isCheckedFacility = true;
  isCheckedSpecialist = true;
  isSelected: boolean = false;
  selectedMonthss = "This Month";
  permissionList: any = [];
  isDailyProductionReport: boolean = false;
  isQualityReport: boolean = false;
  isCallTimeReport: boolean = false;
  isTATReport: boolean = false;
  isSpecialistBreakReport: boolean = false;
  @ViewChild("dailyProductionReportGrid") public dailyProductionReportGrid: GridComponent;
  @ViewChild("qualityReportGrid") public qualityReportGrid: GridComponent;
  @ViewChild("callTimeReportGrid") public callTimeReportGrid: GridComponent;
  @ViewChild("tatReportGrid") public tatReportGrid: GridComponent;
  @ViewChild("specialistBreakReportGrid") public specialistBreakReportGrid: GridComponent;
  constructor(
    private _router: Router,
    public coreHelperService: CoreHelperService,
    public _reportService: ReportService,
    public _commonPageService: CommonPageService,
    private _userManagementService: UserManagementService,
    public _commonVobReportService: CommonVobReportService) {
    this.loadPermission();
    this.range.start = new Date();
    this.range.start.setDate(this.range.start.getDate() - 1);
    this.range.end = new Date();
  }

  //#region Permission Changes
  isDailyProductionReportExportAllowed: boolean = false;
  isQualityReportExportAllowed: boolean = false;
  isCallTimeReportExportAllowed: boolean = false;
  isTATReportExportAllowed: boolean = false;
  isSpecialistBreakReportExportAllowed: boolean = false;

  loadPermission() {
    this.permissionList = this.coreHelperService.getVOBReportTabsPermission();
    if (this.permissionList != undefined && this.permissionList.length > 0) {
      let reports = this.permissionList.filter(r => r.tabMasterId == ModuleTabIds.Daily_Production_Report && r.moduleElementTransId == 0 && r.isEdit > 0);
      this.isDailyProductionReportExportAllowed = (reports != undefined && reports != null && reports.length > 0);
    }
    if (this.permissionList != undefined && this.permissionList.length > 0) {
      let reports = this.permissionList.filter(r => r.tabMasterId == ModuleTabIds.Quality_Report && r.moduleElementTransId == 0 && r.isEdit > 0);
      this.isQualityReportExportAllowed = (reports != undefined && reports != null && reports.length > 0);
    }
    if (this.permissionList != undefined && this.permissionList.length > 0) {
      let reports = this.permissionList.filter(r => r.tabMasterId == ModuleTabIds.Call_Time_Report && r.moduleElementTransId == 0 && r.isEdit > 0);
      this.isCallTimeReportExportAllowed = (reports != undefined && reports != null && reports.length > 0);
    }
    if (this.permissionList != undefined && this.permissionList.length > 0) {
      let reports = this.permissionList.filter(r => r.tabMasterId == ModuleTabIds.TAT_Report && r.moduleElementTransId == 0 && r.isEdit > 0);
      this.isTATReportExportAllowed = (reports != undefined && reports != null && reports.length > 0);
    }
    if (this.permissionList != undefined && this.permissionList.length > 0) {
      let reports = this.permissionList.filter(r => r.tabMasterId == ModuleTabIds.Specialist_Break_Report && r.moduleElementTransId == 0 && r.isEdit > 0);
      this.isSpecialistBreakReportExportAllowed = (reports != undefined && reports != null && reports.length > 0);
    }
  }
  //#endregion

  ngOnInit(): void {
    let currentUrl = this._router.url;
    if (currentUrl.includes('daily-production-report')) {
      this.reportName = "Daily Production Report";
      this.isDailyProductionReport = true;
    } else if (currentUrl.includes('quality-report')) {
      this.reportName = "Quality Report";
      this.isQualityReport = true;
    } else if (currentUrl.includes('call-time-report')) {
      this.reportName = "Call Time Report";
      this.isCallTimeReport = true;
    } else if (currentUrl.includes('tat-report')) {
      this.reportName = "TAT Report";
      this.isTATReport = true;
    } else if (currentUrl.includes('specialist-break-report')) {
      this.reportName = "Specialist Break Report";
      this.isSpecialistBreakReport = true;
    }
    this._commonPageService.clearGroupPaginationListDataCommon();
    this._commonPageService.clearCommonState();
    this.range.start = this.coreHelperService.getMonthStartDate().toISOString();
    this.range.end = this.coreHelperService.getMonthEndDate().toISOString();
    this.getFacilityForFilter();
  }

  // Page Title
  public aggregates: AggregateDescriptor[] = [
    { field: "totalPaid", aggregate: "sum" },
    { field: "chargeAmount", aggregate: "sum" },
  ];
  public get isIndetFacility() {
    return this.selectedFacility.length !== 0 && this.selectedFacility.length !== this.selectFacilityList.length;
  }
  public get isIndetSpecialist() {
    return this.selectedVobSpecialist.length !== 0 && this.selectedVobSpecialist.length !== this.vobSpecialistList.length;
  }
  public get toggleAllTextFacility() {
    return this.isCheckedFacility ? 'Deselect All' : 'Select All';
  }
  public get toggleAllTextSpecialist() {
    return this.isCheckedSpecialist ? 'Deselect All' : 'Select All';
  }
  public isFacilityItemSelected(item) {
    return this.selectedFacility.some((x) => x.facilityId === item.facilityId);
  }
  public isSpecialistItemSelected(item) {
    return this.selectedFacility.some((x) => x.facilityId === item.facilityId);
  }
  public onClickFacility() {
    this.isCheckedFacility = !this.isCheckedFacility;
    this.selectedFacility = this.isCheckedFacility ? this.selectFacilityList.slice() : [];
  }
  public onClickSpecialist() {
    this.isCheckedSpecialist = !this.isCheckedSpecialist;
    this.selectedVobSpecialist = this.isCheckedSpecialist ? this.vobSpecialistList.slice() : [];
  }
  public onValueChangeFacility() {
    this.isCheckedFacility = this.selectedFacility.length === this.selectFacilityList.length;
  }
  public onValueChangeSpecialist() {
    this.isCheckedSpecialist = this.selectedVobSpecialist.length === this.vobSpecialistList.length;
  }

  async runVobDailyProductionReport() {
    this.isSelected = false;
    if (this.selectedFacility?.length == 0 || this.selectedVobSpecialist?.length == 0) {
      this.isSelected = true;
      return;
    }
    let facilityIds = [];
    this.selectedFacility.forEach(element => {
      facilityIds.push(element.facilityId)
    });
    let specialistIds = [];
    this.selectedVobSpecialist.forEach(element => {
      specialistIds.push(element.userId)
    });
    let requestModel = {
      facilityIds: this.coreHelperService.getLoggedInUserDomain() == 1 ? this.coreHelperService.getLoggedInUserDetail().isMultipleFacility == 0 ? this.coreHelperService.getFacilityId().toString() :
        facilityIds.toString() : facilityIds.toString(),
      specialistIds: specialistIds.toString(),
      fromServiceDate: this.coreHelperService.getUtcDate(new Date(this.range.start)),
      toServiceDate: this.coreHelperService.getUtcDate(new Date(this.range.end))
    }
    await lastValueFrom(this._commonVobReportService.getVobDailyProductionReportData(requestModel)).then((response) => {
      if (response.apiStatus) {
        this._commonPageService.clearGroupPaginationListDataCommon();
        this._commonPageService.setGroupPaginationListDataCommon(response.apiData, response.apiData.length);
        this.processData();
      }
      this.filterPopup = false;
    });
  }

  async runVobQualityReport() {
    this.isSelected = false;
    if (this.selectedFacility?.length == 0 || this.selectedVobSpecialist?.length == 0) {
      this.isSelected = true;
      return;
    }
    let facilityIds = [];
    this.selectedFacility.forEach(element => {
      facilityIds.push(element.facilityId)
    });
    let specialistIds = [];
    this.selectedVobSpecialist.forEach(element => {
      specialistIds.push(element.userId)
    });
    let requestModel = {
      facilityIds: this.coreHelperService.getLoggedInUserDomain() == 1 ? this.coreHelperService.getLoggedInUserDetail().isMultipleFacility == 0 ? this.coreHelperService.getFacilityId().toString() :
        facilityIds.toString() : facilityIds.toString(),
      specialistIds: specialistIds.toString(),
      fromServiceDate: this.coreHelperService.getUtcDate(new Date(this.range.start)),
      toServiceDate: this.coreHelperService.getUtcDate(new Date(this.range.end))
    }
    await lastValueFrom(this._commonVobReportService.getVobQualityReportData(requestModel)).then((response) => {
      if (response.apiStatus) {
        this._commonPageService.clearGroupPaginationListDataCommon();
        this._commonPageService.setGroupPaginationListDataCommon(response.apiData, response.apiData.length);
        this.processData();
      }
      this.filterPopup = false;
    });
  }

  async runVobCallTimeReport() {
    this.isSelected = false;
    if (this.selectedFacility?.length == 0 || this.selectedVobSpecialist?.length == 0) {
      this.isSelected = true;
      return;
    }
    let facilityIds = [];
    this.selectedFacility.forEach(element => {
      facilityIds.push(element.facilityId)
    });
    let specialistIds = [];
    this.selectedVobSpecialist.forEach(element => {
      specialistIds.push(element.userId)
    });
    let requestModel = {
      facilityIds: this.coreHelperService.getLoggedInUserDomain() == 1 ? this.coreHelperService.getLoggedInUserDetail().isMultipleFacility == 0 ? this.coreHelperService.getFacilityId().toString() :
        facilityIds.toString() : facilityIds.toString(),
      specialistIds: specialistIds.toString(),
      fromServiceDate: this.coreHelperService.getUtcDate(new Date(this.range.start)),
      toServiceDate: this.coreHelperService.getUtcDate(new Date(this.range.end))
    }
    await lastValueFrom(this._commonVobReportService.getVobCallTimeReportData(requestModel)).then((response) => {
      if (response.apiStatus) {
        this._commonPageService.clearGroupPaginationListDataCommon();
        this._commonPageService.setGroupPaginationListDataCommon(response.apiData, response.apiData.length);
        this.processData();
      }
      this.filterPopup = false;
    });
  }

  async runVobTATReport() {
    this.isSelected = false;
    if (this.selectedFacility?.length == 0 || this.selectedVobSpecialist?.length == 0) {
      this.isSelected = true;
      return;
    }
    let facilityIds = [];
    this.selectedFacility.forEach(element => {
      facilityIds.push(element.facilityId)
    });
    let specialistIds = [];
    this.selectedVobSpecialist.forEach(element => {
      specialistIds.push(element.userId)
    });
    let requestModel = {
      facilityIds: this.coreHelperService.getLoggedInUserDomain() == 1 ? this.coreHelperService.getLoggedInUserDetail().isMultipleFacility == 0 ? this.coreHelperService.getFacilityId().toString() :
        facilityIds.toString() : facilityIds.toString(),
      specialistIds: specialistIds.toString(),
      fromServiceDate: this.coreHelperService.getUtcDate(new Date(this.range.start)),
      toServiceDate: this.coreHelperService.getUtcDate(new Date(this.range.end))
    }
    await lastValueFrom(this._commonVobReportService.getVobTatMissing(requestModel)).then((response) => {
      if (response.apiStatus) {
        this._commonPageService.clearGroupPaginationListDataCommon();
        this._commonPageService.setGroupPaginationListDataCommon(response.apiData, response.apiData.length);
        this.processData();
      }
      this.filterPopup = false;
    });
  }

  async runVobSpecialistBreakReport() {
    this.isSelected = false;
    if (this.selectedFacility?.length == 0 || this.selectedVobSpecialist?.length == 0) {
      this.isSelected = true;
      return;
    }
    let facilityIds = [];
    this.selectedFacility.forEach(element => {
      facilityIds.push(element.facilityId)
    });
    let specialistIds = [];
    this.selectedVobSpecialist.forEach(element => {
      specialistIds.push(element.userId)
    });
    let requestModel = {
      facilityIds: this.coreHelperService.getLoggedInUserDomain() == 1 ? this.coreHelperService.getLoggedInUserDetail().isMultipleFacility == 0 ? this.coreHelperService.getFacilityId().toString() :
        facilityIds.toString() : facilityIds.toString(),
      specialistIds: specialistIds.toString(),
      fromServiceDate: this.coreHelperService.getUtcDate(new Date(this.range.start)),
      toServiceDate: this.coreHelperService.getUtcDate(new Date(this.range.end))
    }
    await lastValueFrom(this._commonVobReportService.getVobSpecialistBreak(requestModel)).then((response) => {
      if (response.apiStatus) {
        this._commonPageService.clearGroupPaginationListDataCommon();
        this._commonPageService.setGroupPaginationListDataCommon(response.apiData, response.apiData.length);
        this.processData();
      }
      this.filterPopup = false;
    });
  }

  runReport() {
    this._commonPageService.clearGroupPaginationListDataCommon();
    if (this.isDailyProductionReport) {
      this.runVobDailyProductionReport();
    } else if (this.isQualityReport) {
      this.runVobQualityReport();
    } else if (this.isCallTimeReport) {
      this.runVobCallTimeReport();
    } else if (this.isTATReport) {
      this.runVobTATReport();
    } else if (this.isSpecialistBreakReport) {
      this.runVobSpecialistBreakReport();
    }
  }

  getDateCalculation(event: any) {
    if (event == "Last Year") {
      this.showCalender = false;
      this.range.start = this.coreHelperService.getLastYearStartDate();
      this.range.end = this.coreHelperService.getLastYearEndDate();
    }
    if (event == "Last Week") {
      this.showCalender = false;
      this.range.start = this.coreHelperService.getLastWeekStartDate();
      this.range.end = this.coreHelperService.getLastWeekEndDate();
    }
    if (event == "Last Month") {
      this.showCalender = false;
      this.range.start = this.coreHelperService.getLastMonthStartDate();
      this.range.end = this.coreHelperService.getLastMonthEndDate();
    }
    if (event == "This Week") {
      this.showCalender = false;
      this.range.start = this.coreHelperService.getWeekStartDate();
      this.range.end = this.coreHelperService.getWeekEndDate();
    }
    if (event == "This Month") {
      this.showCalender = false;
      this.range.start = this.coreHelperService.getMonthStartDate();
      this.range.end = this.coreHelperService.getMonthEndDate();
    }
    if (event == "This Year") {
      this.showCalender = false;
      this.range.start = this.coreHelperService.getYearStartDate();
      this.range.end = this.coreHelperService.getYearEndDate();
    }
    if (event == "Custom") {
      this.showCalender = true;
      this.range.start = new Date();
      this.range.end = new Date();
    }
  }
  // Tabel LIST
  public state: State = {
    skip: 0,
    take: 10,
  };

  public filterlistItems: Array<string> = [
    "Custom",
    "This Week",
    "This Month",
    "Last Week",
    "Last Month",
    "This Year",
    "Last Year",
  ];

  // Modal Start
  public filterPopup = false;
  public facility = false;

  public filterClose() {
    this.filterPopup = false;
  }

  public filterOpen() {
    this.filterPopup = true;
  }

  public facilityOpen() {
    this.facility = true;
  }

  public facilityClose() {
    this.facility = false;
  }

  public carrierContent(args: LegendLabelsContentArgs): string {
    return args.dataItem.carrierValue
  }

  public dollarCount(args: LegendLabelsContentArgs): string {
    return "$" + formatNumber(args.dataItem.weekValue)
  }

  public getFacilityForFilter() {
    this.selectFacilityList = [];
    let facility: any = { 'facilityId': this.coreHelperService.getFacilityId() }
    this._reportService.getFacilityForFilter(facility).subscribe(response => {
      if (response.apiStatus) {
        this.selectFacilityList = response.apiData;
        this.selectedFacility = this.selectFacilityList;
        this.getSpecialistForFilter();
      }
    });
  }

  async getSpecialistForFilter() {
    this.vobSpecialistList = [];
    await lastValueFrom(this._userManagementService.getDepartmentwiseUser(5)).then((response) => {
      if (response.apiStatus) {
        this.vobSpecialistList = response.apiData;
        this.selectedVobSpecialist = this.vobSpecialistList;
        this.runReport();
      }
    });
  }

  //#region Sorting Grid Data
  sortChangeVOBReport(sort: SortDescriptor[]): void {
    this._commonPageService.commonState.sort = sort;
    if (this.isDailyProductionReport) {
      this.runVobDailyProductionReport();
    } else if (this.isQualityReport) {
      this.runVobQualityReport();
    } else if (this.isCallTimeReport) {
      this.runVobCallTimeReport();
    } else if (this.isTATReport) {
      this.runVobTATReport();
    } else if (this.isSpecialistBreakReport) {
      this.runVobSpecialistBreakReport();
    }
  }
  //#endregion
  //#region Paging Grid Data
  pageChangeVOBReport(event: PageChangeEvent): void {
    this._commonPageService.commonState.take = event.take;
    this._commonPageService.commonState.skip = event.skip;
  }
  //#endregion
  //#region Filter Grid Data
  filterChangeVOBReport(filter: CompositeFilterDescriptor): void {
    this._commonPageService.commonState.filter = filter;
    if (this.isDailyProductionReport) {
      this.runVobDailyProductionReport();
    } else if (this.isQualityReport) {
      this.runVobQualityReport();
    } else if (this.isCallTimeReport) {
      this.runVobCallTimeReport();
    } else if (this.isTATReport) {
      this.runVobTATReport();
    } else if (this.isSpecialistBreakReport) {
      this.runVobSpecialistBreakReport();
    }
  }

  processData() {
    this._commonPageService.groupPaginationListDataCommon = {
      data:
        filterBy(this._commonPageService.groupPaginationListDataCommon.data, this._commonPageService.commonState.filter),
      total:
        filterBy(this._commonPageService.groupPaginationListDataCommon.data, this._commonPageService.commonState.filter).length
    }
    this._commonPageService.groupPaginationListDataCommon = {
      data:
        orderBy(this._commonPageService.groupPaginationListDataCommon.data, this._commonPageService.commonState.sort),
      total:
        orderBy(this._commonPageService.groupPaginationListDataCommon.data, this._commonPageService.commonState.sort).length
    };
  }
  //#endregion
  //#region Clear Grid Data
  public clearFilter() {
    this._commonPageService.clearCommonState();
    this._commonPageService.clearGroupPaginationListDataCommon();
    this.range.start = this.coreHelperService.getMonthStartDate().toISOString();
    this.range.end = this.coreHelperService.getMonthEndDate().toISOString();
    this.getFacilityForFilter();
    this.selectedMonthss = "This Month";
  }
  //#endregion
  public exportToPdf(grid: GridComponent): void {
    grid.saveAsPDF();
  }
  public exportToExcel(grid: GridComponent): void {
    grid.saveAsExcel();
  }
}