import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { BehaviorSubject, Observable } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';

import { environment } from 'src/environments/environment';
import { OktaUser } from './user-management.classes';

@Injectable({
  providedIn: 'root'
})
export class UserManagementService extends CoreHttpService {

  private userUrl: string = `${environment.apiUrl}/user`;
  private roleUrl: string = `${environment.apiUrl}/role`;
  private teamUrl: string = `${environment.apiUrl}/team`;

  updateOnlineUsers = new BehaviorSubject<any>([]);
  currentOnlineUsers = this.updateOnlineUsers.asObservable();

  constructor(
    private _http: HttpClient,
    router: Router,
    spinner: NgxSpinnerService
  ) {
    super(_http, router, spinner);
  }

  public getUpdatedOnlineUsers(user: any): void {
    this.updateOnlineUsers.next(user);
  }

  /* User Master API */

  doLoginWithPin(params: any): Observable<any> {
    return this._http.post(`${this.userUrl}/LoginWithPin`, "", { params: params });
  }

  getLoginDetailByUserName(params: any): Observable<any> {
    return this._http.post(`${this.userUrl}/GetLoginDetailByUserName`, "", { params: params });
  }

  getUserList(model: any): Observable<any> {
    return this.post(`${this.userUrl}/GetUserList`, model);
  }

  getUserProfileById(userId: number): Observable<any> {
    return this.get(`${this.userUrl}/GetUserProfileById?UserId=${userId}`);
  }

  getDocumentUserList(userId): Observable<any> {
    return this.get(`${this.userUrl}/GetUsersDetail?UserId=${userId}`);
  }

  getUserAgreementFormat(): Observable<any> {
    return this.get(`${this.userUrl}/GetUserAgreementFormat`);
  }

  addUpdateUserAgreements(param): Observable<any> {
    return this.post(`${this.userUrl}/AddUpdateUserAgreements`, param);
  }

  deleteUserMaster(formData: FormData): Observable<any> {
    return this.postFormData(`${this.userUrl}/AddUpdateUserMaster`, formData);
  }

  AddUpdateUserMaster(formData: FormData): Observable<any> {
    return this.postFormData(`${this.userUrl}/AddUpdateUserMaster`, formData);
  }

  UpdateUserProfile(formData: FormData): Observable<any> {
    return this.postFormData(`${this.userUrl}/UpdateUserProfile`, formData);
  }

  checkIsUsernameExists(userName: any): Observable<any> {
    return this.post(`${this.userUrl}/CheckIsUsernameExists`, userName);
  }

  setActiveInactiveByUserId(model: any): Observable<any> {
    return this.post(`${this.userUrl}/SetActiveInactiveByUserId`, model);
  }

  getUserHistory(model: any): Observable<any> {
    return this.post(`${this.userUrl}/GetUserHistory`, model);
  }

  getUserAccessibilityList(params: any): Observable<any> {
    return this.postParams(`${this.userUrl}/GetUserAccessibilityList`, "", params);
  }

  SetUserAccessibility(model: any) {
    return this.post(`${this.userUrl}/SetUserAccessibility`, model);
  }

  getSingedInUserList() {
    return this.get(`${this.userUrl}/GetSignedInUserList`)
  }

  getUserStatusDetailById() {
    return this.get(`${this.userUrl}/GetUserStatusDetailById`)
  }

  addUpdateSignedUser(param: any): Observable<any> {
    return this.postParams(`${this.userUrl}/AddUpdateSignedInUser`, "", param);
  }

  getDepartmentwiseUser(departmentId: number): Observable<any> {
    return this.get(`${environment.apiUrl}/Common/GetUserListByDepartmentId?departmentId=` + departmentId)
  }
  /* User Master API end */

  /* Role Management API  */
  getRoleManagementList(params: any) {
    return this.postParams(`${this.roleUrl}/GetRoleList`, "", params);
  }
  saveRoleManagement(model: any): Observable<any> {
    return this.post(`${this.roleUrl}/AddUpdateRoleMaster`, model);
  }
  statusRoleManagement(model: any) {
    return this.post(`${this.roleUrl}/SetActiveInactiveByRoleId`, model);
  }
  historyRoleManagement(model: any) {
    return this.post(`${this.roleUrl}/GetRoleHistory`, model);
  }
  /* Role Management API  end*/

  /* Team Management API  */
  getTeamManagementList(params: any) {
    return this.postParams(`${this.teamUrl}/GetTeamList`, "", params);
  }
  saveTeamManagement(model: any) {
    return this.post(`${this.teamUrl}/AddUpdateTeamMaster`, model);
  }
  statusTeamManagement(model: any) {
    return this.post(`${this.teamUrl}/SetActiveInactiveByTeamId`, model);
  }
  historyTeamManagement(model: any) {
    return this.post(`${this.teamUrl}/GetTeamHistory`, model);
  }
  resetpasswordLink(model: any): Observable<any> {
    return this.post(`${this.userUrl}/ResetPasswordLink`, model)
  }

  resetUserPassword(model: any): Observable<any> {
    return this.post(`${this.userUrl}/ResetUserPassword`, model)
  }
  userChangePassword(model: any): Observable<any> {
    return this.post(`${this.userUrl}/UserChangePassword`, model)
  }


  /* 

    OKTA Settings
    Create User at OKTA
    oktaIssuerDNS

  */

  createOktaUser(user: OktaUser): Observable<any> {
    return this.postOkta(`https://dev-76664322.okta.com/api/v1/users?activate=true`, user);
  }
}
