// Manual Auto Call Type
export enum CallType {
    Automatic = 1,
    Manual = 2
}

// Call Actions
export enum CallAction {
    Start = 1,
    Hold = 2,
    Resume = 3,
    DiscussionStart = 4,
    DiscussionEnd = 5,
    End = 6,
}

// Call Refference Modules Type
export enum CallRefferenceType {
    VOB = 1,
    UR = 2,
    Billing = 3,
    RCM = 4,
    MR = 5,
    Payment = 6,
    TPN = 7
}