import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable, Subject } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';

import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HeaderService extends CoreHttpService {

  clearData = new Subject();
  callCleartasklist = new Subject();
  callClearvoblist = new Subject();
  callpatientserach = new Subject();

  private taskUrl: string = `${environment.apiUrl}/TaskMaster`;
  private caseIdUrl: string = `${environment.apiUrl}/CaseRegister`;
  private userUrl: string = `${environment.apiUrl}/User`;
  private StatusUrl: string = `${environment.apiUrl}/TaskMaster`;
  private notificationUrl: string = `${environment.apiUrl}/Notification`;
  private patientUrl: string = `${environment.apiUrl}/PatientMaster`;

  constructor(
    private _http: HttpClient,
    router: Router,
    spinner: NgxSpinnerService
  ) {
    super(_http, router, spinner);
  }

  public cleartask() {
    this.callClearvoblist.next(true);
  }
  public cleartaskList() {
    this.callCleartasklist.next(true);
  }

  public patientSearch() {
    this.callpatientserach.next(true);
  }

  gettaskTypeList(model: any): Observable<any> {
    return this.post(`${this.taskUrl}/GetTaskTypeAndStatusList/`, model);
  }

  getstatusList(model: any): Observable<any> {
    return this.post(`${this.StatusUrl}/GetTaskTypeAndStatusList/`, model);
  }

  AddUpdateTaskMaster(model: any): Observable<any> {
    return this.postFormData(`${this.taskUrl}/AddUpdateTaskMaster`, model);
  }

  getcaseIdList(FacilityId: any): Observable<any> {
    return this.post(`${this.caseIdUrl}/GetCensusCountByFacilityId?FacilityId=` + FacilityId, "");
  }

  getassignUserList(userId): Observable<any> {
    return this.get(`${this.userUrl}/GetUsersDetail?UserId=${userId}`);
  }

  getPatientList(): Observable<any> {
    return this.get(`${this.patientUrl}/GetPatientsForList`);
  }

  /* profile notification setting*/
  GetNotificationSettingsByUserId(): Observable<any> {
    return this.get(`${this.notificationUrl}/GetNotificationSettingsByUserId`)
  }

  UpdateNotificationSettings(model): Observable<any> {
    return this.put(`${this.notificationUrl}/UpdateNotificationSettingsByUserId`, model);
  }
  /* profile notification setting*/


  //#region User Notification Messages
  getNotificationCountByUserId(): Observable<any> {
    return this.get(`${this.notificationUrl}/GetNotificationCountByUserId`);
  }

  getNotificationByUserId(): Observable<any> {
    return this.get(`${this.notificationUrl}/GetNotificationByUserId`);
  }

  markNotificationAsReaded(requestModel: any): Observable<any> {
    return this.post(`${this.notificationUrl}/MarkNotificationRead/`, requestModel);
    // return this.get(`${this.notificationUrl}/MarkNotificationRead?notificationId=${notificationId}&doDelete=${doDelete}`);
  }
  //#endregion
}