import { Component, Inject, NgZone } from '@angular/core';
import { DEFAULT_INTERRUPTSOURCES, Idle } from '@ng-idle/core';
import { OKTA_AUTH } from '@okta/okta-angular';
import { OktaAuth } from '@okta/okta-auth-js';
import { CoreHelperService } from './providers/core-helper/core-helper.service';
import { NavigationEnd, Router } from '@angular/router';
import { UserManagementService } from './modules/auth/administration/user-management/user-management.service';
import { DOCUMENT } from '@angular/common';
import { UserDomainType, UserStatus } from './common/enum/user-domain';
import { NotificationHubService } from './providers/notification.service';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})

export class AppComponent {
  idleState = 'Not started.';
  timedOut: boolean = false;
  logoutTimer: string = "";
  interruptTimer: boolean = false;
  logoutInterval: any;
  onBreak: boolean = false;

  constructor(
    private idle: Idle,
    public _coreHelper: CoreHelperService,
    private _userManagementService: UserManagementService,
    @Inject(OKTA_AUTH) public oktaAuth: OktaAuth,
    private router: Router,
    private ngZone: NgZone,
    private notification: NotificationHubService,
    @Inject(DOCUMENT) private document: any,
  ) {
    //Check Permission
    let urlToValidate = location.pathname.substr(1, location.pathname.length);
    if (urlToValidate.includes("non-auth/user-agreement")) {
      this.router.navigate(['non-auth/user-agreement']);
      return;
    }

    if (!urlToValidate.includes("login/callback") && !urlToValidate.includes("non-auth/user-agreement")) {
      let accessAllowed = this._coreHelper.checkModulePermission(urlToValidate);
      if (!accessAllowed) {
        let domainId = this._coreHelper.getUserDomain();
        if (domainId == UserDomainType.Internal) {
          this.router.navigate(['/auth/dashboard']);
        } else {
          this.router.navigate(['/auth/facility-dashboard']);
        }
      }
    }
    this.ngZone.runOutsideAngular(() => { });
    this.oktaAuth.tokenManager.on('expired', function (key, expiredToken) {
      this.timedOut = true;
    });
  }

  async ngOnInit() {
    this.idle.setIdle(3600);
    // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);
    this.router.events.subscribe((e) => {
      if (e instanceof NavigationEnd) {
        if (e.url != '/') {
          this.idle.watch();
          this.idleState = 'Started.';
          this.idle.onIdleStart.subscribe(async () => {
            this.idleState = "You've gone idle!";
            this.timedOut = true;
            this.runLogoutTimer(1);
          });
        } else {
          this.timedOut = false;
        }
      }
    });
  }

  ngAfterViewInit() {
    this.notification.StartConnection();
  }

  ngAfterViewChecked(){
    this._coreHelper.selectedUserStatus = this._coreHelper.getUserStatusId();
    if(this._coreHelper.selectedUserStatus.toString() == UserStatus.Break.toString() && !this.onBreak){
      this.onBreak = true;
    }
  }

  runLogoutTimer(minute: number) {
    let seconds: number = minute * 60;
    let textSec: any = "0";
    let statSec: number = 60;
    const prefix = minute < 10 ? "0" : "";
    this.logoutInterval = setInterval(() => {
      if (this.interruptTimer) {
        this.interruptTimer = false;
        this.timedOut = false;
        seconds = null;
        clearInterval(this.logoutInterval);
        return;
      }
      if (seconds != null) {
        seconds--;
        if (statSec != 0) statSec--;
        else statSec = 59;

        if (statSec < 10) {
          textSec = "0" + statSec;
        } else textSec = statSec;

        this.logoutTimer = `${prefix}${Math.floor(seconds / 60)}:${textSec}`;

        if (seconds == 0) {
          clearInterval(this.logoutInterval);
          this.timedOut = false;
          this.logout();
        }
      }
    }, 1000);
  }

  staySignInUser() {
    this.interruptTimer = true;
    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
  }

  public async logout() {
    let requestModel = {
      userId: this._coreHelper.getUserId(),
      isLogin: 0,
      spMode: 1,
      userStatusId: UserStatus.Offline
    }
    if (requestModel.userId != undefined || requestModel.userId != null) {
      await lastValueFrom(this._userManagementService.addUpdateSignedUser(requestModel)).then((response) => {
        localStorage.clear();
      });
      await this.oktaAuth.signOut();
    };
  }

  continueWorkingAfterBreak() {
    this.updateUserStatusToAvailable();
    this.onBreak = false;
  }

  async updateUserStatusToAvailable() {
    let requestModel = {
      userId: this._coreHelper.getUserId(),
      isLogin:  UserStatus.Available,
      spMode: 1,
      userStatusId: UserStatus.Available
    }
    if (requestModel.userId != undefined || requestModel.userId != null) {
      await lastValueFrom(this._userManagementService.addUpdateSignedUser(requestModel)).then((response) => {
        this._coreHelper.setUserStatusId(response.apiData);
        this.onBreak = false;
      });
    };
  }
}
