import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { lastValueFrom } from 'rxjs';
import { PatientsService } from '../../resource/add-patients-master/patients-master.service';
import { PDFExportComponent } from '@progress/kendo-angular-pdf-export';
import { VOBService } from '../../services/vob.service';
import { exportPDF, Group } from '@progress/kendo-drawing';
import { CoreHelperService } from 'src/app/providers/core-helper/core-helper.service';

@Component({
  selector: 'app-vob-pdf-export',
  templateUrl: './vob-pdf-export.component.html',
  styleUrls: ['./vob-pdf-export.component.scss']
})

export class VobPdfExportComponent implements OnInit {

  @Input() public paramVobId: number = 0;
  @Input() public paramExportFileName: string = "";
  @Input() public vobFileDate: Date
  @Input() public vobFileFacilityName: string = "";
  @Input() public isAutoDownload: boolean = false;
  @ViewChild("vobpdf") public vobpdf: PDFExportComponent;
  @ViewChild('downloadButton') downloadButton: ElementRef;
  vobId: number = 0;
  vobExportFileName: string = "";
  isHide: boolean = true;

  constructor(
    private _patientservice: PatientsService,
    private _vobService: VOBService,
    private _coreHelperService: CoreHelperService
  ) { }

  ngOnInit(): void {
    this.vobExportFileName = this.paramExportFileName;
    this.vobId = this.paramVobId;
    this.loadVobExportData();
  }

  //VOB Export to PDF
  vobExportData: any;
  async loadVobExportData() {
    await lastValueFrom(this._patientservice.getVobExportByVobId(this.vobId)).then((response) => {
      if (response.apiStatus) {
        this.vobExportData = response.apiData;
        if (this.vobExportData) {
          setTimeout(() => {
            if (this.isAutoDownload) {
              let inputElement: HTMLElement = this.downloadButton.nativeElement as HTMLElement;
              inputElement.click();
            }
          }, 200);
        }
      } else {
        this._vobService.isViewPDF = false;
      }
    });
  }

  async export(pdfComponent: PDFExportComponent) {
    if (this.isAutoDownload) {
      let fileFormData: FormData = new FormData();
      pdfComponent.export().then((group: Group) => exportPDF(group)).then(async (dataUri) => {
        fileFormData.append("file", this.dataURLtoFile(dataUri, pdfComponent.fileName));
        fileFormData.append("fileName", this.vobExportFileName);
        fileFormData.append("facilityName", this.vobFileFacilityName);
        fileFormData.append("receivedDate", this._coreHelperService.getUtcDate(this.vobFileDate).toJSON());
        await lastValueFrom(this._vobService.uploadPatientVobFileToSharepoint(fileFormData)).then((response) => {
          this.isHide = false;
          this._vobService.isViewPDF = false;
        });
      });
    }
    else {
      pdfComponent.saveAs();
    }
  }

  public dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(','), mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }
    return new File([u8arr], filename, { type: mime });
  }
}
