import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { CoreHttpService } from 'src/app/providers/core-http/core-http.service';
import { environment } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CommonVobReportService extends CoreHttpService {
  public todate = new Date();
  private reportUrl: string = `${environment.apiUrl}/VobReports`;
  constructor(
    private _http: HttpClient,
    router: Router,
    spinner: NgxSpinnerService
  ) {
    super(_http, router, spinner);
  }

  getVobDailyProductionReportData(model): Observable<any> {
    return this.post(`${this.reportUrl}/GetVobDailyProductionReportData`, model);
  }

  getVobQualityReportData(model): Observable<any> {
    return this.post(`${this.reportUrl}/GetVobQualityReportData`, model);
  }
  
  getVobCallTimeReportData(model): Observable<any> {
    return this.post(`${this.reportUrl}/GetVobCallTimeReportData`, model);
  }

  getVobTatMissing(model): Observable<any> {
    return this.post(`${this.reportUrl}/GetVobTatMissing`, model);
  }

  getVobSpecialistBreak(model): Observable<any> {
    return this.post(`${this.reportUrl}/GetVobSpecialistBreak`, model);
  }

}
