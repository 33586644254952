<kendo-pdf-export #vobpdf paperSize="A3" margin="0.5cm" style="font-size: 10px;" author="CereusSoftware"
    forcePageBreak=".page-break" [fileName]="vobExportFileName">
    <div *ngIf="vobExportData != undefined">
        <!-- PDF LAYOUT -->
        <div class="row">
            <div class="col-md-12">
                <div class="p-3" style="background-color: black;">
                    <div class="d-flex">
                        <h3 class="text-primary" style="line-height:5 !important">Verification of Benefits</h3>
                        <div class="col d-flex justify-content-end">
                            <img src="../../../../assets/img/nextus-logo.png" height="100" width="200" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6">
                <table class="table table-bordered">
                    <tbody>
                        <tr class="bg-primary">
                            <th colspan="2">Patient Demographics</th>
                        </tr>
                        <tr>
                            <th>Name:</th>
                            <td>{{vobExportData.patientFullName}}</td>
                        </tr>
                        <tr>
                            <th>DOB:</th>
                            <td>{{vobExportData.patientDOB | localDate}}</td>
                        </tr>
                        <tr>
                            <th>Subscr. Name:</th>
                            <td>{{vobExportData.patientSubscriberName}}</td>
                        </tr>
                        <tr>
                            <th>Subscr. DOB:</th>
                            <td>{{vobExportData.patientSubscriberDOB | localDate}}</td>
                        </tr>
                        <tr>
                            <th>Relationship:</th>
                            <td>{{vobExportData.patientSubscriberRelation}}</td>
                        </tr>
                        <tr>
                            <th>Address:</th>
                            <td>{{vobExportData.patientAddress}}</td>
                        </tr>
                        <tr>
                            <th>Member Svc Phone:</th>
                            <td>{{vobExportData.patientMemberServicePhone}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-6">
                <table class="table table-bordered">
                    <tbody>
                        <tr class="bg-primary">
                            <th colspan="4">Insurance Info</th>
                        </tr>
                        <tr>
                            <th>Insurance Company:</th>
                            <td colspan="3">{{vobExportData.insuranceCompany}}</td>
                        </tr>
                        <tr>
                            <th>Insurance Phone:</th>
                            <td colspan="3">{{vobExportData.insurancePhoneNo}}</td>
                        </tr>
                        <tr>
                            <th>Policy ID:</th>
                            <td colspan="3">{{vobExportData.insuranceMemberId}}</td>
                        </tr>
                        <tr>
                            <th>Group No:</th>
                            <td colspan="3">{{vobExportData.insuranceGroupId}}</td>
                        </tr>
                        <tr class="col">
                            <th class="col-md-3">Plan Type:</th>
                            <td class="col-md-3">{{vobExportData.insurancePlanType}}</td>
                            <th class="col-md-3">Funding:</th>
                            <td class="col-md-3">{{vobExportData.insuranceFunding}}</td>
                        </tr>
                        <tr>
                            <th>Claims Address:</th>
                            <td colspan="3">{{vobExportData.insuranceClaimAddress}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-6">
                <table class="table table-bordered">
                    <tbody>
                        <tr class="bg-primary">
                            <th colspan="2">Eligibility</th>
                        </tr>
                        <tr>
                            <th>Coverage Active?</th>
                            <td>{{vobExportData.eligibilityCoverageActive}}</td>
                        </tr>
                        <tr>
                            <th>Effective Coverage Date:</th>
                            <td>{{vobExportData.eligibilityEffectiveCoverageDate | localDate}}</td>
                        </tr>
                        <tr>
                            <th>Effective Term Date:</th>
                            <td>{{vobExportData.eligibilityEffectiveTermDate | localDate}}</td>
                        </tr>
                        <tr>
                            <th>Plan or Calendar Year?</th>
                            <td>{{vobExportData.eligibilityPlanOrCalendarYear}}</td>
                        </tr>
                        <tr>
                            <th>Pre-Existing?</th>
                            <td>{{vobExportData.eligibilityPreExisting}}</td>
                        </tr>
                        <tr>
                            <th>Out of Network Benefits?</th>
                            <td>{{vobExportData.eligibilityOutOfNetworkBenefits}}</td>
                        </tr>
                        <tr>
                            <th>Primary Insurance Policy?</th>
                            <td>{{vobExportData.eligibilityPrimaryInsurancePolicy}}</td>
                        </tr>
                        <tr>
                            <th>Other Insurance on File?</th>
                            <td>{{vobExportData.eligibilityOtherInsuranceOnFile}}</td>
                        </tr>
                        <tr>
                            <th>COB Last updated?</th>
                            <td>{{vobExportData.eligibilityCOBLastUpdated | localDate}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-6">
                <table class="table table-bordered">
                    <tbody>
                        <tr class="bg-primary">
                            <th colspan="9"> Precertification Requirements </th>
                        </tr>
                        <tr class="col">
                            <th class="col-md-1">IPDE:</th>
                            <td class="col-md-1">{{vobExportData.ipdE1}}</td>
                            <td class="col-md-1">{{vobExportData.ipdE2}}</td>
                            <th class="col-md-1">PHP:</th>
                            <td class="col-md-1">{{vobExportData.phP1}}</td>
                            <td class="col-md-1">{{vobExportData.phP2}}</td>
                            <th class="col-md-1">OP GRP:</th>
                            <td class="col-md-1">{{vobExportData.opgrP1}}</td>
                            <td class="col-md-1">{{vobExportData.opgrP2}}</td>
                        </tr>
                        <tr class="col">
                            <th class="col-md-1">RTC:</th>
                            <td class="col-md-1">{{vobExportData.rtC1}}</td>
                            <td class="col-md-1">{{vobExportData.rtC2}}</td>
                            <th class="col-md-1">IOP:</th>
                            <td class="col-md-1">{{vobExportData.ioP1}}</td>
                            <td class="col-md-1">{{vobExportData.ioP2}}</td>
                            <th class="col-md-1">OP IND45:</th>
                            <td class="col-md-1">{{vobExportData.opinD1}}</td>
                            <td class="col-md-1">{{vobExportData.opinD2}}</td>
                        </tr>
                        <tr>
                            <th colspan="3">Pre-certification Name:</th>
                            <td colspan="6">{{vobExportData.preCertificationName}}</td>
                        </tr>
                        <tr>
                            <th colspan="3">Pre-certification Number:</th>
                            <td colspan="6">{{vobExportData.preCertificationNumber}}</td>
                        </tr>
                        <tr>
                            <th colspan="3">Pre-certification Penalty:</th>
                            <td colspan="6">{{vobExportData.preCertificationPenalty}}</td>
                        </tr>
                        <tr>
                            <th colspan="3">Visit Limits:</th>
                            <td colspan="6">{{vobExportData.visitLimits}}</td>
                        </tr>
                        <tr>
                            <th colspan="3">Single Case Conditions:</th>
                            <td colspan="6">{{vobExportData.singleCaseConditions}}</td>
                        </tr>
                        <tr>
                            <th colspan="3">Term Grace Period Status:</th>
                            <td colspan="6">{{vobExportData.termGracePeriodStatus}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mb-2 page-break">
            <div class="col-md-6">
                <table class="table table-bordered">
                    <tbody>
                        <tr class="bg-primary">
                            <th colspan="2">Co-Insurance</th>
                        </tr>
                        <tr>
                            <th>Patient Pays:</th>
                            <td>{{vobExportData.patientPays}}</td>
                        </tr>
                        <tr>
                            <th>Insurance Pays:</th>
                            <td>{{vobExportData.insurancePays}}</td>
                        </tr>
                        <tr>
                            <th>Ind. Deduct:</th>
                            <td>{{vobExportData.indDeduct}}</td>
                        </tr>
                        <tr>
                            <th>Accumulation:</th>
                            <td>{{vobExportData.indDeductAccumulation}}</td>
                        </tr>
                        <tr>
                            <th>Ind. Max OOP:</th>
                            <td>{{vobExportData.indMaxOop}}</td>
                        </tr>
                        <tr>
                            <th>Accumulation:</th>
                            <td>{{vobExportData.indMaxOopAccumulation}}</td>
                        </tr>
                        <tr>
                            <th>Does DED go towards OOP?</th>
                            <td>{{vobExportData.doesDedGoTowardsOop}}</td>
                        </tr>
                        <tr>
                            <th>Claim Rate of Payment:</th>
                            <td>{{vobExportData.claimRateOfPayment}}</td>
                        </tr>
                        <tr>
                            <th>Does policy allow AOB?</th>
                            <td>{{vobExportData.doesPolicyAllowAob}}</td>
                        </tr>
                        <tr>
                            <th>Claims Will Pay To?</th>
                            <td>{{vobExportData.claimsWillPayTo}}</td>
                        </tr>
                        <tr>
                            <th>Third Party Administrator?</th>
                            <td>{{vobExportData.thirdPartyAdministrator}}</td>
                        </tr>
                        <tr>
                            <th>Is Telehealth Covered?</th>
                            <td>{{vobExportData.isTelehealthCovered}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-md-6">
                <table class="table table-bordered">
                    <tbody>
                        <tr class="bg-primary">
                            <th colspan="7">Exclusions</th>
                        </tr>
                        <tr>
                            <th>Exclusions:</th>
                            <td colspan="6">{{vobExportData.exclusions}}</td>
                        </tr>
                        <tr>
                            <th>Free Standing Facility Okay?</th>
                            <td colspan="4">{{vobExportData.freeStandingFacility}}</td>
                            <th colspan="2">Billable Codes:</th>
                        </tr>
                        <tr>
                            <th>State License Sufficient?</th>
                            <td colspan="4">{{vobExportData.stateLicenseSufficient}}</td>
                            <td colspan="1">{{vobExportData.billableCodes1}}</td>
                            <td colspan="1">{{vobExportData.billableCodes2}}</td>
                        </tr>
                        <tr>
                            <th>JCAHO/CARF Accreditation?</th>
                            <td colspan="6">{{vobExportData.accreditation}}</td>
                        </tr>
                        <tr>
                            <th>UA Covered?</th>
                            <td colspan="6">{{vobExportData.uaCovered}}</td>
                        </tr>
                        <tr>
                            <th>Limitations:</th>
                            <td colspan="6">{{vobExportData.limitations}}</td>
                        </tr>
                        <tr>
                            <th>Is there a Service Area Defined on this policy?</th>
                            <td colspan="6">{{vobExportData.thereServiceAreaDefinedOnThisPolicy}}</td>
                        </tr>
                        <tr>
                            <th>Are benefits available outside Service Area?</th>
                            <td colspan="6">{{vobExportData.benefitsAvailableOutsideServiceArea}}</td>
                        </tr>
                        <tr>
                            <th>Verified By:</th>
                            <td colspan="6">{{vobExportData.verifiedBy}}</td>
                        </tr>
                        <tr>
                            <th>Date/Time:</th>
                            <td colspan="6">{{vobExportData.dateTime}}</td>
                        </tr>
                        <tr>
                            <th>Representative:</th>
                            <td colspan="6">{{vobExportData.representative}}</td>
                        </tr>
                        <tr>
                            <th>Ref#</th>
                            <td colspan="6">{{vobExportData.ref}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
        <div class="row mb-2">
            <div class="col-md-12">
                <table class="table table-bordered">
                    <tbody>
                        <tr class="bg-primary">
                            <th class="col-md-8">Notes:</th>
                            <th class="col-md-4">Disclaimer:</th>
                        </tr>
                        <tr>
                            <td class="col-md-8">{{vobExportData.notes}}</td>
                            <td class="col-md-4">{{vobExportData.disclaimer}}</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </div>
    </div>
    <div class="row" style="float: left;" *ngIf="vobExportData != undefined && isHide">
        <div class="top-action-btns">
            <ul>
                <li *ngIf="this.vobId> 0">
                    <a href="javascript:;" #downloadButton class="btn btn-primary" (click)="export(vobpdf)">
                        <span class="custom-icons icon-download m-r"></span>Download</a>
                </li>
            </ul>
        </div>
    </div>
</kendo-pdf-export>
<div class="row" *ngIf="vobExportData == undefined">
    <div class="top-action-btns">
        <h4 *ngIf="!isAutoDownload" style="text-align: center;">VOB information is pending. Please complete the VOB form to export!</h4>
        <h4 *ngIf="isAutoDownload" style="text-align: left;">VOB Form is incomplete are you sure would like to send this to facility?</h4>
    </div>
</div>