
export enum UserDomainType {
    Internal = 0,
    External = 1
}

export enum UserDomainTypeName {
    Internal = "Internal",
    External = "External"
}

export enum PatientCaseViewTabId {
    PatientInfo = 0,
    VOBInfo = 1,
    AuthInfo = 2,
    Services = 3,
    Documents = 4,
    FacilityInfo = 5
}
export enum UserStatus {
    Available = 1,
    Away = 2,
    Busy = 3,
    Offline = 4,
    Break = 5
}