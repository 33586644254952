export enum ModuleTabIds {
    FacilityMaster_Facilities = 1,
    FacilityMaster_FacilityGrouping = 2,
    PatientMaster_Patients = 3,
    PatientMaster_Documents = 4,
    VOB_Workspace = 5,
    VOB_Progress = 6,
    VOB_Statistics = 7,
    VOB_Scorecard = 8,
    VOB_Documents = 9,
    UR_Workspace = 10,
    UR_Progress = 11,
    UR_Statistics = 12,
    UR_Scorecard = 13,
    UR_Documents = 14,
    Billing_Workspace = 15,
    Billing_Progress = 16,
    Billing_Statistics = 17,
    Billing_Scorecard = 18,
    Billing_Documents = 19,
    RCM_Workspace = 20,
    RCM_Progress = 21,
    RCM_Statistics = 22,
    RCM_Scorecard = 23,
    RCM_Documents = 24,
    MedicalRecord_Workspace = 25,
    MedicalRecord_Progress = 26,
    MedicalRecord_Statistics = 27,
    MedicalRecord_Scorecard = 28,
    MedicalRecord_Documents = 44,
    Payments_Workspace = 29,
    Payments_Progress = 30,
    Payments_Statistics = 31,
    Payments_Scorecard = 32,
    Payments_Documents = 45,
    TPN_Workspace = 33,
    TPN_Progress = 34,
    TPN_Completed = 35,
    TPN_Statistics = 36,
    UserManagement_Department = 37,
    UserManagement_Users = 38,
    UserManagement_RoleManagement = 39,
    UserManagement_TeamManagement = 40,

    //Facility Dashboard
    FacilityDashboard_VOB = 41,
    FacilityDashboard_UR = 42,
    FacilityDashboard_Billing = 43,
    FacilityDashboard_RCM = 46,

    //VOB Reports
    Daily_Production_Report = 47,
    Quality_Report = 48,
    Call_Time_Report = 49,
    TAT_Report = 50,
    Specialist_Break_Report = 51,
}