<div class="payment-summary-section">
  <div class="common-tab-with-buttons">
    <div class="inner-action-section inner-full-action">
      <div class="row align-items-center">
        <div class="col-12 col-md-12 col-lg-6">
          <h5 *ngIf="selectedFacility.length > 0"><a href="javascript:;" class="h-title"
              (click)="facilityOpen()"><b>{{selectedFacility[0]?.facilityName}}</b> <span
                *ngIf="selectedFacility.length > 1"><b> ({{selectedFacility?.length}})</b></span></a></h5>
          <h5>{{reportName}} between {{this.range.start | localDate }}
            to {{this.range.end | localDate }}</h5>
        </div>
        <div class="col-12 col-md-12 col-lg-6">
          <div class="top-action-btns">
            <ul>
              <li><a (click)="clearFilter()" class="btn btn-primary btn-sm"><span
                    class="custom-icons icon-rotate m-r"></span>Clear</a></li>
              <li><a href="javascript:;" class="btn btn-primary btn-sm" (click)="filterOpen()"><span
                    class="custom-icons icon-filter m-r"></span> Filter</a></li>
              <li *ngIf="isDailyProductionReport && isDailyProductionReportExportAllowed">
                <div class="dropdown">
                  <a href="javascript:;" class="btn btn-primary btn-sm dropdown-toggle" id="dropdownExport"
                    data-bs-toggle="dropdown" aria-expanded="false">Export</a>
                  <ul class="dropdown-menu dropdown-top-position" aria-labelledby="dropdownExport">
                    <li>
                      <a class="dropdown-item" href="javascript:;" (click)="exportToExcel(dailyProductionReportGrid)">
                        <span class="custom-icons icon-excel m-r"></span>Excel</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="javascript:;" (click)="exportToPdf(dailyProductionReportGrid)">
                        <span class="custom-icons icon-pdf m-r"></span>PDF</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li *ngIf="isQualityReport && isQualityReportExportAllowed">
                <div class="dropdown">
                  <a href="javascript:;" class="btn btn-primary btn-sm dropdown-toggle" id="dropdownExport"
                    data-bs-toggle="dropdown" aria-expanded="false">Export</a>
                  <ul class="dropdown-menu dropdown-top-position" aria-labelledby="dropdownExport">
                    <li>
                      <a class="dropdown-item" href="javascript:;" (click)="exportToExcel(qualityReportGrid)">
                        <span class="custom-icons icon-excel m-r"></span>Excel</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="javascript:;" (click)="exportToPdf(qualityReportGrid)">
                        <span class="custom-icons icon-pdf m-r"></span>PDF</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li *ngIf="isCallTimeReport && isCallTimeReportExportAllowed">
                <div class="dropdown">
                  <a href="javascript:;" class="btn btn-primary btn-sm dropdown-toggle" id="dropdownExport"
                    data-bs-toggle="dropdown" aria-expanded="false">Export</a>
                  <ul class="dropdown-menu dropdown-top-position" aria-labelledby="dropdownExport">
                    <li>
                      <a class="dropdown-item" href="javascript:;" (click)="exportToExcel(callTimeReportGrid)">
                        <span class="custom-icons icon-excel m-r"></span>Excel</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="javascript:;" (click)="exportToPdf(callTimeReportGrid)">
                        <span class="custom-icons icon-pdf m-r"></span>PDF</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li *ngIf="isTATReport && isTATReportExportAllowed">
                <div class="dropdown">
                  <a href="javascript:;" class="btn btn-primary btn-sm dropdown-toggle" id="dropdownExport"
                    data-bs-toggle="dropdown" aria-expanded="false">Export</a>
                  <ul class="dropdown-menu dropdown-top-position" aria-labelledby="dropdownExport">
                    <li>
                      <a class="dropdown-item" href="javascript:;" (click)="exportToExcel(tatReportGrid)">
                        <span class="custom-icons icon-excel m-r"></span>Excel</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="javascript:;" (click)="exportToPdf(tatReportGrid)">
                        <span class="custom-icons icon-pdf m-r"></span>PDF</a>
                    </li>
                  </ul>
                </div>
              </li>
              <li *ngIf="isSpecialistBreakReport && isSpecialistBreakReportExportAllowed">
                <div class="dropdown">
                  <a href="javascript:;" class="btn btn-primary btn-sm dropdown-toggle" id="dropdownExport"
                    data-bs-toggle="dropdown" aria-expanded="false">Export</a>
                  <ul class="dropdown-menu dropdown-top-position" aria-labelledby="dropdownExport">
                    <li>
                      <a class="dropdown-item" href="javascript:;" (click)="exportToExcel(specialistBreakReportGrid)">
                        <span class="custom-icons icon-excel m-r"></span>Excel</a>
                    </li>
                    <li>
                      <a class="dropdown-item" href="javascript:;" (click)="exportToPdf(specialistBreakReportGrid)">
                        <span class="custom-icons icon-pdf m-r"></span>PDF</a>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
    <div class="common-border table-responsive only-pager-grid">
      <!-- Daily Production Report GRID -->
      <kendo-grid *ngIf="isDailyProductionReport" #dailyProductionReportGrid
        [data]="_commonPageService.groupPaginationListDataCommon" filterable="menu" [sortable]="true"
        [sort]="_commonPageService.commonState.sort" (sortChange)="sortChangeVOBReport($event)" [pageable]="true"
        [pageSize]="_commonPageService.commonState.take" [skip]="_commonPageService.commonState.skip"
        (pageChange)="pageChangeVOBReport($event)" [filter]="_commonPageService.commonState.filter"
        (filterChange)="filterChangeVOBReport($event)">
        <kendo-grid-column field="receivedDate" title="Date" [filterable]="true" filter="date" [width]="100">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.receivedDate | localDate}}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="120"></kendo-grid-column>
        <kendo-grid-column field="noOfRequest" class="text-center" title="Number of VOB Requests" [filterable]="true"
          [width]="90"></kendo-grid-column>
        <kendo-grid-column field="priorityRequest" class="text-center" title="Priority Requests" [filterable]="true"
          [width]="60"></kendo-grid-column>
        <kendo-grid-column field="tatMet" class="text-center" title="TAT MET" [filterable]="true"
          [width]="50"></kendo-grid-column>
        <kendo-grid-column field="accuracy" class="text-center" title="Accuracy" [filterable]="true"
          [width]="50"></kendo-grid-column>
        <kendo-grid-messages [pagerItems]="'List'" [pagerOf]="'of'"></kendo-grid-messages>
        <kendo-grid-pdf fileName="Daily Production Report.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
          [landscape]="true" [scale]="0.5">
          <!----custom div-->
          <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
          </kendo-grid-pdf-margin>
          <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header" style="border-bottom: none;" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">
                <div class="address">
                  <div class="for" style="text-align: center; margin-top: 2%;">
                    <h3>Daily Production Report</h3>
                  </div>
                </div>
              </div>
              <div class="footer">
                <div style="float: right">
                  Page {{ pageNum }} of {{ totalPages }}
                </div>
              </div>
            </div>
          </ng-template>
          <!----custom div-->
        </kendo-grid-pdf>
        <kendo-grid-excel fileName="Daily Production Report.xlsx">
        </kendo-grid-excel>
      </kendo-grid>

      <!-- Quality Report GRID -->
      <kendo-grid *ngIf="isQualityReport" #qualityReportGrid [data]="_commonPageService.groupPaginationListDataCommon"
        filterable="menu" [sortable]="true" [sort]="_commonPageService.commonState.sort"
        (sortChange)="sortChangeVOBReport($event)" [pageable]="true" [pageSize]="_commonPageService.commonState.take"
        [skip]="_commonPageService.commonState.skip" (pageChange)="pageChangeVOBReport($event)"
        [filter]="_commonPageService.commonState.filter" (filterChange)="filterChangeVOBReport($event)">
        <kendo-grid-column field="vobId" class="text-center" title="VOB ID" [width]="80"
          [filterable]="false"></kendo-grid-column>
        <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="120"></kendo-grid-column>
        <kendo-grid-column field="patientName" title="Patient Name" [filterable]="true"
          [width]="120"></kendo-grid-column>
        <kendo-grid-column field="receivedDate" title="Receipt Date" [filterable]="true" filter="date" [width]="120">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.receivedDate | localDate}}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="specialistName" title="Specialist" [filterable]="true"
          [width]="120"></kendo-grid-column>
        <kendo-grid-column field="noOfErrors" class="text-center" title="Number of Errors" [filterable]="true"
          [width]="120"></kendo-grid-column>
        <kendo-grid-column field="accuracy" class="text-center" title="Accuracy %" [filterable]="true"
          [width]="120"></kendo-grid-column>
        <kendo-grid-column field="" title="Change Log" [filterable]="true" [width]="120"></kendo-grid-column>
        <kendo-grid-messages [pagerItems]="'List'" [pagerOf]="'of'"></kendo-grid-messages>
        <kendo-grid-pdf fileName="Quality Report.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
          [landscape]="true" [scale]="0.5">
          <!----custom div-->
          <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
          </kendo-grid-pdf-margin>
          <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header" style="border-bottom: none;" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">
                <div class="address">
                  <div class="for" style="text-align: center; margin-top: 2%;">
                    <h3>Quality Report</h3>
                  </div>
                </div>
              </div>
              <div class="footer">
                <div style="float: right">
                  Page {{ pageNum }} of {{ totalPages }}
                </div>
              </div>
            </div>
          </ng-template>
          <!----custom div-->
        </kendo-grid-pdf>
        <kendo-grid-excel fileName="Quality Report.xlsx">
        </kendo-grid-excel>
      </kendo-grid>
      <!-- Call Time Report GRID -->
      <kendo-grid *ngIf="isCallTimeReport" #callTimeReportGrid [data]="_commonPageService.groupPaginationListDataCommon"
        filterable="menu" [sortable]="true" [sort]="_commonPageService.commonState.sort"
        (sortChange)="sortChangeVOBReport($event)" [pageable]="true" [pageSize]="_commonPageService.commonState.take"
        [skip]="_commonPageService.commonState.skip" (pageChange)="pageChangeVOBReport($event)"
        [filter]="_commonPageService.commonState.filter" (filterChange)="filterChangeVOBReport($event)">
        <kendo-grid-column field="enteredDate" title="Date" [filterable]="true" filter="date" [width]="120">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.enteredDate | localDate}}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="vobId" class="text-center" title="VOB ID" [width]="80"
          [filterable]="false"></kendo-grid-column>
        <kendo-grid-column field="specialistName" title="Specialist" [filterable]="true"
          [width]="120"></kendo-grid-column>
        <kendo-grid-column field="payerName" title="Payer" [filterable]="true" [width]="120"></kendo-grid-column>
        <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="120"></kendo-grid-column>
        <kendo-grid-column field="initiation" title="Initiation" [filterable]="true" [width]="120">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.initiation | localDateTime }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="hold" title="Hold" [filterable]="true" [width]="120"></kendo-grid-column>
        <kendo-grid-column field="conversation" title="Conversation" [filterable]="true"
          [width]="120"></kendo-grid-column>
        <kendo-grid-column field="totalDuration" title="Total Duration" [filterable]="true"
          [width]="120"></kendo-grid-column>
        <kendo-grid-messages [pagerItems]="'List'" [pagerOf]="'of'"></kendo-grid-messages>
        <kendo-grid-pdf fileName="Call Time Report.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
          [landscape]="true" [scale]="0.5">
          <!----custom div-->
          <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
          </kendo-grid-pdf-margin>
          <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header" style="border-bottom: none;" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">
                <div class="address">
                  <div class="for" style="text-align: center; margin-top: 2%;">
                    <h3>Call Time Report</h3>
                  </div>
                </div>
              </div>
              <div class="footer">
                <div style="float: right">
                  Page {{ pageNum }} of {{ totalPages }}
                </div>
              </div>
            </div>
          </ng-template>
          <!----custom div-->
        </kendo-grid-pdf>
        <kendo-grid-excel fileName="Call Time Report.xlsx">
        </kendo-grid-excel>
      </kendo-grid>
      <!-- TAT Report GRID -->
      <kendo-grid *ngIf="isTATReport" #tatReportGrid [data]="_commonPageService.groupPaginationListDataCommon"
        filterable="menu" [sortable]="true" [sort]="_commonPageService.commonState.sort"
        (sortChange)="sortChangeVOBReport($event)" [pageable]="true" [pageSize]="_commonPageService.commonState.take"
        [skip]="_commonPageService.commonState.skip" (pageChange)="pageChangeVOBReport($event)"
        [filter]="_commonPageService.commonState.filter" (filterChange)="filterChangeVOBReport($event)">
        <kendo-grid-column field="vobId" class="text-center" title="VOB ID" [width]="80"
          [filterable]="false"></kendo-grid-column>
        <kendo-grid-column field="facilityName" title="Facility" [filterable]="true" [width]="120"></kendo-grid-column>
        <kendo-grid-column field="patientName" title="Patient Name" [filterable]="true"
          [width]="120"></kendo-grid-column>
        <kendo-grid-column field="receivedDate" title="Receipt Date" [filterable]="true" filter="date" [width]="120">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.receivedDate | localDate}}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="completedDate" title="Completed Date" [filterable]="true" filter="date" [width]="120">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.completedDate | localDate}}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="specialistName" title="Specialist" [filterable]="true"
          [width]="120"></kendo-grid-column>
        <kendo-grid-column field="payerName" title="Payer" [filterable]="true" [width]="120"></kendo-grid-column>
        <kendo-grid-column field="reasonForMissing" title="Missing Reason" [filterable]="true"
          [width]="120"></kendo-grid-column>
        <kendo-grid-messages [pagerItems]="'List'" [pagerOf]="'of'"></kendo-grid-messages>
        <kendo-grid-pdf fileName="TAT Report.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
          [landscape]="true" [scale]="0.5">
          <!----custom div-->
          <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
          </kendo-grid-pdf-margin>
          <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header" style="border-bottom: none;" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">
                <div class="address">
                  <div class="for" style="text-align: center; margin-top: 2%;">
                    <h3>TAT Report</h3>
                  </div>
                </div>
              </div>
              <div class="footer">
                <div style="float: right">
                  Page {{ pageNum }} of {{ totalPages }}
                </div>
              </div>
            </div>
          </ng-template>
          <!----custom div-->
        </kendo-grid-pdf>
        <kendo-grid-excel fileName="TAT Report.xlsx">
        </kendo-grid-excel>
      </kendo-grid>
      <!-- Specialist Break Report GRID -->
      <kendo-grid *ngIf="isSpecialistBreakReport" #specialistBreakReportGrid
        [data]="_commonPageService.groupPaginationListDataCommon" filterable="menu" [sortable]="true"
        [sort]="_commonPageService.commonState.sort" (sortChange)="sortChangeVOBReport($event)" [pageable]="true"
        [pageSize]="_commonPageService.commonState.take" [skip]="_commonPageService.commonState.skip"
        (pageChange)="pageChangeVOBReport($event)" [filter]="_commonPageService.commonState.filter"
        (filterChange)="filterChangeVOBReport($event)">
        <kendo-grid-column field="breakDate" title="Date" [filterable]="true" filter="date" [width]="120">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{dataItem.breakDate | localDate}}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="specialistName" title="Specialist Name" [filterable]="true"
          [width]="120"></kendo-grid-column>
        <kendo-grid-column field="specAvailable" title="Availability" [filterable]="true"
          [width]="120"></kendo-grid-column>
        <kendo-grid-column field="startTime" title="Start Time" [filterable]="true" [width]="120">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem.startTime | localTimeSecond }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="endTime" title="End Time" [filterable]="true" [width]="120">
          <ng-template kendoGridCellTemplate let-dataItem>
            <span>{{ dataItem.endTime | localTimeSecond }}</span>
          </ng-template>
        </kendo-grid-column>
        <kendo-grid-column field="duration" title="Duration(Mins)" [filterable]="true"
          [width]="120"></kendo-grid-column>
        <kendo-grid-messages [pagerItems]="'List'" [pagerOf]="'of'"></kendo-grid-messages>
        <kendo-grid-pdf fileName="Specialist Break Report.pdf" [allPages]="true" paperSize="A4" [repeatHeaders]="true"
          [landscape]="true" [scale]="0.5">
          <!----custom div-->
          <kendo-grid-pdf-margin top="2.5cm" left="0.5cm" right="0.5cm" bottom="2.5cm">
          </kendo-grid-pdf-margin>
          <ng-template kendoGridPDFTemplate let-pageNum="pageNum" let-totalPages="totalPages">
            <div class="page-template">
              <div class="header" style="border-bottom: none;" [ngStyle]="{ display: pageNum <= 1 ? 'block' : 'none' }">
                <div class="address">
                  <div class="for" style="text-align: center; margin-top: 2%;">
                    <h3>Specialist Break Report</h3>
                  </div>
                </div>
              </div>
              <div class="footer">
                <div style="float: right">
                  Page {{ pageNum }} of {{ totalPages }}
                </div>
              </div>
            </div>
          </ng-template>
          <!----custom div-->
        </kendo-grid-pdf>
        <kendo-grid-excel fileName="Specialist Break Report.xlsx">
        </kendo-grid-excel>
      </kendo-grid>
    </div>
  </div>
</div>

<!-- Filter Popup Start -->
<kendo-dialog title="Filter Options" *ngIf="filterPopup" (close)="filterClose()" class="kendo-modal modal-md">
  <div class="common-popup-space">
    <div class="row">
      <div *ngIf="!isSpecialistBreakReport && !isCallTimeReport" class="col-12 col-md-12">
        <div class="form-group"
          *ngIf="this.coreHelperService.getLoggedInUserDetail().isMultipleFacility == 1 || this.coreHelperService.getLoggedInUserDomain() == 0">
          <label class="form-label">Select Facility</label>
          <kendo-multiselect [data]="selectFacilityList" [kendoDropDownFilter]="_reportService.filterSettings"
            [(ngModel)]="selectedFacility" [textField]="'facilityName'" [valueField]="'facilityId'"
            (valueChange)="onValueChangeFacility()" [autoClose]="false" class="multiselect-dropdown-height"
            [ngClass]="{'is-invalid': isSelected && selectedFacility?.length == 0}">
            <ng-template kendoMultiSelectHeaderTemplate>
              <span style="margin-left:14px">
                <input type="checkbox" id="chk" kendoCheckBox [checked]="isCheckedFacility"
                  [indeterminate]="isIndetFacility" (click)="onClickFacility()" />
                <kendo-label for="chk">&nbsp;&nbsp;&nbsp;{{ toggleAllTextFacility }}</kendo-label>
              </span>
            </ng-template>

            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <span>
                <kendo-label for="chk-{{ dataItem.facilityId }}">
                  <input type="checkbox" id="chk-{{ dataItem.facilityId }}" kendoCheckBox
                    [checked]="isFacilityItemSelected(dataItem)" />
                </kendo-label>
              </span>
              <span>{{ dataItem.facilityName }}</span>
            </ng-template>
          </kendo-multiselect>
          <div *ngIf="isSelected && selectedFacility?.length == 0" class="invalid-feedback">
            Please select facility
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12" *ngIf="!isCallTimeReport">
        <div class="form-group"
          *ngIf="this.coreHelperService.getLoggedInUserDetail().isMultipleFacility == 1 || this.coreHelperService.getLoggedInUserDomain() == 0">
          <label class="form-label">Select Specialist</label>
          <kendo-multiselect [data]="vobSpecialistList" [kendoDropDownFilter]="_reportService.filterSettings"
            [(ngModel)]="selectedVobSpecialist" [textField]="'userName'" [valueField]="'userId'"
            (valueChange)="onValueChangeSpecialist()" [autoClose]="false" class="multiselect-dropdown-height"
            [ngClass]="{'is-invalid': isSelected && selectedVobSpecialist?.length == 0}">
            <ng-template kendoMultiSelectHeaderTemplate>
              <span style="margin-left:14px">
                <input type="checkbox" id="chk" kendoCheckBox [checked]="isCheckedSpecialist"
                  [indeterminate]="isIndetSpecialist" (click)="onClickSpecialist()" />
                <kendo-label for="chk">&nbsp;&nbsp;&nbsp;{{ toggleAllTextSpecialist }}</kendo-label>
              </span>
            </ng-template>

            <ng-template kendoMultiSelectItemTemplate let-dataItem>
              <span>
                <kendo-label for="chk-{{ dataItem.userId }}">
                  <input type="checkbox" id="chk-{{ dataItem.userId }}" kendoCheckBox
                    [checked]="isSpecialistItemSelected(dataItem)" />
                </kendo-label>
              </span>
              <span>{{ dataItem.userName }}</span>
            </ng-template>
          </kendo-multiselect>
          <div *ngIf="isSelected && selectedVobSpecialist?.length == 0" class="invalid-feedback">
            Please select specialist
          </div>
        </div>
      </div>
      <div class="col-12 col-md-12">
        <div class="form-group">
          <label class="form-label">Select</label>
          <kendo-dropdownlist [data]="filterlistItems" [(ngModel)]="selectedMonthss"
            (selectionChange)="getDateCalculation($event)">
          </kendo-dropdownlist>
        </div>
      </div>
      <div class="col-12 col-md-12" *ngIf="showCalender">
        <div class="form-group mb-0">
          <label class="form-label">Select Range</label>
          <kendo-daterange>
            <div class="date-range-box">
              <kendo-dateinput kendoDateRangeStartInput [(ngModel)]="range.start" [ngModelOptions]="{standalone: true}"
                [(value)]="range.start" [value]="dateValue"></kendo-dateinput>
              <span class="dash-range">-</span>
              <kendo-dateinput kendoDateRangeEndInput [(ngModel)]="range.end" [ngModelOptions]="{standalone: true}"
                [(value)]="range.end" [value]="dateValue"></kendo-dateinput>
            </div>
            <kendo-daterange-popup>
              <ng-template kendoDateRangePopupTemplate>
                <kendo-multiviewcalendar kendoDateRangeSelection [disabledDates]="_reportService.disabledDates">
                </kendo-multiviewcalendar>
              </ng-template>
            </kendo-daterange-popup>
          </kendo-daterange>
        </div>
      </div>
    </div>

  </div>
  <kendo-dialog-actions class="d-block text-end">
    <button kendoButton type="button" (click)="runReport()" class="btn btn-primary btn-md">Run</button>
    <button kendoButton (click)="filterClose()" class="btn btn-cancel btn-md">Cancel</button>
  </kendo-dialog-actions>
</kendo-dialog>
<!-- Filter Popup End -->

<!-- Title Facility Popup start -->
<kendo-dialog title="Facility List" *ngIf="facility" (close)="facilityClose()" class="kendo-modal modal-md">
  <div class="common-popup-space">
    <div class="row">
      <div class="col-12 col-md-12">
        <div class="form-group">
          <ul class="selected-facility-name">
            <li *ngFor="let item of selectedFacility">
              <span>{{item.facilityName}}</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <kendo-dialog-actions class="d-block text-end">
    <button kendoButton (click)="facilityClose()" class="btn btn-cancel btn-md">Cancel</button>
  </kendo-dialog-actions>
</kendo-dialog>
<!-- Title Facility Popup end -->