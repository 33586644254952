export const DynamicListData =
{
  ContactTypeList: [
    {
      text: "Live Review",
      value: 1
    },
    {
      text: "Voice Mail",
      value: 2
    },
    {
      text: "Fax/Portal",
      value: 3
    },
    {
      text: "Other",
      value: 4
    }
  ],
  FrequencyList: [
    {
      text: "1x/wk",
      value: 1
    },
    {
      text: "2x/wk",
      value: 2
    },
    {
      text: "3x/wk",
      value: 3
    },
    {
      text: "4x/wk",
      value: 4
    },
    {
      text: "5x/wk",
      value: 5
    },
    {
      text: "6x/wk",
      value: 6
    },
    {
      text: "7x/wk",
      value: 7
    }
  ],
  AuthTypeList: [
    {
      text: "Precert",
      value: 1
    },
    {
      text: "Concurrent",
      value: 2
    },
    {
      text: "Retro",
      value: 3
    }
  ],
  PolicyList: [
    {
      key: 2,
      label: "Very Good"
    },
    {
      key: 1,
      label: "Good"
    }
  ],
  IssueTypeList: [
    {
      text: "Call Back Needed",
      value: 1
    },
    {
      text: "Assistance Needed",
      value: 2
    }
  ],
  BenefitsList: [
    {
      text: "Yes",
      value: 1
    },
    {
      text: "No",
      value: 2
    },
    {
      text: "N/A",
      value: 3
    },
    {
      text: "Rep could not disclose",
      value: 4
    }
  ],
  CoverageTypeList: [
    {
      text: "In-Network",
      value: 1
    },
    {
      text: "Out of Network",
      value: 2
    },
    {
      text: "In and Out of Network",
      value: 3
    }
  ],
  PayToPatientOrFacilityList: [
    {
      text: "Facility",
      value: 1
    },
    {
      text: "Member",
      value: 2
    },
    {
      text: "N/A",
      value: 3
    },
    {
      text: "Rep could not disclose",
      value: 4
    }
  ],
  AccrediationRequirementsList: [
    {
      text: "JCAHO/CARF",
      value: 1
    },
    {
      text: "JCAHO Only",
      value: 2
    },
    {
      text: "CARF Only",
      value: 3
    },
    {
      text: "State License",
      value: 4
    },
    {
      text: "Other",
      value: 5
    },
    {
      text: "N/A",
      value: 6
    }
  ],
  PolicyUptodateList: [
    {
      text: "Premium Payments Up to Date",
      value: 1
    },
    {
      text: "Currently in Grace Period",
      value: 2
    },
    {
      text: "N/A",
      value: 4
    },
    {
      text: "N/C",
      value: 5
    },
    {
      text: "Rep Could Not Disclose",
      value: 3
    }
  ],
  COBStatusList: [
    {
      text: "Current",
      value: 1
    },
    {
      text: "Needs Update",
      value: 2
    },
    {
      text: "N/A",
      value: 3
    },
    {
      text: "Rep could not disclose",
      value: 4
    }
  ],
  NetwrokList: [
    {
      text: "SuperMed (Medical Mutual Only)",
      value: 1
    },
    {
      text: "Narrow (Medical Mutual Only)",
      value: 2
    },
    {
      text: "N/A",
      value: 3
    },
    {
      text: "N/C",
      value: 4
    },
    {
      text: "Rep Could Not Disclose",
      value: 5
    }
  ],
  AuthStatusList: [
    { text: 'Approved', value: 'Approved' },
    { text: 'Denied', value: 'Denied' },
    { text: 'Discharged', value: 'Discharged' },
    { text: 'No Auth Req', value: 'NoAuthReq' },
    { text: 'Voided', value: 'Voided' }
  ],
  DisachargeTypeList: [
    { text: 'Home DC', value: '1' },
    { text: 'Step Down DC', value: '2' },
    { text: 'AMA DC', value: '3' },
    { text: 'Medical DC', value: '4' }
  ],
  YesNoList: [
    { text: 'Yes', value: 1 },
    { text: 'No', value: 2 }
  ],
  GenderList: [
    { text: 'Female', value: 2 },
    { text: 'Male', value: 1 },
    { text: 'Rather not to say', value: 3 },
  ],
  SubscriberList: [
    { text: 'Child', value: 2 },
    { text: 'Employee', value: 6 },
    { text: 'Other (Individual)', value: 4 },
    { text: 'Other (Organization)', value: 5 },
    { text: 'Self', value: 1 },
    { text: 'Spouse', value: 3 },
  ],
  InsuranceTypeList: [
    { text: 'Primary', value: 1 },
    { text: 'Secondary', value: 2 },
    { text: 'Tertiary', value: 3 },
  ],
  ChartTypeList: [
    { text: "Line", value: 1 },
  ],
  DashboardList: [
    { text: "VOB", value: 1 },
    { text: "UR", value: 2 },
    { text: "Billing", value: 3 },
    { text: "Credentialing", value: 4 },
    { text: "TPN SCA", value: 5 },
    { text: "TPN & Reconsideration", value: 6 },
    { text: "RCM", value: 7 },
    { text: "Medical Record", value: 8 },
  ],
  FacilityNewOldList: [
    { text: "New", value: 0 },
    { text: "Existing", value: 1 },
  ],
  NegotiationsList: [
    { text: "Negotiations", value: 1 },
    { text: "Reconsiderations", value: 2 }
  ],
  ClaimsList: [
    { text: "Claims", value: 1 },
    { text: "Dollar Amount", value: 2 },
    { text: "Percentage on Billed", value: 3 },
    { text: "Average Per Claim", value: 4 }
  ],
  InsuranceBreakDownDdwnList: [
    { text: "Good", value: 0 },
    { text: "Okay", value: 1 },
    { text: "Poor", value: 2 },
  ],
  BillingActionChoicesList: [
    { text: "Request a Correction", value: 2 },
    { text: "Void a Claim", value: 3 },
  ],
  ResolveIssueTypeList: [
    { text: 'Reassign', action: 'reassign' },
    { text: 'Send to facility', action: 'sendToFacility' },
    { text: 'Send back to specialist', action: 'sendToSpecialist' }
  ],
  RCMPriorityTypeList: [
    { text: "Immediate", value: 1 },
    { text: "High", value: 2 },
    { text: "Medium", value: 3 },
    { text: "Low", value: 4 },
    { text: "Lowest", value: 5 }
  ],
  PriorityTypeList: [
    { text: 'High', value: 1 },
    { text: 'Medium', value: 2 },
    { text: 'Low', value: 3 },
    { text: 'Immediate', value: 4 },
  ],
  DirectionTypeList: [
    { text: 'All', value: 0 },
    { text: 'Incoming', value: 1 },
    { text: 'Outgoing', value: 2 },
    { text: 'Internal', value: 3 },
  ],
  AnnouncementTypeList: [
    { text: "Internal", value: 0 },
    { text: "Facilities", value: 1 },
    { text: "Team", value: 2 }
  ],
  MonthTypeList: [
    { text: "January", value: 1 },
    { text: "February", value: 2 },
    { text: "March", value: 3 },
    { text: "April", value: 4 },
    { text: "May", value: 5 },
    { text: "June", value: 6 },
    { text: "July", value: 7 },
    { text: "August", value: 8 },
    { text: "September", value: 9 },
    { text: "October", value: 10 },
    { text: "November", value: 11 },
    { text: "December", value: 12 },
  ],
  DocumentTypeList: [
    { text: "Facility", value: 1 },
    { text: "Patient", value: 2 }
  ],
  DomainTypeList: [
    { text: "Facility", value: 1 },
    { text: "Internal", value: 0 }
  ],
  RoleTypeList: [
    { text: "Super Admin", value: 1 },
    { text: "Operation Head", value: 2 },
    { text: "Manager", value: 3 },
    { text: "Lead", value: 4 },
    { text: "Specialist", value: 5 },
    { text: "External", value: 6 }
  ],
  VOBBenefitsList: [
    { text: 'Yes', value: 1 },
    { text: 'No', value: 2 },
    { text: 'N/A', value: 3 },
  ],
  VOBCoverageTypeList: [
    { text: 'In-Network', value: 1 },
    { text: 'Out of Network', value: 2 },
    { text: 'In and Out of Network', value: 3 },
  ],
  VOBAccrediationRequirementsList: [
    { text: 'JACHO', value: 1 },
    { text: 'CARF', value: 2 },
    { text: 'State License', value: 3 },
    { text: 'N/A', value: 4 },
  ],
  VOBPolicyUptodateList: [
    { text: 'Premium Payments Up to Date', value: 1 },
    { text: 'Currently in Grace Period', value: 2 },
    { text: 'Rep could not Disclose', value: 3 },
  ],
  CarrierUrgencyList: [
    { text: 'Same Day', value: 1 },
    { text: 'Flexible', value: 2 },
    { text: 'N/A', value: 3 },
  ],
}
