import { Pipe, PipeTransform } from '@angular/core';
import { formatDate } from '@angular/common';

@Pipe({
  name: 'localDateTime',
})

export class LocalDateTimePipe implements PipeTransform {
  transform(date: Date = null): string {
    if (date) {
      let localDate = new Date(date); // val is in UTC
      let localOffset = localDate.getTimezoneOffset() * 60000;
      let localTime = localDate.getTime() - localOffset;

      localDate.setTime(localTime);
      return formatDate(localDate, 'MM/dd/yyyy h:mm a', 'en-US');
    }
    else {
      return null;
    }
  }
}

@Pipe({
  name: 'localDate',
})

export class LocalDatePipe implements PipeTransform {
  transform(date: Date = null): string {
    return date ? formatDate(new Date(date), 'MM/dd/yyyy', 'en-US') : null;
  }
}

@Pipe({
  name: 'localDateTimeSecond',
})

export class LocalDateTimeSecondPipe implements PipeTransform {
  transform(date: Date = null): string {
    if (date) {
      let localDate = new Date(date); // val is in UTC
      let localOffset = localDate.getTimezoneOffset() * 60000;
      let localTime = localDate.getTime() - localOffset;

      localDate.setTime(localTime);
      return formatDate(localDate, 'MM/dd/yyyy h:mm:ss a', 'en-US');
    }
    else {
      return null;
    }
  }
}

@Pipe({
  name: 'localTimeSecond',
})

export class LocalTimeSecondPipe implements PipeTransform {
  transform(date: Date = null): string {
    if (date) {
      let localDate = new Date(date); // val is in UTC
      let localOffset = localDate.getTimezoneOffset() * 60000;
      let localTime = localDate.getTime() - localOffset;

      localDate.setTime(localTime);
      return formatDate(localDate, 'h:mm:ss', 'en-US');
    }
    else {
      return null;
    }
  }
}
