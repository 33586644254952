<!-- Call Log Popup Start -->
<kendo-dialog title="{{callLogTitle + ' ' + patientName}}" *ngIf="isCallLogOpen" (close)="callLogClose()"
    class="kendo-modal modal-1180">
    <div class="common-popup-space">
        <div class="row mb-3">
            <div class="col col-md-6">
                <div class="row col-12">
                    <div class="col-md-6">
                        <button type="button"
                            class="btn btn-lg col-12 text-white btn-primary" (click)="onStartEndCallClick(!isCallStart)">
                            {{startEndCallText}}</button>
                    </div>
                    <div class="col-md-6">
                        <button type="button" 
                            class="btn btn-lg col-12 text-white btn-primary" (click)="onHoldResumeCallClick(!isCallHold)"
                            [disabled]="holdResumeCallDisable">
                            <img [ngClass]="isCallHold ? 'play-icon' : 'pause-icon'" height="18" width="18" />
                            {{holdResumeCallText}}</button>
                    </div>
                </div>
            </div>
            <div class="col col-md-6">
                <div class="row col-12">
                    <div class="col-md-6">
                        <button type="button" class="btn btn-success btn-lg col-12 text-white"
                            (click)="onDiscussionStartClick()" [disabled]="discussionStartDisable">Discussion
                            Start</button>
                    </div>
                    <div class="col-md-6">
                        <button type="button" class="btn btn-danger btn-lg col-12 text-white"
                            (click)="onDiscussionEndClick()" [disabled]="discussionEndDisable">Discussion
                            End</button>
                    </div>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label for="taskType" class="form-label font-weight-bold">Call Log :</label>
                    <div class="common-border table-responsive view-collection-grid-height view-vobinfo">
                        <kendo-grid [kendoGridBinding]="callLogDetailsData" #kendoGridForCallLog="kendoGrid"
                            [group]="group">

                            <kendo-grid-column field="callDate" title="Call Date" [hidden]="true">
                                <ng-template kendoGridGroupHeaderTemplate let-group let-value="value">
                                    {{value | localDate}}
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="Call Details" [width]="200">
                                <ng-template kendoGridCellTemplate let-dataItem>
                                    <span>{{dataItem.callActionTypeName}} : {{dataItem.callDateTime |
                                        localDateTimeSecond}}</span>
                                </ng-template>
                            </kendo-grid-column>
                            <kendo-grid-column title="Notes" [width]="150" field="notes">
                            </kendo-grid-column>
                        </kendo-grid>
                    </div>
                </div>
            </div>
            <div class="col-lg-12 col-md-12">
                <div class="form-group">
                    <label for="taskType" class="form-label font-weight-bold">Call Note:</label>
                    <textarea class="form-control call-log-textarea" rows="5" [(ngModel)]="notes" placeholder="Note"
                        maxlength="450"></textarea>
                </div>
            </div>
        </div>
    </div>
    <kendo-dialog-actions class="d-block text-end">
        <button kendoButton (click)="openManualCallLog()" class="btn btn-primary btn-md">Manual Log</button>
        <button kendoButton (click)="callLogClose()" class="btn btn-cancel btn-md">Close</button>
    </kendo-dialog-actions>
</kendo-dialog>

<kendo-dialog title="Add Manual Call Log" *ngIf="addManualCallLog" (close)="cancelManualCallLog()"
    class="kendo-modal modal-1180">
    <div class="common-popup-space">
        <form [formGroup]="manualcallLogForm">
            <div class="row">
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label class="form-label">Start date time <span class="field-required">*</span></label>
                        <kendo-datetimepicker [incompleteDateValidation]="true" #datetimepicker calendarType="classic"
                            formControlName="startDatetime"
                            [ngClass]="{ 'is-invalid':  manualcallLogForm.get('startDatetime').errors && isSubmittedCallLog}">
                        </kendo-datetimepicker>
                        <div *ngIf="manualcallLogForm.get('startDatetime').errors" class="invalid-feedback">

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label class="form-label">End date time</label>
                        <kendo-datetimepicker [incompleteDateValidation]="true" #datetimepicker calendarType="classic"
                            formControlName="endDatetime"
                            [ngClass]="{ 'is-invalid':  manualcallLogForm.get('endDatetime').errors && isSubmittedCallLog}">
                        </kendo-datetimepicker>
                        <div *ngIf="manualcallLogForm.get('endDatetime').errors" class="invalid-feedback">

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label class="form-label">Hold date time</label>
                        <kendo-datetimepicker [incompleteDateValidation]="true" #datetimepicker calendarType="classic"
                            formControlName="holdDatetime"
                            [ngClass]="{ 'is-invalid':  manualcallLogForm.get('holdDatetime').errors && isSubmittedCallLog}">
                        </kendo-datetimepicker>
                        <div *ngIf="manualcallLogForm.get('holdDatetime').errors" class="invalid-feedback">

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label class="form-label">Resume date time</label>
                        <kendo-datetimepicker [incompleteDateValidation]="true" #datetimepicker calendarType="classic"
                            formControlName="resumeDatetime"
                            [ngClass]="{ 'is-invalid':  manualcallLogForm.get('resumeDatetime').errors && isSubmittedCallLog}">
                        </kendo-datetimepicker>
                        <div *ngIf="manualcallLogForm.get('resumeDatetime').errors" class="invalid-feedback">

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label class="form-label">Discussion start date time <span
                                class="field-required">*</span></label>
                        <kendo-datetimepicker [incompleteDateValidation]="true" #datetimepicker calendarType="classic"
                            formControlName="discussionStartDatetime"
                            [ngClass]="{ 'is-invalid':  manualcallLogForm.get('discussionStartDatetime').errors && isSubmittedCallLog}">
                        </kendo-datetimepicker>
                        <div *ngIf="manualcallLogForm.get('discussionStartDatetime').errors" class="invalid-feedback">

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-6">
                    <div class="form-group">
                        <label class="form-label">Discussion end date time <span class="field-required">*</span></label>
                        <kendo-datetimepicker [incompleteDateValidation]="true" #datetimepicker calendarType="classic"
                            formControlName="discussionEndDatetime"
                            [ngClass]="{ 'is-invalid':  manualcallLogForm.get('discussionEndDatetime').errors && isSubmittedCallLog}">
                        </kendo-datetimepicker>
                        <div *ngIf="manualcallLogForm.get('discussionEndDatetime').errors" class="invalid-feedback">

                        </div>
                    </div>
                </div>
                <div class="col-12 col-md-12">
                    <div class="form-group">
                        <label for="taskType" class="form-label font-weight-bold">Call Note:</label>
                        <textarea class="form-control call-log-textarea" rows="5" formControlName="callNote" placeholder="Note"
                            maxlength="450"></textarea>
                    </div>
                </div>
            </div>
        </form>
    </div>
    <kendo-dialog-actions class="d-block text-end">
        <button kendoButton (click)="saveManualCallLog()" class="btn btn-primary btn-md">Save</button>
        <button kendoButton (click)="cancelManualCallLog()" class="btn btn-cancel btn-md">Cancel</button>
    </kendo-dialog-actions>
</kendo-dialog>
<!-- Call Log Popup End -->