// Provide all common set of validation messages here
export const GenericMessages = {
  email: {
    required: 'Please enter email',
    pattern: 'Please enter a valid email'
  },
  firstName: {
    required: 'Please enter first name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  lastName: {
    required: 'Please enter last name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  userName: {
    required: 'Please enter user name',
    maxlength: 'Please enter up to 30 characters',
    cannotContainSpace: 'Blank space not allowed',
    minlength: 'Please enter atlest 6 letter',
    pattern: 'Username does not contain space and allows only letters, numbers, underscore & dash'
  },
  phoneNumber: {
    required: 'Please enter phone number',
    mask: 'Please enter a valid phone number'
  },
  password: {
    required: 'Please enter password',
    pattern: "Password must contains at least 8 character including uppercase, lowercase, special character and number",
    maxlength: 'The password length must be less than or equal to 15',
    cannotContainSpace: 'Blank space not allowed'

  },
  confirmPassword: {
    required: 'Please enter confirm password',
    pattern: "Confirm password must contains at least 8 character including uppercase, lowercase, special character and number",
    maxlength: 'The password length must be less than or equal to 15',
    match: 'Password and confirm password must be same',
    cannotContainSpace: 'Blank space not allowed'
  },
  oldPassword: {
    required: 'Please enter current password',
    pattern: "Current password must contains at least 8 character including uppercase, lowercase, special character and number",
    maxlength: 'The Current password length must be less than or equal to 15',
    cannotContainSpace: 'Blank space not allowed'

  }
}

/* Document Master */
export const documentMasterMessages = {
  documentTypeId: {
    required: 'Please select document type'
  },
  categoryId: {
    required: 'Please select category'
  },
  subCategoryId: {
    required: 'Please select sub category'
  },
  patientId: {
    required: 'Please select patient'
  },
  tags: {
    required: 'Please select tags'
  },
  documentUpload: {
    required: 'Please select file'
  },
  statusId: {
    required: 'Please select status'
  },
  fileTypeId: {
    required: 'Please select file type '
  },
  internalNotes: {
    maxlength: 'Please enter up to 500 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  externalNotes: {
    maxlength: 'Please enter up to 500 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  expiryDate: {
    required: 'Please enter Expiry Date'
  },
  fileName: {
    require: 'Please enter file name'
  }
}

export const documentCategory = {
  categoryName: {
    required: 'Please enter category name '
  },
  description: {
    required: 'Please enter description '
  }
}
export const documentSubCategory = {
  subCategoryName: {
    required: 'Please enter category name '
  },
  description: {
    required: 'Please enter description '
  }
}
export const documentFilter = {
  note: {
    maxlength: 'Please enter up to 500 characters',
    cannotContainSpace: 'Blank space not allowed'
  }
}

export const userManagementMessages = {
  firstName: GenericMessages['firstName'],
  lastName: GenericMessages['lastName'],
  userName: GenericMessages['userName'],
  password: GenericMessages['password'],
  emailId: GenericMessages['email'],
  isArchive: {
    required: 'Please select status'
  },
  isAactive: {
    required: 'Please select status'
  },

  phoneNo: {
    required: 'Please Enter Your Phone Number',
    mask: 'Please enter a valid phone number'
  },
  extension: {
    required: 'Please Enter Extension'
  },
  domainId: {
    required: 'Please select domain'
  },
  facilityId: {
    required: 'Please select associated facility'
  },
  departmentId: {
    required: 'Please select department'
  },
  teamId: {
    required: 'Please select Team'
  },
  userRoleId: {
    required: 'Please select user role'
  },
  roleName: {
    required: 'Please enter role name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  description: {
    required: 'Please enter description',
    maxlength: 'Please enter up to 200 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  details: {
    required: 'Please enter description',
    maxlength: 'Please enter up to 200 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  // phone: GenericMessages['phoneNumber'],
}
export const departMentMasterMessages = {
  departmentName: {
    required: 'Please enter department Name',
    maxlength: 'Please enter up to 100 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
}
export const teamManagementMessages = {
  domainId: {
    required: 'Please select domain'
  },
  teamName: {
    required: 'Please enter team name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  description: {
    required: 'Please enter description',
    maxlength: 'Please enter up to 200 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  // isArchive: {
  //     required: 'Please select status'
  // },



  // phone: GenericMessages['phoneNumber'],
}
export const roleManagementMessages = {
  domainId: {
    required: 'Please select domain'
  },
  roleName: {
    required: 'Please enter role name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  description: {
    required: 'Please enter description',
    maxlength: 'Please enter up to 200 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  details: {
    required: 'Please enter description',
    maxlength: 'Please enter up to 200 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  isActive: {
    required: 'Please select status'
  },



  // phone: GenericMessages['phoneNumber'],
}
export const LoginFormMessages = {
  email: GenericMessages['email'],
  password: GenericMessages['password']
}
export const moduleMasterMessages = {
  moduleName: {
    required: 'Please enter module Name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  description: {
    required: 'Please enter description',
    maxlength: 'Please enter up to 200 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  isArchive: {
    required: 'Please select status'
  },


}
export const diagnosticMessages = {
  diagnosticCodes: {
    required: 'Please enter diagnostic Codes',
    maxlength: 'Please enter up to 10 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  description: {
    required: 'Please enter description',
    maxlength: 'Please enter up to 200 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
}
export const documentTypeMasterMessages = {
  documentTypeId: {
    required: 'Please select document Type',
  },
  categoryName: {
    required: 'Please select category Name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  subCategoryName: {
    required: 'Please enter subCategory Name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  departmentId: {
    required: 'Please select department',
  },
}

export const engagementMessages = {
  departmentId: {
    required: 'Please select Department Name',
  },
  engagementType: {
    required: 'Please enter type',
    maxlength: 'Please enter up to 30 characters',
  },
  marks: {
    required: 'Please enter marks',
    // numRegex = /^-?\d*[.,]?\d{0,0}$/,
    // isNumberCheck: 'please enter an integer'
  },
}

export const tenureGoalMessages = {
  departmentId: {
    required: 'Please select Department Name',
  },
  zeroToOne: {
    required: 'Please enter goal',
  },
  twoToThree: {
    required: 'Please enter goal',
  },
  fourToSix: {
    required: 'Please enter goal',
  },
  sevenToNine: {
    required: 'Please enter goal',
  },
  aboveTen: {
    required: 'Please enter goal',
  }

}

export const attendanceMessages = {

  attendanceType: {
    required: 'Please select Attendance Type',
  },
  totalMarks: {
    required: 'Please select Total Marks',
  },
  totalHours: {
    required: 'Please enter Total Hours',
  },
}

export const statusWorkFlowMessage = {

  departmentId: {
    required: 'Please select department',
  },
  tabId: {
    required: 'Please select Tab',
  },
  cardId: {
    required: 'Please select Card',
  },
  statusId: {
    required: 'Please select status',
  },
  statusDetailId: {
    required: 'Please select status details',
  },
}
export const taxonomyMasterMessages = {
  taxonomyName: {
    required: 'Please enter Facility Identifier Name',
    maxlength: 'Please enter up to 100 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
}

export const tpnMasterMessages = {
  thirdPartyName: {
    required: 'Please enter Third Party Name',
    maxlength: 'Please enter up to 100 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
}

export const serviceTypeMasterMessages = {
  serviceTypeName: {
    required: 'Please enter service type Name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  serviceId: {
    required: 'Please select service Name',
  },
  isArchive: {
    required: 'Please select status'
  },

}
export const facilityDocumentsMessages = {
  documentName: {
    required: 'Please enter document Name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  isArchive: {
    required: 'Please select status'
  },
  isRequired: {
    required: 'Please select  status'
  },

}
export const announcementMessages = {
  title: {
    required: 'Please enter title',
    maxlength: 'Please enter up to 100 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  announcementFor: {
    required: 'Please enter announcement For',
    maxlength: 'Please enter up to 100 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  details: {
    required: 'Please enter details',
    //maxlength: 'Please enter up to 100 characters',
    //cannotContainSpace: 'Blank space not allowed'
  },
  startDate: {
    required: 'Please select start date',
    bsDate: 'Invalid start date'
  },
  endDate: {
    required: 'Please select end date',
    bsDate: 'Invalid end date'
  },
  teamId: {
    required: 'Please select team',
  },
}
export const serviceMasterMessages = {
  serviceName: {
    required: 'Please enter service Name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  serviceTypeId: {
    required: 'Please select serviceType Name',
  },
  acronym: {
    required: 'Please enter acronym',
    maxlength: 'Please enter up to 10 characters',



    cannotContainSpace: 'Blank space not allowed'
  },
  revCode: {
    required: 'Please enter revision code',
    maxlength: 'Please enter up to 10 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  hcpsCode: {
    required: 'Please enter hcps code',
    maxlength: 'Please enter up to 10 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  price: {
    required: 'Please enter price',
    cannotContainSpace: 'Blank space not allowed'
  },
  isArchive: {
    required: 'Please select status'
  },
  vobCode: {
    required: 'Please select vobCode Yes/No'
  },


}
export const carrierInfoMessages = {
  insuranceCompany: {
    required: 'Please enter insurance company name',
    maxlength: 'Please enter up to 100 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  payerId: {
    required: 'Please enter payer Id',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  paymentSource: {
    required: 'Please enter payment source',
    maxlength: 'Please enter up to 100 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  collaborateId: {
    required: 'Please enter collaborate Id',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  contactPerson: {
    required: 'Please enter contact Person Name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  portalSystemId: {
    required: 'Please enter portal system Id',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  shortName: {
    required: 'Please enter abbreviation/short name',
    maxlength: 'Please enter up to 10 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  notes: {
    required: 'Please enter notes',
    maxlength: 'Please enter up to 250 characters',
    cannotContainSpace: 'Blank space not allowed'
  },

}
export const carrierContactInfoMessages = {
  mainPhoneNo: {
    required: 'Please enter main phone No',
    cannotContainSpace: 'Blank space not allowed'
  },
  phoneType: {
    required: 'Please enter phoneType',
    cannotContainSpace: 'Blank space not allowed'
  },

  mainPhoneExt: {
    required: 'Please enter main phone Ext',
    cannotContainSpace: 'Blank space not allowed'
  },
  faxNo: {
    required: 'Please enter fax',
    cannotContainSpace: 'Blank space not allowed'

  },
  faxNoExt: {
    required: 'Please enter fax Ext',
    cannotContainSpace: 'Blank space not allowed'

  },
  otherPhoneNo: {
    required: 'Please enter other phone No',
    cannotContainSpace: 'Blank space not allowed'

  },
  otherPhoneExt: {
    required: 'Please enter other phone Ext',
    cannotContainSpace: 'Blank space not allowed'

  },
  urPhoneNo: {
    required: 'Please enter ur phone',
    cannotContainSpace: 'Blank space not allowed'
  },
  urPhoneExt: {
    required: 'Please enter ur phone Ext',
    cannotContainSpace: 'Blank space not allowed'
  },
}

export const patientInfoMessages = {
  firstName: GenericMessages['firstName'],
  lastName: GenericMessages['lastName'],
  dob: {
    required: 'Please select dob'
  },
  gender: {
    required: 'Please select gender'
  },
  ssn: {
    required: 'Please enter ssn',
    maxlength: 'Please enter up to 10 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  addressLine1: {
    required: 'Please enter  address',
    maxlength: 'Please enter up to 250 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  stateId: {
    required: 'Please select state',
  },
  cityId: {
    required: 'Please enter city',
  },
  zipCode: {
    required: 'Please enter zip',
    maxlength: 'Please enter up to 5 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  patientAccountId: {
    required: 'Please enter account Id',
    maxlength: 'Please enter up to 12 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  facilityId: {
    required: 'Please enter facility Id',
    maxlength: 'Please enter up to 10 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
}
export const patientInsuranceMessages = {

  facilityName: {
    required: 'Please select facility'
  },
  insuredName: {
    required: 'Please enter insured',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  memberId: {
    required: 'Please enter member Id',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  groupId: {
    required: 'Please enter group Id',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  insuranceType: {
    required: 'Please select type',
  },
}
export const patientContactInfoMessages = {
  mainPhoneNo: {
    required: 'Please enter mainPhone No',
    cannotContainSpace: 'Blank space not allowed'
  },
  mainPhoneExt: {
    required: 'Please enter mainPhone Ext',
    maxlength: 'Please enter up to 4 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  email: {
    required: 'Please enter email',
    cannotContainSpace: 'Blank space not allowed'
  },
  emailExt: {
    required: 'Please enter email Ext',
    cannotContainSpace: 'Blank space not allowed'
  },
  otherPhoneNo: {
    required: 'Please enter otherPhone No',
    cannotContainSpace: 'Blank space not allowed'
  },
  otherPhoneExt: {
    required: 'Please enter otherPhone Ext',
    maxlength: 'Please enter up to 4 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  mobilePhoneNo: {
    required: 'Please enter mobilePhone No',
    cannotContainSpace: 'Blank space not allowed'
  },
  mobilePhoneExt: {
    required: 'Please enter mobilePhone Ext',
    maxlength: 'Please enter up to 4 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
}
export const patientICDDataMessages = {
  primaryCode: {
    required: 'Please enter primary code',
    maxlength: 'Please enter up to 10 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  description: {
    required: 'Please enter description',
    maxlength: 'Please enter up to 250 characters',
    cannotContainSpace: 'Blank space not allowed'
  }
}
export const facilityGroupMessages = {
  groupName: {
    required: 'Please enter group Name',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  legalName: {
    required: 'Please enter legal Name',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  description: {
    required: 'Please enter description',
    maxlength: 'Please enter up to 250 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  isArchive: {
    required: 'Please select status',
  },
}
export const patientDemographicsMessages = {
  insuranceCompany: {
    required: 'Please enter insured',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  subscriberGender: {
    required: 'Please select gender',
  },
  subscriberDOB: {
    required: 'Please select dob',
  },
  addressLine1: {
    required: 'Please enter address',
    maxlength: 'Please enter up to 250 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  subscriberRelationId: {
    required: 'Please select relation',
  },
  facilityId: {
    required: 'Please select facility'
  },
  subScriberFName: {
    required: 'Please enter subscriber first name',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  memberId: {
    required: 'Please enter member Id',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  groupId: {
    required: 'Please enter group Id',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  insuranceTypeId: {
    required: 'Please select type',
  },
}
//Facility Info Massages
export const practiceDemographicsMessages = {
  facilityName: {
    required: 'Please enter facility Name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  taxPayerId: {
    required: 'Please select taxPayer Id',
  },
  npi: {
    required: 'Please enter npi',
  },
  targetStartDate: {
    required: 'Please select target Start Date',
  },
  startDate: {
    required: 'Please select Start Date',
  },
  stateId: {
    required: 'Please select State',
  },
  cityId: {
    required: 'Please enter City',
  },
  zipCode: {
    required: 'Please enter Zipcode',
  },
  phoneNo: {
    required: 'Please enter phone No',
  },
  webLink: {
    required: 'Please select webLink',
  },
  patientStatementPhone: {
    required: 'Please enter patientStatement Phone',
    maxlength: 'Please enter up to 50 characters',
  },
  accountManagerName: {
    required: 'Please enter Account Manager Name',
    maxlength: 'Please enter up to 50 characters',
  },
  primaryAddress: {
    required: 'Please enter primary Address',
    maxlength: 'Please enter up to 200 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  accountManager: {
    required: 'Please select account manager',
  }
}
export const taxonomyMessages = {
  identifierId: {
    required: 'Please enter identifier',
  },
  description: {
    required: 'Please enter Id',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
}
export const payerIdentifierMessages = {
  carrierName: {
    required: 'Please enter carrier Name',
  },
  effectiveDate: {
    required: 'Please select effective Date',
  },
  expirationDate: {
    required: 'Please select expiration Date',
  },
  contractType: {
    required: 'Please select contract Type',
  },
  feeSchedule: {
    required: 'Please enter free Text',
  },
}
export const taskTypeMessages = {
  taskTypeName: {
    required: 'Please enter Task Type Name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
}
export const TaskMessages = {

  departmentId: {
    required: 'Please select Department Name',
  },
  taskTypeId: {
    required: 'Please select Task Type Name',
  },
  taskDetail: {
    required: 'Please select Task Detail',
  },
}
export const documentsMessages = {
  uploadedFileName: {
    required: 'Please enter uploaded FileName',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  expirationDate: {
    required: 'Please select explaination Date',
  },
}
export const facilityRatesMessages = {
  serviceType: {
    required: 'Please select service Type',
  },
  serviceName: {
    required: 'Please select service Name',
  },
  units: {
    required: 'Please enter units',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  rate: {
    required: 'Please enter rate',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  revisionCode: {
    required: 'Please enter revision Code',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  hcpsCode: {
    required: 'Please enter hcps Code',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  isValidated: {
    required: 'Please select This Feilds',
  },
  description: {
    required: 'Please enter description',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
}
export const ehrLoginMessages = {
  loginName: {
    required: 'Please enter login Name',
    maxlength: 'Please enter up to 20 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  loginPassword: {
    required: 'Please select Password',
    pattern: "Password must contains at least 8 character including uppercase, lowercase, special character and number",
    // maxlength: 'The password length must be less than or equal to 15',
    cannotContainSpace: 'Blank space not allowed'
  },
}
export const addressMessages = {
  primaryAddress: {
    required: 'Please enter primary Address',
    maxlength: 'Please enter up to 250 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  secondaryAddress: {
    required: 'Please enter secondary Address',
    maxlength: 'Please enter up to 250 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  mailingAddress: {
    required: 'Please enter mailing Address',
    maxlength: 'Please enter up to 250 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
}
//Staff
export const clinicalStaffMessages = {
  FirstName: GenericMessages['firstName'],
  LastName: GenericMessages['lastName'],
  EmailId: GenericMessages['email'],
  RoleId: {
    required: 'Please select Role',
  },
  CAQH: {
    required: 'Please enter CAQH',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  StaffSSN: {
    required: 'Please enter SSN',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  LicenseNo: {
    required: 'Please enter Licence',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  NPI: {
    required: 'Please enter NPI',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
}
export const NonclinicalStaffMessages = {
  FirstName: GenericMessages['firstName'],
  LastName: GenericMessages['lastName'],
  EmailId: GenericMessages['email'],
  PhoneNo: GenericMessages['phoneNumber'],
  RoleId: {
    required: 'Please select Role',
  },
}
export const staffProviderMessages = {
  providerName: {
    required: 'Please enter Document Type',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  providerNumber: {
    required: 'Please enter provider Number',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
}
export const thirdPartyAccountMessages = {
  webAddress: {
    required: 'Please enter web Address',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  securityAnswer: {
    required: 'Please enter security Answer',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  accountName: {
    required: 'Please enter account Name',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  password: GenericMessages['password'],
  emailId: {
    required: 'Please enter associated email Id',
    pattern: 'Please enter a valid email'
  },
  userName: GenericMessages['userName'],
}
export const noteMessages = {
  internals: {
    required: 'Please enter note',
    cannotContainSpace: 'Blank space not allowed'
  },
  externals: {
    required: 'Please enter note',
    cannotContainSpace: 'Blank space not allowed'
  }

}
export const taskManagementMessages = {
  taskTypeId: {
    required: 'Please select task type'
  },
  caseId: {
    required: 'Please select case'
  },
  facilityId: {
    required: 'Please select facility'
  },
  assignedTo: {
    required: 'Please select assign user'
  },
  dueDate: {
    required: 'Please select due date'
  },
  statusId: {
    required: 'Please select status'
  },
  taskSubject: {
    required: 'Please select subject '
  },
  details: {
    required: 'Please enter details',
    maxlength: 'Please enter up to 50 characters',
    cannotContainSpace: 'Blank space not allowed'
  },
  notifyTypeId: {
    required: 'Please select team notify id'
  },
}

export const messageConfigurationMessages = {
  moduleId: {
    required: 'Please select module'
  },
  messageHeader: {
    required: 'Please select title'
  },
  message: {
    required: 'Please select message'
  },
}

export const uploadTypeMessages = {
  typeName: {
    required: 'Please select Document Name'
  },
  uploadTypeName: {
    required: 'Please select file type '
  },
}

export const vobPatientDemographicMessages = {
  firstName: {
    required: 'Please enter patient first name',
  },
  lastName: {
    required: 'Please enter patient last name',
  },
  subscriberFName: {
    required: 'Please enter subscriber first name',
  },
  subscriberLName: {
    required: 'Please enter subscriber last name',
  },
  dob: {
    required: 'Please select patient dob',
  },
  subscriberDob: {
    required: 'Please select subscriber dob',
  },
  relationship: {
    required: 'Please select subscriber relationship',
  },
  addressLine1: {
    required: 'Please enter address line 1',
  },
  stateId: {
    required: 'Please select state',
  },
  cityId: {
    required: 'Please enter city',
  },
  zipCode: {
    required: 'Please enter zipcode',
  },
}

export const vobInsuranceMessages = {
  memberId: {
    required: 'Please enter member id',
  },
  phoneNo: {
    mask: 'Please enter a valid phone no',
    required: 'Please enter phone number',
  },
  memberServicePhone: {
    required: 'Please enter member service number',
    mask: 'Please enter member service number'
  },
  planTypeId: {
    required: 'Please select plan type',
  },
  payerId: {
    required: 'Please enter payer id',
  },
  primaryPolicy: {
    required: 'Please select primary policy',
  },
  hasNetworkBenifits: {
    required: 'Please select has network benifits',
  }
}

export const vobEligibilityMessages = {
  isActive: {
    required: 'Please select active or not',
  },
  coverageTypeId: {
    required: 'Please select coverage type',
  },
  cobStatusId: {
    required: 'Please select cob status',
  },
  funding: {
    required: 'Please select funding',
  },
  effectiveCoverageDate: {
    required: 'Please select effective coverage date',
  },
  lastCOBUpdated: {
    required: 'Please select last cob updated',
  },
  effectiveTermDate: {
    required: 'Please select effective term date',
  },
  existingClause: {
    required: 'Please select existing clause',
  }
}

export const vobBenefitDetailsMessages = {
  patientPays_IN: {
    required: 'Please enter patient pays',
  },
  insurancePays_IN: {
    required: 'Please enter insurance pays',
  },
  deductible_IN: {
    required: 'Please enter deductible',
  },
  deductibleAccum_IN: {
    required: 'Please enter accumulation',
  },
  maxOutOfPocket_IN: {
    required: 'Please enter max out of pocket',
  },
  maxOOPAccum_IN: {
    required: 'Please enter accumulation',
  },
  patientPays_OUT: {
    required: 'Please enter patient pays',
  },
  insurancePays_OUT: {
    required: 'Please enter insurance pays',
  },
  deductible_OUT: {
    required: 'Please enter deductible',
  },
  deductibleAccum_OUT: {
    required: 'Please enter accumulation',
  },
  maxOutOfPocket_OUT: {
    required: 'Please enter max out of pocket',
  },
  maxOOPAccum_OUT: {
    required: 'Please enter Accumulation',
  },
  isTPN: {
    required: 'Please select tpn',
  },
  isAOB: {
    required: 'Please select aob',
  },
  isPayToPatientOrFacility: {
    required: 'Please select facility or member/patient',
  },
  isOutOfPocket: {
    required: 'Please specify if deductibles count toward out-of-pocket',
  },
  isPolicyUptodate: {
    required: 'Please select if premiums are up to date',
  },
  policyRetroTermDate: {
    required: 'Please select policy retro term date',
  },
}
export const vobPrecertificationMessages = {
  contactPerson: {
    required: 'Please enter contact person',
  },
  contactPhone: {
    required: 'Please enter contact number',
  },
  visitLimits: {
    required: 'Please enter visit limits',
  },
  penalties: {
    required: 'Please enter penalties',
  }
}
export const vobExclusionsMessages = {
  exclusionDetails: {
    required: 'Please enter exclusion details',
  },
  isFreeStandingFacilityId: {
    required: 'Please select if free standing facility is okay',
  },
}
export const vobNotesMessages = {
  verifiedBy: {
    required: 'Please enter verified by',
  },
  spokeToId: {
    required: 'Please enter spoke to',
  },
  noteDate: {
    required: 'Please enter note date',
  },
  noteTime: {
    required: 'Please enter note time',
  },
  externalNotes: {
    required: 'Please enter external notes',
  },
  internalNotes: {
    required: 'Please enter internal notes',
  },
  referenceNo: {
    required: 'Please enter reference number',
  },
}

export const authCarrierDetailsMassages = {
  caseManagerName: {
    required: 'Please enter Case Manager Name',
  },
  carrier: {
    required: 'Please enter Carrier Name',
  },
  caseManagerPhone: {
    required: 'Please enter Phone',
  },
  preCertPhone: {
    required: 'Please enter Precert Phone No.',
  },
  caseManagerExt: {
    required: 'Please enter Extension',
  },
  memberId: {
    required: 'Please enter Member Id',
  },
  noOfDays: {
    required: 'Please enter Days',
  },
  faxNo: {
    required: 'Please enter Fax Number',
  },
  dob: {
    required: 'Please enter Dob'
  },
  admittedDate: {
    required: 'Please enter Admitted Date'
  },
  carrierEmail: {
    required: 'Please enter Valid Email.'
  },
}

export const authConcurrentMassages = {
  levelofCare: {
    required: 'Please select service'
  },
  authType: {
    required: 'Please select auth type'
  },
  authStartDate: {
    required: 'Please enter start Date'
  },
  dateOfReview: {
    required: 'Please select Date of Review',
  },
  timeOfReview: {
    required: 'Please enter Time of Review',
  },
  contactTypeId: {
    required: 'Please select Contact Type',
  },
}
export const authCallinInfoMassages = {
  caseManagerName: {
    required: 'Please enter CaseManager Name',
  },
  carrier: {
    required: 'Please enter Carrier Name',
  },
  caseManagerPhone: {
    required: 'Please enter Phone',
  },
  preCertPhone: {
    required: 'Please enter Contact No',
  },
  caseManagerExt: {
    required: 'Please enter Extension',
  },
  memberId: {
    required: 'Please enter Member Id',
  },
  noOfDays: {
    required: 'Please enter Days',
  },
  faxNo: {
    required: 'Please enter Fax Number',
  },
}
export const authAuthDetailsMassages = {
  authType: {
    required: 'Please enter Auth Type',
  },
  levelOfCare: {
    required: 'Please enter Level of Care',
  },
  days: {
    required: 'Please enter Days',
  },
  authNo: {
    required: 'Please enter Auth No',
  },
  startDate: {
    required: 'Please select Start Date',
  },
  endOfDate: {
    required: 'Please Select End Date',
  },
  authStatus: {
    required: 'Please select Auth Status.',
  }
}
export const authActionsMassages = {
  responsibleId: {
    required: 'Please select responsibleId',
  },
  statusId: {
    required: 'Please select statusId',
  },
  statusDetails: {
    required: 'Please enter statusDetails',
  }
}
